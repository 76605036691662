import { config, base_url, axiousInstance } from './index';
let axios=axiousInstance;


export function getRamosVariosDetail(siniestro, policy, item) {
  let data = {
    "policyNo": policy, //348647
    "item": item, //1
    "sinestro": siniestro //10096168
  };
  return axios
    .post(base_url + "ramosVarios/getRamosVariosDetails", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      throw error;
    });
}

export function getRamosVarios(siniestro, policy, item, flag) {
  let data = {
    "flag": flag,
    "siniestrosNo": siniestro, // 10095397,
    "policyNo": policy || "None",
    "itemNo": item || "None"
  }
  return axios
    .post(base_url + "ramosVarios/getramosvariossc1", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      throw error;
    });
}

export function getRamosVariosPerdidaProbable(siniestro, policy, item) {
  let data = {
    "siniestrosNo": siniestro
  }
  return axios
    .post(base_url + "ramosVarios/getramosvariossc3", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      throw error;
    });
}

export function getRamosVariosPagos(siniestro, policy, item) {
  let data = {
    "siniestrosNo": siniestro,
    "policyNo": policy || "None",
    "itemNo": item || "None",
    "flag": "S"
  }
  return axios
    .post(base_url + "ramosVarios/getramovariossc4", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      throw error;
    });
}