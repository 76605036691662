import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import logo from "../../assets/images/phoenixLogo.png";
import UserManualEnglish from "../../assets/LMI-UserManualEnglish.pdf";
import UserManualSpanish from "../../assets/LMI-UserManualSpanish.pdf";
import PersonIcon from "@material-ui/icons/Person";
import { translate } from "react-i18next";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import "./Header.scss";
import { authContext } from "../../utils/adalConfig";

const drawerWidth = 240;

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  }
});

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.sideBarOpen || true,
      anchorEl: null,
      selectedLng: "es",
      userName: "",
      userRole:"",
      showUserName: false
    };

    if (localStorage.getItem("userName")) {
      this.state.userName = localStorage.getItem("userName");
      this.state.userRole = localStorage.getItem("userRole")?JSON.parse(localStorage.getItem("userRole"))[0]:"";
      this.state.showUserName = true;
    } else {
      this.state.showUserName = false;
    }
  }
  componentWillMount() {
    let defaultLng =
      this.props.i18n &&
      this.props.i18n.language &&
      this.props.i18n.language == "en-US"
        ? "es"
        : this.props.i18n.language;
    this.setState({
      selectedLng: defaultLng
    });
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      open: nextProps.sideBarOpen
    });
  }
  handleDrawerOpen = () => {
    this.props.handleDrawerOpen();
  };
  handleDrawerClose = () => {
    this.props.handleDrawerClose();
  };
  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  logOutAzureAD = () => {
    authContext.logOut();
    localStorage.clear();
  };

  render() {
    const { classes } = this.props;
    const { i18n } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    let translateObj =
      (i18n.store.data[i18n.language] &&
        i18n.store.data[i18n.language].translations) ||
      i18n.store.data["es"].translations;
    /* change language function */
    const changeLanguage = lng => {
      this.setState({
        selectedLng: lng
      });
      i18n.changeLanguage(lng);
    };
    return (
      <AppBar
        position="absolute"
        className={classNames(
          classes.appBar,
          this.state.open && classes.appBarShift
        )}
      >
        <Toolbar
          disableGutters={!this.state.open}
          className={"remove-padding " + classes.toolbar}
        >
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={this.handleDrawerOpen}
            className={classNames(
              classes.menuButton,
              this.state.open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          {this.state.open ? (
            <div className={"custom-property " + classes.toolbarIcon}>
              <IconButton
                onClick={this.handleDrawerClose}
                className="color-white"
              >
                <ChevronLeftIcon />
              </IconButton>
            </div>
          ) : null}
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            <img className="logo" src={logo} alt="" />
          </Typography>
          <div className="translate-btn-grp">
            <Button
              className={
                "remove-fcs " +
                (this.state.selectedLng === "es" ? "lng-selected-button" : "")
              }
              onClick={() => changeLanguage("es")}
              color="inherit"
            >
              Spanish
            </Button>
            /
            <Button
              className={
                "remove-fcs " +
                (this.state.selectedLng === "en" ? "lng-selected-button" : "")
              }
              onClick={() => changeLanguage("en")}
              color="inherit"
              selected
            >
              English
            </Button>
          </div>
          {this.state.showUserName ? (
            <span className="userName">Hola! {this.state.userName} ({this.state.userRole})</span>
          ) : (
            <span className="userName" />
          )}
          <IconButton
            aria-owns={open ? "menu-appbar" : undefined}
            aria-haspopup="true"
            onClick={this.handleMenu}
            color="inherit"
            className="remove-fcs"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={open}
            onClose={this.handleClose}
            className="profilemenu"
          >
            <MenuItem onClick={this.logOutAzureAD}>
              {translateObj.logout}
            </MenuItem>
            <MenuItem
              onClick={() =>
                window.open(
                  this.state.selectedLng === "en"? UserManualEnglish:UserManualSpanish,
                  "_blank"
                )
              }
            >
              {translateObj.user_manul}
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired
};
let data = withStyles(styles)(Header);
export default translate("translations")(data);
