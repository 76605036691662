import React from 'react';
import PropTypes from 'prop-types';
// import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Divider from "@material-ui/core/Divider";
import Typography from '@material-ui/core/Typography';
import { translate, Trans } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
// import clasesDeContratoKeys from "src/Constants/reaseguradoresKeys";
import DyanmicTableWithSorting from "../../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";


import TextField from '@material-ui/core/TextField';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import {
    MuiPickersUtilsProvider,
    DatePicker
} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import EmptyState from "../../../../BaseComponents/EmptyState/EmptyState";

class ClassesDeContrato extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showClassesDeContrato: true,

        };

    }
    componentDidMount() {
        let showClasesDeContrato = this.props.data && this.props.data.length > 0 ? true : false;
        this.setState({
            ClasesDeContratoData: this.props.data,
            showClasesDeContrato: showClasesDeContrato,
            contratoNo: this.props.contratoNo
        });
    }

    showLoaderAction() {
        let showLoader = this.state.showLoader;
        this.setState({
            showLoader: !showLoader
        });
    }

    submitToLimites = () => {
        this.props.goToLimites(this.state.contratoNo)
    }
    goToPreviousPage = () => {
        this.props.goToDataGenerales(0)
    };

    render() {
        const showClassesDeContrato = this.state.showClassesDeContrato;
        let keys = {
            "clase": "Nombre Clase",
            "retención": "% Retención",
            "cesión": "% Cesion",
        };
        let ClasesDeContratoData=this.props.data;
        return (
            <div className="tab-class">
                    <Card style={{ marginTop: "25px" }} className="Card-area box-show-none">
                    {ClasesDeContratoData ? (
                        <div >
                            <div className="container">
                                <DyanmicTableWithSorting
                                    data={ClasesDeContratoData}
                                    keyArray={keys}

                                    virticalScroll={true}
                                    tableMaxHeight={"400"}
                                />
                            </div>
                        </div>) :
                         (
                            <div className={"content"}>
                                <EmptyState
                                    emoticon={"🔍"}
                                    message={"No se encontraron datos"}
                                />
                            </div>
                        )}
                        <div className="reso-btn-group">
                            <div className="btn">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    onClick={this.goToPreviousPage}
                                    className="search-policy pull-right resetButtonClass ">Volver</Button>
                            </div>
                            <div className="btn " >
                                <Button variant="contained" size="medium" onClick={this.submitToLimites} color="primary" className="search-policy pull-right resetButtonClass">
                                    Confirmar cambios y continuar
                                </Button>
                            </div>
                        </div>
                    </Card>
            </div>
        )
    }
}

export default translate("translations")(ClassesDeContrato);