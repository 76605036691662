import React from 'react';
import PropTypes from 'prop-types';
// import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Divider from "@material-ui/core/Divider";
import Typography from '@material-ui/core/Typography';
import { translate, Trans } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';

import TextField from '@material-ui/core/TextField';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import {
    MuiPickersUtilsProvider,
    DatePicker
} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import EmptyState from "../../../../BaseComponents/EmptyState/EmptyState";


class CondicionesEspeciales extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCondicionesEspeciales: true,
            condicionesEspeciales: ""
        };
    }

    handleChangeCondicionesEspeciales = event => {
        const value = event.target.value;
        this.setState({
            condicionesEspeciales: value
        });
    };
    goToPreviousPage = () => {
        this.props.goToClasesDeContrato(6)
    };
    render() {
        const showCondicionesEspeciales = this.state.showCondicionesEspeciales;
        console.log("this.props.data", this.props.data)
        return (
            <div className="tab-class">
                    <Card className="Card-area box-show-none" style={{ marginTop: "25px" }}>
                        
                        <div className="container">
                                    <div className="row porClientRowClass">
                                        <div className="col-sm-12">
                                            <TextField
                                                id="outlined-multiline-static"
                                                label="Condiciones especiales"
                                                multiline
                                                rows="5"
                                                value={this.props.data}
                                                disabled={true}
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </div>
                                    </div>
                                </div>
                       
                        <div className="reso-btn-group">
                            <div className="btn">
                            </div>
                            <div className="btn">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    onClick={this.goToPreviousPage}
                                    className="search-policy pull-right ">Volver</Button>
                            </div>
                        </div>
                    </Card>
               
            </div>
        )
    }
}

export default translate("translations")(CondicionesEspeciales);