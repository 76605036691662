import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ConsultarMandatosCliente from '../Phoenix/components/PacPatApp/ConsultarMandatosCliente'
import InformeDeCargosRechazados from '../Phoenix/components/PacPatApp/InformeDeCargosRechazados'


export const PacPat = ({ match }) => (
    <Switch>
        <Route exact path={`${match.url}/consulta-por-rut`} component={ConsultarMandatosCliente}/>
        <Route exact path={`${match.url}/consulta-por-estado`} component={InformeDeCargosRechazados}/>
    </Switch>
   
  ) 