import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ReactExport from "react-data-export";
import './TableComponet.scss'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#3f51b5',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});


const dataSet1 = [
    {
        Dessert: "Frozen yoghurt",
        Calories: 159,
        Fat: 6.0,
        Carbs: 24,
        Protein:4.0 
    },
    {
        Dessert: "Ice cream sandwich",
        Calories: 237,
        Fat: 9.0,
        Carbs: 37,
        Protein:4.3
    },
    {
        Dessert: "Frozen yoghurt",
        Calories: 305,
        Fat: 16.0,
        Carbs: 24,
        Protein:4.0
    },
    {
        Dessert: "Cupcake",
        Calories: 262,
        Fat: 3.7,
        Carbs: 67,
        Protein:4.3
    },
    {
        Dessert: "Gingerbread",
        Calories: 356,
        Fat: 16.0,
        Carbs: 49,
        Protein:3.9
    }
];



let id = 0;
function createData(name, calories, fat, carbs, protein) {
  id += 1;
  return { id, name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9)
];

function CustomizedTable(props) {
  const { classes } = props;

  return (
    <div>
    
    <ExcelFile element={ <Button variant="contained" size="large" color="primary" className="search-policy download-button"> Download Data </Button>}>
      <ExcelSheet data={dataSet1} name="Employees">
          <ExcelColumn label="Dessert" value="Dessert"/>
          <ExcelColumn label="Calories" value="Calories"/>
          <ExcelColumn label="Fat" value="Fat"/>
          <ExcelColumn label="Carbs" value="Carbs"/>
          <ExcelColumn label="Protein" value="Protein"/>
      </ExcelSheet>
    </ExcelFile>
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <CustomTableCell>Dessert</CustomTableCell>
            <CustomTableCell align="right">Calories</CustomTableCell>
            <CustomTableCell align="right">Fat</CustomTableCell>
            <CustomTableCell align="right">Carbs</CustomTableCell>
            <CustomTableCell align="right">Protein</CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow className={classes.row} key={row.id}>
              <CustomTableCell component="th" scope="row">
                {row.name}
              </CustomTableCell>
              <CustomTableCell align="right">{row.calories}</CustomTableCell>
              <CustomTableCell align="right">{row.fat}</CustomTableCell>
              <CustomTableCell align="right">{row.carbs}</CustomTableCell>
              <CustomTableCell align="right">{row.protein}</CustomTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
    </div>
  );
}

CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomizedTable);