import React from "react";
import DyanmicTableWithSorting from "../../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import { translate, Trans } from "react-i18next";
import _ from "lodash";
import SinistrosKeys from "./SinistrosKeys.json";
import Button from "@material-ui/core/Button";
import CloudDownload from "@material-ui/icons/CloudDownload";
import { getSinistrosExcel } from "./../../../api/ExcelDownload";
import EmptyState from "../../../../BaseComponents/EmptyState/EmptyState";
import VolverButton from "../../../../BaseComponents/VolverButtons/VolverButton";
import "./tabs.scss";

function Sinistros(props) {
  const SinistrosData = props.data.length;

  var downloadEcel = data => {
    getSinistrosExcel(SinistrosKeys, props.data);
  };
  return (
    <div className="tab-class">
      {SinistrosData > 0 ? (
        <>
          <DyanmicTableWithSorting
            data={props.data}
            keyArray={SinistrosKeys}
            orderBy={SinistrosKeys[0]}
            pathData={{}}
            isPaginationReq={false}
            isEnhancedTableHeadReq={false}
            defaultRowPerPage={10}
            virticalScroll={true}
            tableMaxHeight={"400"}
          />
          <Button
            variant="contained"
            size="medium"
            color="primary"
            disabled={false}
            className="search-icon search-policy"
            style={{ float: "right", marginBottom: "5px" }}
            onClick={downloadEcel}
          >
            <CloudDownload className="download-icon" />
            {"Excel"}
          </Button>
          <VolverButton />
        </>
      ) : (
        <div className={"content"}>
          <EmptyState
            emoticon={"🔍"}
            message={"No se encontraron Siniestros"}
          />
        </div>
      )}
    </div>
  );
}

export default translate("translations")(Sinistros);
