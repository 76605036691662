import React from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { translate, Trans } from "react-i18next";
import Button from "@material-ui/core/Button";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import Card from '@material-ui/core/Card';
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import _ from 'lodash';
import { api } from "../../api/caja-penta";
import  { columnName , columnNameTwo } from './constants'
import  { scrollToBottom } from  '../../../utils/scrollDown'

const styles = theme => ({
});

class DetalleDeComprobante extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
   
   
  }
  
  componentDidMount() {
    const { match: { params } , location : {search}     } = this.props;
    const  { oficina,anoContable,mesContable,tipoDocumento,correlativoint} = params;

    if(!_.isEmpty(oficina) && !_.isEmpty(anoContable) && !_.isEmpty(mesContable) && !_.isEmpty(tipoDocumento) && !_.isEmpty(correlativoint)) {
      const data =  {
        oficina : parseInt(oficina),
        anoContable : parseInt(anoContable),
        mesContable : parseInt(mesContable),
        tipoDocumento : parseInt(tipoDocumento),
        correlativoInt :parseInt(correlativoint)  
      }
      console.log(data)

      this.getDiscretaDetails3(data)
    }
    this.setState({search : search})
    
  }

  getDiscretaDetails3(data){
    this.showLoaderAction()
    try{

      let promises=[];
      promises.push( api.getDiscretaDetails3(data))
      promises.push( api.getDiscretaDetails4(data))
      promises.push( api.getDiscretaDetails5(data))

      Promise.all(promises)
      .then((res)=>{
        if(res && !_.isEmpty(res)){
          if(res.length === 3) {
            const data =  res[0].data;
            const Detalledecomprobante = data && data.dettaleDeTicket && data.dettaleDeTicket.length > 0 ?data.dettaleDeTicket[0] :[]
            const Detalledetickets = res[1].data.dettaleDeTicket;
            const details =  res[2].data.details

            this.setState({
              detalledecomprobante :  Detalledecomprobante || {},
              detalledetickets : Detalledetickets || [],
              details: details || []
            })
            scrollToBottom();
          }
        }else {
          toast.error(res.msg, {position: toast.POSITION.TOP_RIGHT});
        }
        this.showLoaderAction()
      })
      .catch((ex)=>{
        toast.error("Tiempo de espera agotado. Inténtalo de nuevo", {position: toast.POSITION.TOP_RIGHT});
        this.showLoaderAction();
      })
    }catch(ex){
      toast.error("Tiempo de espera agotado. Inténtalo de nuevo", {position: toast.POSITION.TOP_RIGHT});
      this.showLoaderAction();
    }
  }

  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  resetSearch() {
    this.setState({
      enableSearch : true,
      searchTextOne : "",
      searchTextTwo : ""
    });
  }


 
 showLoaderAction() {
  let showLoader = this.state.showLoader;
  this.setState({ showLoader: !showLoader });
}

goMainPage=()=>{
  let {search} = this.state;
  this.props.history.push(`/caja-penta/consulta-de-tickets${search}`)
}
 
 
  render() {
    const { i18n } = this.props;
    const { classes } = this.props;
    let translateObj = (i18n.store.data[i18n.language] && i18n.store.data[i18n.language].translations) ||  i18n.store.data["es"].translations;
   

    const detalledecomprobante =  this.state && this.state.detalledecomprobante  ?this.state.detalledecomprobante  :  null;
    const detalledetickets  = this.state && this.state.detalledetickets ?  this.state.detalledetickets : null;


    return (
      <main className="main-container">
        {this.state.showLoader ? <Loader /> : null}
        <div className="right-align">
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className="search-policy"
              onClick={this.goMainPage}
            >
              {"Volver"}
            </Button>
        </div>
        <div>
            <div>
              {detalledecomprobante ?
              <div className="margin-top-30">
                <Card className="Card-area box-show-none">
                    <fieldset className="custom-border">
                    <legend className="legend-box font-size-20">Consultar Mandatos Cliente</legend>
                        <div className="vertical-align-middle">
                            <label className="width-label">Fecha Caja</label>
                            <TextField InputLabelProps={{shrink: true }}
                                id="standard-bare"
                                className="textField"
                                value={detalledecomprobante.fechaCaja}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                readOnly: true,
                                }}
                                style = {{width: 300}}
                            /> 
                              <label className="width-label margin-left-100px"> Oficina de pago</label> 
                             <TextField InputLabelProps={{shrink: true }}
                                id="standard-bare"
                                className="textField"
                                value={detalledecomprobante.oficinaDePago}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                readOnly: true,
                                }}
                                style = {{width: 300}}
                            />                          
                        </div>
                        <div className="vertical-align-middle">
                            <label className="width-label">Monto en MP</label>
                            <TextField InputLabelProps={{shrink: true }}
                                id="standard-bare"
                                className="textField"
                                value={detalledecomprobante.montoEnMp}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                readOnly: true,
                                }}
                                style = {{width: 300}}
                            /> 
                              <label className="width-label margin-left-100px"> Moneda de Pago</label> 
                             <TextField InputLabelProps={{shrink: true }}
                                id="standard-bare"
                                className="textField"
                                value={detalledecomprobante.monedaDePago}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                readOnly: true,
                                }}
                                style = {{width: 300}}
                            />                          
                        </div>
                        <div className="vertical-align-middle">
                            <label className="width-label">Nombre Cajero</label>
                            <TextField InputLabelProps={{shrink: true }}
                                id="standard-bare"
                                className="textField"
                                value={detalledecomprobante.nombreCajero}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                readOnly: true,
                                }}
                                style = {{width: 300}}
                            /> 
                              <label className="width-label margin-left-100px">Nombre Caja</label> 
                             <TextField InputLabelProps={{shrink: true }}
                                id="standard-bare"
                                className="textField"
                                value={detalledecomprobante.nombreCaja}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                readOnly: true,
                                }}
                                style = {{width: 300}}
                            />                          
                        </div>
                    </fieldset>
                </Card>
              </div>    
              : null 
              }    
            </div>
        </div>
        {detalledetickets ?
        <div>
            <legend className="legend-box margin-top-bottom-caja">Detalle de tickets</legend>
              <DyanmicTableWithSorting 
                  data={detalledetickets}
                  keyArray={columnName}
                  orderBy={columnName[0]}
                  pathData={{}}
                  isPaginationReq={true}
                  isEnhancedTableHeadReq={true}
                  defaultRowPerPage={4}
              />
        </div>
        :  null 
        }
        { this.state.details ?
        <div>
            <legend className="legend-box margin-top-bottom-caja">Detalle de formas de pago</legend>
              <DyanmicTableWithSorting 
                  data={this.state.details}
                  keyArray={columnNameTwo}
                  orderBy={columnNameTwo[0]}
                  pathData={{}}
                  isPaginationReq={true}
                  isEnhancedTableHeadReq={true}
                  defaultRowPerPage={4}
              />
        </div>
        : null
        }
           
        <ToastContainer />
      </main>
    );
  }
}


DetalleDeComprobante.propTypes = {
  classes: PropTypes.object.isRequired
};

export default translate("translations")( withStyles(styles)(DetalleDeComprobante));


