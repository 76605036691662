import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Divider from "@material-ui/core/Divider";
import Typography from '@material-ui/core/Typography';
import { translate, Trans } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { MuiPickersUtilsProvider,  DatePicker} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import EmptyState from "../../../../BaseComponents/EmptyState/EmptyState";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash'

const styles = theme => ({
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gridGap: `${theme.spacing.unit * 3}px`,
      flexWrap: 'nowrap'
    },
    paper: {
      padding: theme.spacing.unit,
      textAlign: 'center',
      color: theme.palette.text.primary,
      fontSize: `15px`,
      whiteSpace: 'nowrap',
      fontFamily:'Roboto',
      marginBottom: theme.spacing.unit
    },
    divider: {
      margin: `${theme.spacing.unit * 2}px 0`,
    },
    card: {
      padding: `20px`
    }
  });


class Descuentos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showDescuentos: true,

        };
    }
    goToPreviousPage = () => {
        this.props.goToClasesDeContrato(4)
    };
    submitForm=()=>{
        // this.props.getSevenTabData();
        this.props.goToClasesDeContrato(6)
    }
    render() {
        const showDescuentos = this.state.showDescuentos;
        const { classes } = this.props;
        const data  = this.props.data;
        

        return (
            <div className="tab-class">                    
                <Card style={{ marginTop: "25px" }} className="Card-area box-show-none">
                    {data ? (
                    <div className={classes.root  + " margin-top-30px custom-scroll-style"}>
                        <table style={{ tableLayout:'fixed', width:'100%'}} > 
                            <tr> 
                                <th style={{width: "200px" }} className="no-border">{""}</th> 
                                {data.map((value, index) => {
                                    let bln  = value.nombreCompañía ?  false : true
                                    return <> 
                                        <th className={bln ?"no-border" :"grey-background"} style={{width: "200px" }} colspan="4">
                                            {value.nombreCompañía }
                                        </th> 
                                        </>
                                })} 
                            </tr> 
                            <tr> 
                                <th style={{width: "200px" }} className="no-border">{""}</th> 
                                {data.map((value, index) => {
                                    let bln  = value.nombreSecurity ?  false : true
                                    return <> 
                                        <th className={bln ?"no-border" :"col-highlight"} style={{width: "200px" }} colspan="4">
                                            {value.nombreSecurity }
                                        </th> 
                                        </>
                                })} 
                            </tr> 
                            <tr> 
                                <th className="col-highlight">{"Participacion"}</th> 
                                {data.map((value, index) => {
                                    return <> 
                                        {(!value.pondedaro ) ?
                                            <>
                                            <td colspan="2">{value.cP.participación||"0.00"} %</td>
                                            <td colspan="2">{value.eXc.participación||"0.00"}%</td>
                                            </>
                                            : <td colspan="4">{"Ponderado"}</td> 
                                        }
                                        </>
                                })} 
                            </tr>
                            <tr> 
                                <th className="col-highlight">{"Indice de Riesgo"}</th> 
                                {data.map((value, index) => {
                                    return <> 
                                            <td colspan="2" className="grey-background">{value.cP.clase}</td>
                                            <td colspan="2" className="grey-background">{value.eXc.clase}</td>
                                        </>
                                })} 
                            </tr> 
                            <tr> 
                                <th>{"Sin Indice de Riesgo"}</th> 
                                {data.map((value, index) => {
                                    return <> 
                                            <td colspan="2">{value.cP.descContrato}</td>
                                            <td colspan="2">{value.eXc.descContrato}</td>
                                        </>
                                })} 
                            </tr>
                        </table> 
                    </div>                    
                        ) : (
                            <div className={"content"}>
                                <EmptyState
                                    emoticon={"🔍"}
                                    message={"No se encontraron datos"}
                                />
                            </div>
                        )}
                    <div className="reso-btn-group">
                        <div className="btn">
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                onClick={this.goToPreviousPage}
                                className="search-policy pull-right ">Volver</Button>
                        </div>
                        <div className="btn" >
                            <Button 
                                variant="contained" 
                                size="medium" 
                                color="primary" 
                                className="search-policy pull-right" 
                                onClick={this.submitForm}>
                                Confirmar cambios y continuar
                            </Button>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }
}



Descuentos.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};
  
export default translate("translations")(withStyles(styles, { withTheme: true })(Descuentos));

