import React from 'react';
import { translate, Trans } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import DyanmicTable from "./../../../../BaseComponents/DyanmicTableParticipation/DyanmicTable"
import {contratosTabFourKeys} from "../../../../Constants/reaseguradoresKeys"
import EmptyState from "../../../../BaseComponents/EmptyState/EmptyState";

class Participaciones extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showParticipaciones: true,
            seleccioneParticipante: "",
            ingresePorcentaje: "",
            nombreClass: "",
            tasaMinimaResidential: "",
            tasaMinimaNonResidential: ""
        };
    }
    goToPreviousPage = () => {
        this.props.goToClasesDeContrato(2)
    };  
    submitForm=()=>{
        // this.props.getFifthTabData();
        this.props.goToClasesDeContrato(4)
    }
    render() {
        const showParticipaciones = this.state.showParticipaciones;
        let tableData=this.props.data;
        return (
            <div className="tab-class">
                    <Card style={{ marginTop: "25px" }} className="Card-area box-show-none">
                    {tableData ? (
                        <DyanmicTable
                            data={tableData}
                            keyArray={contratosTabFourKeys}
                            orderBy={contratosTabFourKeys[0]}
                            virticalScroll={true}
                            tableMaxHeight={"450"}
                            hideColoumn={["clase","tipo"]}
                        />
                    ) :
                    <div className={"content"}>
                        <EmptyState
                            emoticon={"🔍"}
                            message={"No se encontraron datos"}
                        />
                    </div>
                    }
                        <div className="reso-btn-group">
                            <div className="btn">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    className="search-policy pull-right " onClick={this.goToPreviousPage} >Volver</Button>
                            </div>
                            <div className="btn " >
                                    <Button variant="contained" size="medium" color="primary" className="search-policy  pull-right" onClick={this.submitForm}>
                                        Confirmar cambios y continuar
                                    </Button>
                                </div>
                        </div>
                    </Card>
            </div>
        )
    }
}

export default translate("translations")(Participaciones);