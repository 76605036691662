export const MENU = [
  {
    name: "Consulta General",
    key: "consulta_general",
    link: "/global-search",
    defaultPage: "/policy",
    accessRole: "ADMIN",
    icon: "StoreIcon",
    children: [
      {
        name: "Policy",
        link: "/policy",
        key: "policy",
        icon: "HomeIcon"
      },
      {
        name: "Claims",
        link: "/claims",
        key: "claims",
        icon: "LibraryBooksIcon"
      },
      {
        name: "Items",
        link: "/items-list",
        key: "items",
        icon: "TocIcon"
      },
      {
        name: "Buscar",
        link: "/buscar",
        key: "buscar",
        icon: "PageviewIcon"
      },
      {
        name: "Collections",
        link: "/collections",
        key: "collections",
        icon: "VerticalSplitIcon"
      },
      {
        name: "Endorsement",
        link: "/endorsement",
        key: "endorsement",
        icon: "CheckBoxIcon"
      },
      {
        name: "CoInsurance",
        link: "/coinsurance",
        key: "coInsurance",
        icon: "SupervisorAccountIcon"
      },
      {
        name: "Patente & Motor",
        link: "/patento-motor",
        key: "patenteMotor",
        icon: "CheckBoxIcon"
      },
      {
        name: "Referencia Externa",
        link: "/broker-reference",
        key: "brokerReference",
        icon: "CheckBoxIcon"
      },
      {
        name: "Documento",
        link: "/documento",
        key: "documento",
        icon: "CheckBoxIcon"
      }
    ]
  },
  {
    name: "Consulta Global",
    key: "consulta_global",
    defaultPage: "/porcliente",
    link: "/global-search",
    accessRole: "ADMIN",
    icon: "StoreIcon",
    children: [
      {
        name: "Por Cliente",
        link: "/consulta-global/por-cliente",
        key: "porcliente",
        icon: "PageviewIcon"
      },
      {
        name: "Por Siniestro",
        link: "/consulta-global/por-siniestro",
        key: "porsinistro",
        icon: "PageviewIcon"
      },
      {
        name: "Por Referencia Corredor",
        link: "/consulta-global/por-referencia-corredor",
        key: "porReferenciaCorredor",
        icon: "PageviewIcon"
      },
      {
        name: "Por Poliza",
        link: "/consulta-global/por-poliza",
        key: "porpoliza",
        icon: "PageviewIcon"
      },
      {
        name: "Por Ramos",
        link: "/consulta-global/por-ramos",
        key: "porramos",
        icon: "PageviewIcon"
      },
      {
        name: "Por N° Negocio",
        link: "/consulta-global/por-n-negocio",
        key: "pornegocio",
        icon: "PageviewIcon"
      }
    ]
  },
  {
    name: "Consulta Pat Pac",
    key: "consulta_pat_pac",
    defaultPage: "/pac-pat/consulta-por-rut",
    accessRole: "ADMIN",
    icon: "StoreIcon",
    children: [
      {
        name: "Consulta de Mandatos",
        link: "/pac-pat/consulta-por-rut",
        key: "consultademandatos",
        icon: "PageviewIcon"
      },
      {
        name: "Generacion de Cargos",
        link: "/pac-pat/consulta-por-estado",
        key: "generaciondecargos",
        icon: "PageviewIcon"
      }
    ]
  },
  {
    name: "Caja Pagadora",
    accessRole: "ADMIN",
    key: "caja_pagadora",
    defaultPage: "/caja-pagadora/consulta-de-cheques",
    icon: "StoreIcon",
    children: [
      {
        name: "Consulta De Cheques",
        link: "/caja-pagadora/consulta-de-cheques",
        key: "consultadecheques"
      }
    ]
  },
  {
    name: "Siniestros Ramos Varios",
    key: "siniestros_ramos_varios",
    defaultPage: "/ramos/consultasiniestros",
    accessRole: "ADMIN",
    icon: "StoreIcon",
    children: [
      {
        name: "Consulta Siniestros",
        link: "/ramos/consultasiniestros",
        key: "consultasiniestros",
        icon: "PageviewIcon"
      }
    ]
  },
  {
    name: "Sinestro Vehiculo",
    key: "sinestroVehiculo",
    defaultPage:"/vehiculos/siniestros-vehiculos",
    accessRole :"ADMIN",
    icon: "StoreIcon",
    children: [
      {
        name: "Siniestros Vehiculos",
        link: "/vehiculos/siniestros-vehiculos",
        key: "siniestrosVehiculos",
        icon: "PageviewIcon"
      }
    ]
  },
  {
    name: "Análisis de Recuperos",
    key: "analista_recuperos",
    defaultPage:"/analista-recuperos/consultasiniestros",
    accessRole :"ADMIN",
    children: [
      {
        name: "Consulta Siniestros",
        link: "/analista-recuperos/consultasiniestros",
        key: "consultasiniestros",
        icon: "PageviewIcon"
      },{
        name: "Gestión CIR",
        link: "/analista-recuperos/gestion-cir",
        key: "gestioncir",
        icon: "PageviewIcon"
      },{
        name: "Area Abogados Externos",
        link: "/analista-recuperos/area-trabajo",
        key: "gestioncir",
        icon: "PageviewIcon"
      }
    ]
  },
  {
    name: "Reaseguros",
    key: "consulta_de_reaseguro",
    defaultPage:"/reaseguradores/consulta-reaseguro",
    children: [
      {
        name: "Consulta por Distribucion",
        link: "/reaseguradores/consulta-por-distribucion",
        key: "consultareaseguro",
        icon:  "PageviewIcon"
      },
      {
        name: "Consulta de Reaseguros",
        link: "/reaseguradores/consulta-reaseguro",
        key: "consultareaseguro",
        icon: "PageviewIcon"
      },
      {
        name: "Contratos",
        link: "/reaseguradores/contratos",
        key: "consultareaseguro",
        icon:  "PageviewIcon"
      }]
  },
  {
    name: "Caja Penta",
    key: "caja_penta",
    defaultPage:"/caja-penta/consulta-de-tickets",
    accessRole :"ADMIN",
    icon: "StoreIcon",
    children: [
      {
        name : "RCD Discreta",
        link : "/caja-penta/consulta-de-tickets",
        key :  "consultaDeTickets",
        icon:  "PageviewIcon"
      },
      {
        name: "RCD Masiva",
        link: "/caja-penta/filterosh-historial",
        key: "filteroshHistorial",
        icon: "PageviewIcon"
      }
    ]
  }
];
