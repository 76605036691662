import React from "react";
import { config, base_url, axiousInstance } from './index';
let axios=axiousInstance;


export  function getTotalBrokerReference() {
 // url :  https://lrigoocw6l.execute-api.us-east-1.amazonaws.com/dev/getTotalBrokerReference 

  return axios
  .post(base_url + "getTotalBrokerReference",{} ,config)
  .then(res => {
    return res && res.data;
  })
  .catch(error => {
    console.log("error " + error);
    return error;
  });
}


export function getBrokerReference(RutCorredor,Referencia){
// url :  https://lrigoocw6l.execute-api.us-east-1.amazonaws.com/dev/getBrokerReference 

 let data = {
  "RutCorredor": RutCorredor,
  "Referencia": Referencia
  };

  
  return axios
  .post(base_url + "getBrokerReference",data ,config)
  .then(res => {
    return res && res.data;
  })
  .catch(error => {
    console.log("error " + error);
    return error;
  });
}