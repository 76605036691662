import React from "react";
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import TextField from "@material-ui/core/TextField";
import { translate, Trans } from "react-i18next";
import Button from "@material-ui/core/Button";
import {
  getCoverageByPolicyItemNo,
  servicioAsistenciaDetail
} from "./../../api/coverage";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import _ from "lodash";
import { scrollToBottom } from "../../../utils/scrollDown";
import "./Coverage.scss";

class CoverageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blnShowCoverage: false,
      coverageData: undefined,
      isEnhancedTableHeadReq: false,
      showLoader: false,
      policyNo: undefined,
      itemNo: undefined,
      showAsistencias: false,
      assistenciasData: undefined,
      showVolver: false
    };

    this.showAsistenciasData = this.showAsistenciasData.bind(this);
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    const policyNo = Number.parseInt(params.policyNo);
    const itemNo = Number.parseInt(params.itemNo);
    this.setState({ policyNo: policyNo, itemNo: itemNo, showVolver: true });
    if (policyNo && itemNo) {
      const data = { policyNo: policyNo, itemNo: itemNo };
      this.getCoverageData(data);
    }
  }

  getCoverageData(data) {
    this.showLoader();
    getCoverageByPolicyItemNo(data)
      .then(res => {
        if (res.data && res.data.coverageDetails && res.data.title) {
          let resObj = res.data.coverageDetails;
          resObj.splice(-2, 1);
          resObj[resObj.length - 1]["total"] = true;
          this.setState({
            blnShowCoverage: true,
            coverageData: resObj,
            title: res.data.title
          });
        } else {
          toast.error(
            res.msg ||
              "No coverage found for given Policy no : " + this.state.policyNo,
            {
              position: toast.POSITION.TOP_RIGHT
            }
          );
        }
        this.showLoader();
      })
      .catch(ex => {
        console.log(ex);
      });
  }

  showLoader() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  resetSearch() {
    this.setState({
      blnShowEndorsement: false,
      searchText: "",
      data: undefined
    });
  }

  showAsistenciasData() {
    this.showLoader();
    let { policyNo, itemNo } = this.state;
    servicioAsistenciaDetail(policyNo, itemNo)
      .then(res => {
        if (res.data && res.data.assistDetails) {
          this.setState({
            showAsistencias: true,
            assistenciasData: res.data.assistDetails
          });
        } else {
          toast.error(res.msg, { position: toast.POSITION.TOP_RIGHT });
        }
        scrollToBottom();
        this.showLoader();
      })
      .catch(ex => {
        toast.error("Something went wrong.", {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  }

  backToPreviousPage = () => {
    window.history.back();
  };

  render() {
    const keyArray = {
      card: "Cob,Adic.,Rec.y Desctos.",
      monto: "Monto",
      tasa: "Tasa",
      fija: "Prima fija",
      afecta: "Prima Neta Afecta",
      exenta: "Prima Neta Exenta"
    };

    const keyAsistencias = {
      codigoContrato: "Contrato",
      nombreContrato: " Nombre Contrato",
      codigoServicio: "Servicio",
      nombreServicio: "Nombre de Servicio",
      primaVenta: "Prima Venta"
    };

    return (
      <main className="main-container">
        {this.state.showLoader ? <Loader /> : null}

        {this.state.blnShowCoverage && this.state.coverageData ? (
          <>
            <h3>{this.state.title}</h3>
            <DyanmicTableWithSorting
              data={this.state.coverageData}
              keyArray={keyArray}
              orderBy={keyArray[0]}
              pathData={{}}
              isEnhancedTableHeadReq={this.state.isEnhancedTableHeadReq}
              virticalScroll={true}
              tableMaxHeight={"400"}
              passClick={this.showAsistenciasData}
              coloumnLeftAlign={"card"}
            />
          </>
        ) : null}

        {this.state.showAsistencias && this.state.assistenciasData ? (
          <>
            <h3 className="title-ssistencia">
              [ Detalle de Servicios de Asistencia ]
            </h3>
            <DyanmicTableWithSorting
              data={this.state.assistenciasData}
              keyArray={keyAsistencias}
              orderBy={keyAsistencias[0]}
              pathData={{}}
              isPaginationReq={this.state.isPaginationReq}
              isEnhancedTableHeadReq={this.state.isEnhancedTableHeadReq}
              defaultRowPerPage={this.state.defaultRowPerPage}
              virticalScroll={true}
              tableMaxHeight={"400"}
              // passClick={this.showAsistenciasData}
            />
          </>
        ) : null}
        <ToastContainer />
        {(this.state.coverageData || this.state.assistenciasData) &&
        this.state.showVolver ? (
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className="search-policy"
            onClick={this.backToPreviousPage}
            style={{ float: "right", marginTop: 10 }}
          >
            {"Volver"}
          </Button>
        ) : null}
      </main>
    );
  }
}

export default translate("translations")(CoverageComponent);
