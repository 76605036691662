import React from "react";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
    {
        name: "Johson",
        amount: 30000,
        sex: 'M',
        is_married: true
    },
    {
        name: "Monika",
        amount: 355000,
        sex: 'F',
        is_married: false
    },
    {
        name: "John",
        amount: 250000,
        sex: 'M',
        is_married: false
    },
    {
        name: "Josef",
        amount: 450500,
        sex: 'M',
        is_married: true
    }
];

var dataSet2 = [
    {
        name: "Johnson",
        total: 25,
        remainig: 16
    },
    {
        name: "Josef",
        total: 25,
        remainig: 7
    }
];

class ExcelDownload extends React.Component {

    constructor(props){
        super(props);
    }
    componentWillReceiveProps(){
        document.getElementById("downloadexcel").click();
    }
    componentDidMount(){
        document.getElementById("downloadexcel").click();
    }
    render() {
        let excelData = this.props.excelData;
        let sheetName=this.props.sheetName;
        return (
            <ExcelFile filename={this.props.excelName} element={<button style={{ display:"none"}} id="downloadexcel" >Download Data</button>}>
                {/* {
                    excelData.map((data, index) => ( 
                    <ExcelSheet data={data.dataSet} name={data.name} key={index}>
                    {
                        data.headers.map((header,index2)=>(
                            <ExcelColumn key={header + index2}  label={header} value={header}/>

                        ))
                    }
                    
                </ExcelSheet>
                ))} */}
                <ExcelSheet dataSet={excelData} name={sheetName}/>
            </ExcelFile>
        );
    }
}

export default ExcelDownload;
