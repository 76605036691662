import React from 'react';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";


class DetalleReaseguroItemPorCobertura extends React.Component {
    constructor(props){
        super(props);
    }



    render(){

        const PorCoburturaData= [
            {"Tipo Distribución" : "COASEGURO OTRA CÍA." , "Porcentaje" : "30.00" , "Monto Asegurado" : "335,437.20" , "Prima" : "389.7093"  , "Descuento" : ""},
            {"Tipo Distribución" : "FACULTATIVO" , "Porcentaje" : "70.00" , "Monto Asegurado" : "782,686.80" , "Prima" : "389.7093"  , "Descuento" : ""}
        ]
        const  columnPorCoburtura = {
            "Tipo Distribución" :"Tipo Distribución" , "Porcentaje" : "Porcentaje(%)" , "Monto Asegurado"  : "Monto Asegurado" , "Prima" : "Prima" , "Descuento":  "Descuento(%)"  
        }
        
        return(
            <main className="main-container">
                <Card className="Card-area box-show-none">
                <fieldset className="custom-border">
                <legend className="legend-box">Datos Generales Ítem</legend>
                <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare"
                            label="N° Póliza"
                            className="textField"
                            value={"N° Póliza: "}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 400}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{   shrink: true }}
                            id="standard-bare"
                            label="N° Ítem: "
                            className="textField"
                            value={"N° Ítem: "}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 400}}
                        />
                    </span>
                </div>
                <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare"
                            label="Vigencia Desde"
                            className="textField"
                            value={"Vigencia Desde"}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 400}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{   shrink: true }}
                            id="standard-bare"
                            label="Vigencia Hasta"
                            className="textField"
                            value={"Vigencia Hasta"}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 400}}
                        />
                    </span>
                </div>
                <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare"
                            label="Ramo"
                            className="textField"
                            value={"Ramo"}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 400}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{   shrink: true }}
                            id="standard-bare"
                            label="Moneda"
                            className="textField"
                            value={"Moneda"}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 400}}
                        />
                    </span>
                </div>
                <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare"
                            label="Nombre Contratante"
                            className="textField"
                            value={"Nombre Contratante"}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 820}}
                        />
                    </span>
                </div>
                <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare"
                            label="Nombre Asegurado"
                            className="textField"
                            value={"Nombre Asegurado"}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 820}}
                        />
                    </span>
                </div>
                <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare"
                            label="Uso Destino "
                            className="textField"
                            value={"Uso"}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 400}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{   shrink: true }}
                            id="standard-bare"
                            label=""
                            className="textField"
                            value={"Destino"}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 400}}
                        />
                    </span>
                </div>
                <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare"
                            label="Tipo de Construcción"
                            className="textField"
                            value={"Tipo de"}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 400}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{   shrink: true }}
                            id="standard-bare"
                            label=""
                            className="textField"
                            value={"Construcción"}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 400}}
                        />
                    </span>
                </div>
                <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare"
                            label="Nombre Contrato"
                            className="textField"
                            value={"Nombre Contrato"}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 820}}
                        />
                    </span>
                </div>
                <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare"
                            label="Forzado"
                            className="textField"
                            value={"N0"}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 820}}
                        />
                    </span>
                </div>
            </fieldset>
            </Card>
            <Card className="Card-area box-show-none">
                <fieldset className="custom-border">
                <legend className="legend-box">Detalle Ítem  vigente entre 28/03/2017 y 09/07/2017 </legend>
                <DyanmicTableWithSorting 
                          data={PorCoburturaData}
                          keyArray={columnPorCoburtura}
                          orderBy={columnPorCoburtura[0]}
                          pathData={{}}
                          virticalScroll={true}
                          tableMaxHeight={"400"}
                          isEnhancedTableHeadReq={false}
                          virticalScroll={true}
                          tableMaxHeight={"400"}                        
                         />
                </fieldset>
            </Card>
            </main>
        )
    }
}


export default DetalleReaseguroItemPorCobertura;