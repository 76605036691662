import React from 'react'
import Card from '@material-ui/core/Card';
import DyanmicTable from '../../../../BaseComponents/DyanmicTable/DyanmicTable';
import {sinistroKeys } from "./../../../../Constants/claim"

export default function SinistroComponent(props) {
  const sinistroData = props.sinistro;
  return (
    <div>
       {sinistroData ?
          <div className="margin-for-table">
            <DyanmicTable data={sinistroData} id="item" keyArray={sinistroKeys} virticalScroll={true}
              tableMaxHeight={"400"} />
          </div>
      : null }
    </div>
   
  )
}
