import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { translate, Trans } from "react-i18next";
import Button from "@material-ui/core/Button";
import { getItemDetails } from "../../api/policy";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "calc(100% - 240px)",
    backgroundColor: theme.palette.background.paper
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  }
});

class PorPoliza extends React.Component {
  constructor(props) {
    super(props);

    let reqObject = {};
    reqObject.p_Criterio = 1006;
    reqObject.p_R_VLO_CODIGO_UNO = 0;
    reqObject.p_R_VLO_COD_SUS = 0;
    reqObject.p_R_VLO_FEC_INICIO = 0;
    reqObject.p_R_VLO_FEC_TERMINO = moment(new Date()).format("YYYYMMDD");
    reqObject.p_R_VST_CODIGO_UNO = "T";
    reqObject.p_R_VST_CODIGO_DOS = "";
    reqObject.p_R_VLO_FEC_POL_INI = 0;
    reqObject.p_R_VLO_FEC_POL_TER = moment(new Date()).format("YYYYMMDD");
    reqObject.p_R_VLO_COD_RAMOS = "";
    reqObject.p_CodigoOficina = 0;
    reqObject.p_RutUsuario = 0;
    reqObject.slug = "consulta-global/por-poliza";
    reqObject.porPoliza = true;
    reqObject.flag = "porPoliza";

    this.state = {
      value: 0,
      claimObj: undefined,
      policyNo: "",
      enableSearch: true,
      itemNo: "",
      showLoader: false,
      reqObj: reqObject
    };
  }

  handleChange = event => {
    const value = event.target.value;
    let bln = value.length > 0 ? false : true;
    this.setState({ policyNo: value, enableSearch: bln });
  };

  handleItemChange = event => {
    const value = event.target.value;
    this.setState({ itemNo: value });
  };

  showPorPoliza = () => {
    let itemValue = this.state.itemNo ? parseInt(this.state.itemNo) : 0;
    this.setState(
      Object.assign(this.state.reqObj, {
        p_R_VLO_CODIGO_UNO: parseInt(this.state.policyNo),
        p_R_VLO_COD_SUS: itemValue
      })
    );
    console.log("this.state---->", this.state);
    this.props.addPorPolizaFormData(this.state);
  };

  resetSearch = () => {
    this.setState({
      policyNo: "",
      itemNo: "",
      enableSearch: true,
      reqObj: undefined
    });

    this.props.deletePorPolizaFormData();
  };

  componentDidMount() {
    const { porPolizaObj } = this.props;
    if (porPolizaObj && !_.isEmpty(porPolizaObj)) {
      let {
        claimObj,
        enableSearch,
        itemNo,
        policyNo,
        reqObj,
        showLoader
      } = this.props.porPolizaObj;
      if (policyNo && reqObj) {
        this.setState({
          claimObj: claimObj,
          enableSearch: enableSearch,
          itemNo: itemNo,
          policyNo: policyNo,
          reqObj: reqObj,
          showLoader: showLoader
        });
      }
    }
  }

  render() {
    const { i18n } = this.props;
    let translateObj =
      (i18n.store.data[i18n.language] &&
        i18n.store.data[i18n.language].translations) ||
      i18n.store.data["es"].translations;
    const { classes } = this.props;
    const { value } = this.state;
    const showLoader = this.state.showLoader;
    const reqObj = this.state.reqObj;

    return (
      <div className="main-container">
        {showLoader ? <Loader /> : null}
        <div className="search-wrapper">
          <TextField
            id="standard-search"
            label={"Poliza"}
            type="search"
            className="text-field"
            margin="normal"
            value={this.state.policyNo}
            onChange={this.handleChange}
          />
          <TextField
            id="standard-item-search"
            label={"Item"}
            type="search"
            className="text-field"
            margin="normal"
            value={this.state.itemNo}
            onChange={this.handleItemChange}
          />
          <Link
            to={{
              pathname: `/consulta-global/por-poliza/line-of-business/ramo`,
              state: reqObj
            }}
          >
            <Button
              variant="contained"
              size="medium"
              color="primary"
              disabled={this.state.enableSearch}
              onClick={this.showPorPoliza}
              className="search-policy"
            >
              {translateObj.policy_page.search_btn}
            </Button>
          </Link>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className="search-policy"
            onClick={this.resetSearch}
          >
            {translateObj.policy_page.reset_btn}
          </Button>
        </div>
      </div>
    );
  }
}

PorPoliza.propTypes = {
  classes: PropTypes.object.isRequired
};

// let translateObj = translate("translations")(PorPoliza);
// export default withStyles(styles)(translateObj);

function mapStateToProps(state) {
  console.log("state------>", state);
  return {
    porPolizaObj: state.porPolizaObj
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addPorPolizaFormData: porReduxReqObj =>
      dispatch({ type: "ADD_POR_POLIZA_FORM_DATA", porReduxReqObj }),
    deletePorPolizaFormData: () =>
      dispatch({ type: "DELETE_POR_POLIZA_FORM_DATA" })
  };
}

let porPolizaObj = connect(
  mapStateToProps,
  mapDispatchToProps
)(PorPoliza);

let translateObj = translate("translations")(porPolizaObj);
export default withStyles(styles)(translateObj);
