import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import _ from 'lodash'
import { translate, Trans } from "react-i18next";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import './Reaseguradores.scss'
import Card from '@material-ui/core/Card';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from 'material-ui-pickers';
import SearchIcon from "@material-ui/icons/Search";
import DetaileReaseguroItem from './DetaileReaseguroItem'
import Calendar from '../../../BaseComponents/CalendarComponent/Calendar';
import {  api } from '../../api/reasuguro'
import moment from "moment";
import 'moment/min/locales';

const styles = theme => ({});

  

class ConsultaReaseguro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        showLoader :  false,
        fechaConsulta: null,
        policyNo : null,
        itemNo  : null ,
        enableSearch : true

    };

  }

  handleDateChange = date => {
    this.setState({ 
      fechaConsulta: date , 
      enableSearch : false });
  };

  componentDidMount(){
    const { match: { params }  } = this.props;
    let {policyNo , itemNo , fechaConsulta } = params;
    fechaConsulta =  moment(fechaConsulta).format("YYYY/MM/DD")
    if(policyNo && itemNo && fechaConsulta) {
      this.setState({
        policyNo :  policyNo,
        itemNo :  itemNo ,
        fechaConsulta : new Date(fechaConsulta),
        enableSearch : false
      })
    }

  }

  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  nextPage(policyNo,itemNo,fechaConsulta,vigenteDesde,vigenteHasta){
    this.props.history.push(`/reaseguradores/consulta-reaseguro/detaile-reaseguro-item/${policyNo}/${itemNo}/${fechaConsulta}/${vigenteDesde}/${vigenteHasta}`)
  }

  getReaseguroData = ()=>{
    let {policyNo,itemNo,fechaConsulta}  = this.state;
    policyNo =  !_.isEmpty(policyNo) ? parseInt(policyNo) : null;
    itemNo = !_.isEmpty(itemNo) ? parseInt(itemNo) : null;
    fechaConsulta = !_.isNull(fechaConsulta) ? moment(fechaConsulta).format("YYYYMMDD") : null;

    if(!policyNo){
      toast.error("Debe ingresar Número de Póliza. ", {position: toast.POSITION.TOP_RIGHT});
      return;
    }

    if(policyNo && fechaConsulta && !itemNo){
      toast.error("Debe ingresar Número de Item.", {position: toast.POSITION.TOP_RIGHT});
      return;
    }
    if(policyNo && itemNo && !fechaConsulta){
      toast.error("Debe ingresar Fecha de Consulta.", {position: toast.POSITION.TOP_RIGHT});
      return;
    }
    this.showLoaderAction()
    try{
      api.getScreen1(policyNo,itemNo,fechaConsulta)
      .then((res)=>{
        if(res && res.data && !_.isEmpty(res.data)) {
          
          if(!_.isNull(policyNo) && !_.isNull(itemNo)){
            let data =  res.data
            let {vigenteDesde ,vigenteHasta}  = data;
            let itemMessage = `Ítem Vigente desde el ${vigenteDesde}  hasta  ${vigenteHasta}`
            if(this.state && this.state.itemMessage){
              itemMessage =  null; 
              let startDate = vigenteDesde.replace(/\//g, "")
              let endDate = vigenteHasta.replace(/\//g, "")
              this.nextPage(policyNo,itemNo,fechaConsulta,startDate,endDate);
            }
            this.setState({
              data :  data,
              policyMessage : null,
              itemMessage :  itemMessage,
              vigenteDesde : vigenteDesde,
              vigenteHasta: vigenteHasta
            })

          }else if(!_.isNull(this.policyNo)){
            let data =  res.data
            let {vigenciaDesde ,vigenciaHasta ,fechaConsulta, cantidadItem}  = data;
            let policyMessage = `Póliza Vigente desde el ${vigenciaDesde}  hasta  ${vigenciaHasta} .Cantidad de Ítems  ${cantidadItem}`
            this.setState({
              data :  data,
              policyMessage :  policyMessage,
              cantidadItem : cantidadItem,
              fechaConsulta:  new Date(fechaConsulta)
            })            
          }
      }else {
        toast.error(res.msg, {position: toast.POSITION.TOP_RIGHT});
      }        
      this.showLoaderAction();
      })
      .catch((ex)=>{
        toast.error("Something went wrong.", {position: toast.POSITION.TOP_RIGHT});
        this.showLoaderAction()
      })
    }catch(ex){
      toast.error("Something went wrong.", {position: toast.POSITION.TOP_RIGHT});
      this.showLoaderAction()
    }
    
  }

  handleChange =(event)=>{
    const value = event.target.value;
    if(event.target.name === "policyNo"){
      this.setState({ policyNo: value, enableSearch : false});
    } else if(event.target.name === "itemNo") {
      this.setState({ itemNo: value, enableSearch : false });
    }
  }

  resetSearch =()=>{
    this.setState({
      policyNo : "",
      itemNo:  "",
      fechaConsulta : null,
      itemMessage : null,
      policyMessage :  null
    })
  }

  

  
  render() {

    const { classes, theme } = this.props;
    const { selectedDate } = this.state;

    
    const { i18n } = this.props;
    let translateObj =     (i18n.store.data[i18n.language] && i18n.store.data[i18n.language].translations) ||  i18n.store.data["es"].translations;
  
    return (
      <main className="main-container">
        {this.state.showLoader ? <Loader /> : null}
        <Card className="Card-area box-show-none">
            <fieldset className="custom-border">
              <legend className="legend-box">Parámetros de Búsqueda</legend> 
              <div className="center-div">
                <div>
                  <div className="div-inline">
                    <label className="labe-margin">(*) Número de Póliza:</label>
                    <div className="">
                        <TextField InputLabelProps={{shrink: true}}
                          id="standard-bare"
                          className="textField"
                          value={this.state.policyNo}
                          margin="normal"
                          variant="outlined"
                          onChange={this.handleChange}
                          InputProps={{
                              readOnly: false,
                          }}
                          name="policyNo"
                          style = {{width: 300}}
                        />
                    </div>
                  </div>
                </div>
                <div>
                     {
                       this.state && this.state.policyMessage ?
                      <label> {this.state.policyMessage}</label>
                      :null
                      }
                  </div>
                 <div>
                  <div className="div-inline">
                    <label className="labe-margin">(*) Número de Ítem:</label>
                    <div>
                        <TextField InputLabelProps={{shrink: true}}
                          id="standard-bare"
                          className="textField"
                          value={this.state.itemNo}
                          onChange={this.handleChange}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                              readOnly: false,
                          }}
                          name="itemNo"
                          style = {{width: 300}}
                        />                   
                    </div>
                  </div>
                  <div>
                     {
                       this.state && this.state.itemMessage ?
                      <label> {this.state.itemMessage}</label>
                      :null
                      }
                  </div>
                </div>
                <div>
                <div className="div-inline">
                  <label className="labe-margin">(*) Fecha de Consulta:</label>
                  <div className="drop-down-menu">
                    <div class="space-for-calender">
                        <Calendar 
                          selectedDate = {this.state.fechaConsulta}
                          handleDateChange=  {this.handleDateChange}
                          format = {"dd/MM/yyyy"}
                          placeholder = {""}
                        />
                      </div>
                  </div>
                  </div>                
              </div>
              </div>
              <div className="center-div">
                <div>
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className="search-policy button-margin"
                        disabled={this.state.enableSearch}
                        onClick={this.getReaseguroData}
                    >
                      {translateObj.policy_page.search_btn}
                    </Button>
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className="search-policy"
                        onClick={this.resetSearch}
                    >
                      {translateObj.policy_page.reset_btn}
                    </Button>
                </div>
              </div>
            </fieldset>
       </Card>
        <ToastContainer />
      </main>
    );
  }
}

ConsultaReaseguro.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
  };
  
export default translate("translations")(withStyles(styles, { withTheme: true })(ConsultaReaseguro));


