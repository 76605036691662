export const collectionColumn = {
  poliza: "Poliza",
  documento: "Documento",
  cuota: "Nro. Cuota",
  primaCuota: "Prima Cuota",
  intCuota: "Interés Cuota",
  amortizacion: "Amortizacion",
  fVenc: "Fecha Venc.",
  primaPagada: "Prima Pagada",
  endoso: "Endoso",
  fPago: "Fecha pago",
  item: "item",
  intPagado: "Interés Pagado",
  intMoraPesos: "Interés Mora Pesos",
  pagadoPesos: "Pagado Pesos",
  origen: "Origen",
  tipoDocto: "Tipo Docto",
  planilla: "Planilla",
  recibo: "Recibo",
  ofcPago: "Ofic_Pago",
  pac: "Pac"
};

export const resumenColumns = {
  documento: "Documento",
  cuota: "Cuota",
  primaCuota: "Prima Cuota",
  intCuota: "Interés Cuota",
  amortizacion: "Amortizacion",
  fVenc: "Fecha Venc.",
  primaPagada: "Prima Pagada",
  intPagado: "Interés Pagado",
  intMoraPesos: "Interés Mora  Pesos",
  pagadoPesos: "Pagado Pesos",
  origen: "Origen",
  tipoDocto: "Tipo Dcto",
  pac: "PAC-PAT"
};

export const PolizaDetailColumns = {
  poliza: "Poliza",
  endoso: "Endoso",
  item: "item",
  captial: "Captial"
};

export const insertDataArray = total => {
  return [
    {
      poliza: "Total",
      cuota: "",
      primaCuota: total.primaCuota,
      intCuota: total.intCuota,
      amortizacion: total.amortizacion,
      primaPagada: total.primaPagada,
      intPagado: total.intPagado,
      intMoraPesos: total.intMoraPesos,
      pagadoPesos: total.pagadoPesos,
      total: true
    }
  ];
};
