import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import "./EmptyState.scss";

export const EmptyState = props => {
  return (
    <div className={"empty-state-container"}>
      <div className={"floating-message"}>
        {props.hideEmoticon === true ? (
          ""
        ) : (
          <p className={"emoticon"}>{props.emoticon ? props.emoticon : ":)"}</p>
        )}
        <p className={"message"}>
          {props.message ? props.message : "Loading..."}
        </p>
        {props.showButton ? (
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => {
              window.history.back();
            }}
          >
            {"Volver"}
          </Button>
        ) : null}
      </div>

      {/* <p className={"emoticon"}>{props.emoticon ? props.emoticon : ":)"}</p> */}
    </div>
  );
};

EmptyState.propTypes = {
  hideEmoticon: PropTypes.any,
  emoticon: PropTypes.any,
  message: PropTypes.any
};

export default EmptyState;
