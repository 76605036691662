import React from 'react'
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import  './SituactionCobranzaPrimas.scss'
import Card  from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    grid:{
        margin:'5'
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
});

function SituactionCobranzaPrimas(props) {
  const situactionCobranzaYPrimas = props.data;
  const { classes } = props;
  return (
    <div className="Main-container">
        { situactionCobranzaYPrimas ?
        <Card className="Card-area">
            <h3 className="Heading-name">Situación Cobranza y Primas</h3>
            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Column</TableCell>
                            <TableCell align="center">Afecto  Neto</TableCell>
                            <TableCell align="center">Exento Neto</TableCell>
                            <TableCell align="center">Bruto</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center">Propuestas</TableCell>
                            <TableCell align="center">{situactionCobranzaYPrimas.afectoNeto.propuestas}</TableCell>
                            <TableCell align="center">{situactionCobranzaYPrimas.exentoNeto.propuestas}</TableCell>
                            <TableCell align="center">{situactionCobranzaYPrimas.bruto.propuestas}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center">Endosos</TableCell>
                            <TableCell align="center">{situactionCobranzaYPrimas.afectoNeto.endosos}</TableCell>
                            <TableCell align="center">{situactionCobranzaYPrimas.exentoNeto.endosos}</TableCell>
                            <TableCell align="center">{situactionCobranzaYPrimas.bruto.endosos}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center">Total</TableCell>
                            <TableCell align="center">{situactionCobranzaYPrimas.afectoNeto.total}</TableCell>
                            <TableCell align="center">{situactionCobranzaYPrimas.exentoNeto.total}</TableCell>
                            <TableCell align="center">{situactionCobranzaYPrimas.bruto.total}</TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell align="center">Column</TableCell>
                        <TableCell align="center">Page Documentos</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center">Interes</TableCell>
                            <TableCell align="center">{situactionCobranzaYPrimas.pageDocumentos.interes}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center">Prima</TableCell>
                            <TableCell align="center">{situactionCobranzaYPrimas.pageDocumentos.prima}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        </Card>
        : null
        }
    </div>
  )
}

SituactionCobranzaPrimas.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SituactionCobranzaPrimas);
