import React from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { translate, Trans } from "react-i18next";
import Button from "@material-ui/core/Button";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import Card from '@material-ui/core/Card';
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import _ from 'lodash';
import { api } from '../../api/vehiculos'
import './Vehiculos.scss'
import Paper from '@material-ui/core/Paper';


const styles = theme => ({
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gridGap: `${theme.spacing.unit * 2}px`,
      flexWrap: 'nowrap'
    },
    paper: {
      padding: theme.spacing.unit,
      textAlign: 'center',
      color: theme.palette.text.primary,
      fontSize: `15px`,
      whiteSpace: 'nowrap',
      fontFamily:'Roboto',
      marginBottom: theme.spacing.unit
    },
    divider: {
      margin: `${theme.spacing.unit * 1}px 0`,
    },
    card: {
      padding: `20px`
    }
  });

class SiniestrosVehiculos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data : undefined,
      showData :  false,
      NMandatoNo : undefined,
      showNMandato : false ,
      showLoader :  false ,
      openDailog :false ,
      NMandatoData:  undefined,
      searchTextOne : "",
      enableSearch : true
    };
   
    this.handleChangeOne = this.handleChangeOne.bind(this);
    this.showMandatoClient =  this.showMandatoClient.bind(this);
    this.resetSearch = this.resetSearch.bind(this);



  }
  
  componentDidMount() {
    const { match: { params } } = this.props;
    const {siniestros} = params;
    if(siniestros){
      this.setState({ searchTextOne: siniestros , enableSearch: false});
      this.showMandatoClient(siniestros)
    }
    
  }

  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  handleChangeOne(event) {
    const value = event.target.value;    
    this.setState({ searchTextOne: value });
    let showSearch = this.showSearchButton(value);
    this.setState({enableSearch: showSearch})

  }

 
  

  showSearchButton(firsText) {
    let bln  = true;
    if(firsText  && firsText.length ) {
      bln =  false;
    }
    return bln;
  }

  


  resetSearch() {
    this.setState({
      enableSearch : true,
      searchTextOne : "",
      showData:false     
    });
  }


  handleClickOpen(){
    this.setState({openDailog :  true})
  }
  
  handleClose =()=> {
    this.setState({ openDailog: false });
  };

  clickRow = (data) =>{
   if(data && data.nMandato){
     const nMandato = data.nMandato
     this.getNMandatoData(nMandato)
   }
 }



 showLoaderAction() {
  let showLoader = this.state.showLoader;
  this.setState({ showLoader: !showLoader });
} 
 
 
 showMandatoClient(siniestros){
    let sinestro=parseInt(this.state.searchTextOne) || parseInt(siniestros);
    
    this.showLoaderAction()
    api.getPaymentOrder(sinestro).then((result)=>{
      this.showLoaderAction()
      if(result.data && Object.keys(result.data).length){
          this.setState({
            data : result.data,
            showData:  true
          })
          console.log(result.data.paymentOrder);
      }else{
        toast.error(result.msg, {position: toast.POSITION.TOP_RIGHT});
        this.setState({
          data : [],
          showData:  false
        })
      }
    })
    
 }
 
  render() {
    const { i18n } = this.props;
    const { classes } = this.props;
    let translateObj = (i18n.store.data[i18n.language] && i18n.store.data[i18n.language].translations) ||  i18n.store.data["es"].translations;

    let columnName = {
    "sinestro" :"Siniestro",
    "liquidador" : "Liquidador",
    "folio" : "Folio",
    "estado" : "Estado",
    "pagar" : "Pagar a",
    "moneda" : "Moneda",
    "mtoChequeMp" : "Monto Pago",
    "nroBolFac" : "Boleta / Factura / Nota Crédito",	
    "numeroOrden":"Número Orden",
    "serieCheque" : "Serie Cheque",	
    "tipoDeOrden" :"Tipo de Orden",
    "detalle" : "Detalle"
    }

    const data = {
      route: "/vehiculos/siniestros-vehiculos",
      keys: ["detalle"]
    };

    return (
      <main className="main-container">
        {this.state.showLoader ? <Loader /> : null}

        <div className="search-wrapper">
          <TextField
            id="custom-css-standard-input"
            label={" N° Siniestro   	"}
            type="search"
            className="text-field"
            margin="normal"
            value={this.state.searchTextOne}
            onChange={this.handleChangeOne}
          />
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className="search-policy"
            disabled={this.state.enableSearch}
            onClick={this.showMandatoClient.bind()}
          >
            {translateObj.policy_page.search_btn}

          </Button>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className="search-policy"
            onClick={this.resetSearch.bind()}
          >
            {translateObj.policy_page.reset_btn}
          </Button>
        </div>
        {this.state.showData ? 
        <div>             
            <DyanmicTableWithSorting 
                data={this.state.data.paymentOrder}
                keyArray={columnName}
                orderBy={columnName[0]}
                pathData={data}
                isPaginationReq={true}
                linkCreateArray={["folio","secuen","nroSolicitudPago"]}
                isEnhancedTableHeadReq={true}
            /> 
        </div>
        : null }
       
        <ToastContainer />
      </main>
    );
  }
}


SiniestrosVehiculos.propTypes = {
  classes: PropTypes.object.isRequired
};

export default translate("translations")( withStyles(styles)(SiniestrosVehiculos));

