import React from 'react'
import TextField from '@material-ui/core/TextField';
import  './AntecedentesCorredor.scss'
import Card from '@material-ui/core/Card';


export default function Antecedentes_corredor(props) {
  const antecedentesCorredor = props.data;

  return (
    <div className="Main-container">
    { antecedentesCorredor ?
      <Card className="Card-area box-show-none">
      <fieldset className="custom-border">
       <legend className="legend-box">Antecedentes Corredor</legend>
            {/* <h3 className="Heading-name">Antecedentes Corredor</h3> */}
            <div className="vertical-align-middle">
                <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Nombre"
                        className="textField"
                        value={antecedentesCorredor.nombre}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                        style = {{width: 500}}
                    />
                </span>
                <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Rut"
                        className="textField"
                        value={antecedentesCorredor.rut[0]}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                        style = {{width: 240}}
                    />
                     <span className="minus-sign">-</span>
                     <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label=""
                        className="textField"
                        value={antecedentesCorredor.rut[1]}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                        style = {{width: 100}}
                    />
                </span>
            </div>
            <div>
                <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Telefono"
                        className="textField"
                        value={antecedentesCorredor.telefono}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                        style = {{width: 300}}
                    />
                </span>
                <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Agencia"
                        className="textField"
                        value={antecedentesCorredor.agencia}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                        style = {{width: 300}}
                    />
                </span>
                <span className="Space-around">
                    <TextField InputLabelProps={{
                            shrink: true,
                        }}
                        id="standard-bare"
                        label={antecedentesCorredor.comission?"Comisión pond":"Comisión"}
                        className="textField"
                        value={antecedentesCorredor.comisionPond}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                        style = {{width: 250}}
                    />
                </span>
                {antecedentesCorredor.comission?<>
                <span className="Space-around">
                    <TextField InputLabelProps={{
                            shrink: true,
                        }}
                        id="standard-bare"
                        label="Comisión inc."
                        className="textField"
                        value={antecedentesCorredor.comisionInc}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                        style = {{width: 200}}
                    />
                </span>
                <span className="Space-around">
                    <TextField InputLabelProps={{
                            shrink: true,
                        }}
                        id="standard-bare"
                        label="Sismo"
                        className="textField"
                        value={antecedentesCorredor.sismo}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                        style = {{width: 200}}
                    />
                </span>
                </>:null}
            </div>
            </fieldset>
        </Card>
        : null
        }
    </div>
  )
}