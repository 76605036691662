import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SiniestrosVehiculos from '../Phoenix/components/Vehiculos/SiniestrosVehiculos';
import DetalleDelPago from '../Phoenix/components/Vehiculos/DetalleDelPago';

export const Vehiculos = ({ match }) => (
    <Switch>        
        <Route exact path={`${match.url}/siniestros-vehiculos`} component={SiniestrosVehiculos}/>
        <Route exact path={`${match.url}/siniestros-vehiculos/:siniestros`} component={SiniestrosVehiculos}/>
        <Route exact path={`${match.url}/siniestros-vehiculos/:folio/:secuen/:nroSolicitudPago`} component={DetalleDelPago}/>
    </Switch>
   
  ) 