export const development =  {
    apiEndPoint:"https://n0j295euik.execute-api.us-east-1.amazonaws.com/dev/",
    env:"development",
    adalConfig: {
        tenant: 'LibertyMutual.onmicrosoft.com',
        tenantId: '08a83339-90e7-49bf-9075-957ccd561bf1',
        clientId: 'b42be481-ea9c-4753-9907-d6c4785e7a21',
        endpoints: {
            api: 'https://libertymutual.onmicrosoft.com/42c71d7c-aa24-47e2-8fb4-8e3148d071db',
        },
        cacheLocation: 'localStorage',
    }
};

// export const development =  {
//     apiEndPoint: "https://nh8z31aqf9.execute-api.us-east-1.amazonaws.com/nonprod/",
//     env:"nonprod",
//     adalConfig: {
//         tenant: 'LibertyMutual.onmicrosoft.com',
//         tenantId: '08a83339-90e7-49bf-9075-957ccd561bf1',
//         clientId: '5ff877e2-7ae6-4716-8962-a2e06d6dacc3',// application id fro non-prod env
//         endpoints: {
//             api: 'https://libertymutual.onmicrosoft.com/157a6bd1-a58b-4541-bb9e-eac5ccd36984',
//         },
//         cacheLocation: 'localStorage',
//     }
// };