import React from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { translate, Trans } from "react-i18next";
import Button from "@material-ui/core/Button";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import './pacpat.scss'
import Card from '@material-ui/core/Card';
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import _ from 'lodash';
import { getObtBitaCoraMandato , getBitaCoraMandato } from '../../api/pacpat'

const styles = theme => ({
});

class ConsultarMandatosCliente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data : undefined,
      showData :  false,
      NMandatoNo : undefined,
      showNMandato : false ,
      showLoader :  false ,
      openDailog :false ,
      NMandatoData:  undefined,
      searchTextOne : "",
      searchTextTwo :  "",
      enableSearch : true
    };
   
    this.handleChangeOne = this.handleChangeOne.bind(this);
    this.handleChangeTwo = this.handleChangeTwo.bind(this);
    this.showMandatoClient =  this.showMandatoClient.bind(this);
    this.resetSearch = this.resetSearch.bind(this);



  }
  
  componentDidMount() {
    
  }

  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  handleChangeOne(event) {
    const value = event.target.value;
    this.setState({ searchTextOne: value });
    let showSearch = this.showSearchButton(value, this.state.searchTextTwo);
    this.setState({enableSearch: showSearch})

  }

  handleChangeTwo(event) {
    const value = event.target.value;
    this.setState({ searchTextTwo: value });
    let showSearch = this.showSearchButton(this.state.searchTextOne, value);
    this.setState({enableSearch: showSearch})
  }

  showSearchButton(firsText, secondText) {
    let bln  = true;
    if(firsText && secondText && firsText.length && secondText.length ) {
      bln =  false;
    }
    return bln;
  }

  


  resetSearch() {
    this.setState({
      enableSearch : true,
      searchTextOne : "",
      searchTextTwo : "",
      showData:  false
    });
  }


  handleClickOpen(){
    this.setState({openDailog :  true})
  }
  
  handleClose = () => {
    this.setState({ openDailog: false });
  };

  clickRow = (data) =>{
   if(data && data.nMandato){
     const nMandato = data.nMandato
     this.getNMandatoData(nMandato)
   }
 }
 showLoaderAction() {
  let showLoader = this.state.showLoader;
  this.setState({ showLoader: !showLoader });
}
getNMandatoData(no){
  this.showLoaderAction()
  getBitaCoraMandato(no).then((result)=>{
    this.showLoaderAction();
    if(result.data && result.data.bitacoraMandato && result.data.bitacoraMandato.length){
        this.setState({
          NMandatoData : result.data.bitacoraMandato,
          NMandatoNo : no,
          showNMandato : true,
          openDailog:  true
        })
     }else{
      toast.error(result.msg, {position: toast.POSITION.TOP_RIGHT});
      this.setState({
          NMandatoData : [],
          NMandatoNo : no,
          showNMandato : false,
          openDailog:  false
      })
     }
   })

 } 



//  showPaymentOrder = () =>{
//    let siniestro = 

//  }

 
 
 
 showMandatoClient(){
    let pRutTitular=parseInt(this.state.searchTextOne);
    let pRutTitularDiv= parseInt(this.state.searchTextTwo);
    this.showLoaderAction()
    getObtBitaCoraMandato(pRutTitular,pRutTitularDiv).then((result)=>{
      this.showLoaderAction()
      if(result.data && Object.keys(result.data).length){
          this.setState({
            data : result.data,
            showData:  true
          })
      }else{
        toast.error(result.msg, {position: toast.POSITION.TOP_RIGHT});
        this.setState({
          data : [],
          showData:  false
        })
      }
    })
    
 }
 
  render() {
    const { i18n } = this.props;
    const { classes } = this.props;
    let translateObj = (i18n.store.data[i18n.language] && i18n.store.data[i18n.language].translations) ||  i18n.store.data["es"].translations;
    let columnNames = {
      "nMandato" : "Nº Mandato" ,	
      "tipo" : "Tipo" ,	
      "entidad" : "Entidad"	, 
      "ctacte": "Nº Cta.Cte."	, 
      "sucursalOrigen":"Sucursal Origen" ,	
      "fechaRecepcion" : "Fecha Timbre Recepción" ,	
      "estado": "Estado" ,
      "canal":	"Canal" }  


    let ColumnNMandato = {
      "fecha" : "Fecha / Hora", 	
      "estado" : "Estado Mandato" ,	
      "evento" : "Evento" ,	
      "responsable" : "Responsable",
    }


    return (
      <main className="main-container">
        {this.state.showLoader ? <Loader /> : null}

        <div className="search-wrapper">
          <TextField
            id="custom-css-standard-input"
            label={"RUT Titular"}
            type="search"
            className="text-field"
            margin="normal"
            value={this.state.searchTextOne}
            onChange={this.handleChangeOne}
          />
          <span className="minus-sign">-</span>
          <TextField
            id="custom-css-standard-input"
            type="search"
            className="text-field margin-top-15"
            margin="normal"
            value={this.state.searchTextTwo}
            onChange={this.handleChangeTwo}
          />
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className="search-policy"
            disabled={this.state.enableSearch}
            onClick={this.showMandatoClient.bind()}
          >
            {translateObj.policy_page.search_btn}
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className="search-policy"
            onClick={this.resetSearch.bind()}
          >
            {translateObj.policy_page.reset_btn}
          </Button>
        </div>
        { this.state.showData ? 
        <div className="margin-top-30">
             <Card className="Card-area box-show-none">
             <fieldset className="custom-border">
               <legend className="legend-box">Consultar Mandatos Cliente</legend>
                <div className="vertical-align-middle">
                    <span className="Space-around">
                      <TextField InputLabelProps={{shrink: true }}
                          id="standard-bare"
                          label="Nombre Titular"
                          className="textField"
                          value={this.state.data.nombreTitular}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                          readOnly: true,
                          }}
                          style = {{width: 600}}
                      />
                    </span>
                    <span className="Space-around">
                      <TextField InputLabelProps={{  shrink: true }}
                          id="standard-bare"
                          label="RUT Titular"
                          className="textField"
                          value={this.state.data.rutTitular}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                          readOnly: true,
                          }}
                          style = {{width: 300}}
                      />
                    </span>

                    <DyanmicTableWithSorting
                        data={this.state.data.obtBitacoraMandato}
                        keyArray={columnNames}
                        orderBy={columnNames[0]}
                        pathData={{}}
                        passClick = {this.clickRow}
                        virticalScroll={true}
                        tableMaxHeight={"400"}
                        isEnhancedTableHeadReq={false}
                        virticalScroll={true}
                        tableMaxHeight={"400"}
                      />
                  </div>
                </fieldset>
                </Card>
                </div>   
              :  null }
                <div>
                {
                  this.state.showNMandato  && this.state.NMandatoData && this.state.NMandatoData.length ? 
                  <Dialog  open={this.state.openDailog}  onClose={this.handleClose} aria-labelledby="responsive-dialog-title">
                  <DialogTitle id="responsive-dialog-title" className="dailog-title-style"> Bitácora Mandato Nro. {this.state.NMandatoNo}</DialogTitle>
                    <DialogContent className="width-700px">
                        <DyanmicTableWithSorting 
                          data={this.state.NMandatoData}
                          keyArray={ColumnNMandato}
                          orderBy={ColumnNMandato[0]}
                          pathData={{}}
                          virticalScroll={true}
                          tableMaxHeight={"400"}
                          isEnhancedTableHeadReq={false}
                          virticalScroll={true}
                          tableMaxHeight={"400"}                        
                         />
                        
                    </DialogContent>
                  </Dialog> : null
                }
                </div>     
        <ToastContainer />
      </main>
    );
  }
}


ConsultarMandatosCliente.propTypes = {
  classes: PropTypes.object.isRequired
};

export default translate("translations")( withStyles(styles)(ConsultarMandatosCliente));


