import React from "react";
import DyanmicTableWithSorting from "../../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import EndorsementDetailKeys from "./EndorsementDetailKeys.json";
import { translate, Trans } from "react-i18next";
import { getEndorsementDetails } from "./../../../api/policy";
import Loader from "../../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import "./EndorsementDetails.scss";
import _ from "lodash";
import Button from "@material-ui/core/Button";

class EndorsementDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      data: undefined,
      EndorsementDetailKeysArray: EndorsementDetailKeys,
      blnShowItem: false,
      showLoader: false,
      totalItems: "",
      endos: "",
      isEnhancedTableHeadReq: false,
      showVolver: false
    };
    this.showItemList = this.showItemList.bind(this);
  }

  componentWillMount() {
    const {
      match: { params }
    } = this.props;
    let endos = params.itemNo;
    let policyNo = params.policyNo;
    this.showItemList(policyNo, endos);
    this.setState({
      policyNo: policyNo,
      endos: endos,
      enableSearch: policyNo ? false : true,
      showVolver: policyNo ? true : false
    });
  }

  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  showItemList(policyNo, endos, start, end) {
    this.showLoaderAction();
    if (
      this.state.searchText &&
      this.state.searchText.length > 0 &&
      this.state.searchItemText &&
      this.state.searchItemText.length > 0
    ) {
      this.props.history.push(
        "/items-list/" + this.state.searchText + "/" + this.state.searchItemText
      );
    }

    var reqObj = {};
    reqObj.policy_id = policyNo;
    reqObj.endosoNo = endos;

    getEndorsementDetails(reqObj)
      .then(response => {
        if (response && response.data && !_.isEmpty(response.data)) {
          var respObj = response.data.endos;
          let totalObj = {};
          totalObj.item = "Total";
          totalObj.tipo = "";
          totalObj.fetchaIngreso = "";
          totalObj.vegenciaDesde = "";
          totalObj.vigenciaHasta = "";
          totalObj.nombreAsegurado = "";
          totalObj.primaAfecta = response.data.primaAfectaTotal;
          totalObj.primaExenta = response.data.primaExentaTotal;
          totalObj.primaBruta = response.data.primaBrutaTotal;
          totalObj.total = true;
          respObj.push(totalObj);
          this.setState({
            blnShowItem: true,
            data: respObj
          });
          this.showLoaderAction();
        } else {
          toast.error(
            response.msg || "No Endorsement found for given policy number",
            {
              position: toast.POSITION.TOP_RIGHT
            }
          );
          this.setState({
            data: undefined,
            blnShowItem: false
          });
          this.showLoaderAction();
        }
      })
      .catch(err => {
        this.showLoaderAction();
        console.error(err);
      });
    this.setState({ blnShowItem: true });
  }

  backToPreviousPage = () => {
    window.history.back();
  };

  render() {
    const blnShowItem = this.state.blnShowItem;
    let showLoader = this.state.showLoader;

    return (
      <main className="main-container">
        {showLoader ? <Loader /> : null}
        <div className="main-screen">
          {blnShowItem && this.state.data ? (
            <div>
              <div className="search-wrapper">
                <h4>
                  {[
                    "Detalles del Endoso"
                  ] /* [ Endosos Poliza: {this.state.policyNo} - Endos:{" "}
                  {this.state.endos} ] */}
                </h4>
              </div>
              <DyanmicTableWithSorting
                data={this.state.data}
                keyArray={this.state.EndorsementDetailKeysArray}
                orderBy={this.state.EndorsementDetailKeysArray[0]}
                pathData={{}}
                virticalScroll={true}
                tableMaxHeight={"400"}
                isEnhancedTableHeadReq={this.state.isEnhancedTableHeadReq}
                virticalScroll={true}
                tableMaxHeight={"400"}
              />
              {this.state.showVolver ? (
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className="search-policy"
                  onClick={this.backToPreviousPage}
                  style={{ float: "right", marginTop: 10 }}
                >
                  {"Volver"}
                </Button>
              ) : null}
            </div>
          ) : null}
        </div>
        <ToastContainer />
      </main>
    );
  }
}

export default translate("translations")(EndorsementDetails);
