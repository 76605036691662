export const roleList = {
    "Super_Admin":["consulta_general","consulta_global","caja_penta", "consulta_pat_pac","caja_pagadora","siniestros_ramos_varios","sinestroVehiculo" ,"sinestroVehiculo", "analista_recuperos", "consulta_de_reaseguro"],
    "ADMIN":["consulta_general","consulta_global","caja_penta", "consulta_pat_pac","caja_pagadora","siniestros_ramos_varios","sinestroVehiculo" ,"sinestroVehiculo", "analista_recuperos", "consulta_de_reaseguro"],
    "Cobranzas":["consulta_general", "consulta_pat_pac", "caja_penta"],
    "Tesoreria_Full_Access":["consulta_general", "consulta_pat_pac", "caja_penta"],
    "Tesoreria":["consulta_pat_pac","caja_pagadora", "caja_penta"],
    "Suscriptor_RV":["consulta_general", "consulta_de_reaseguro", "siniestros_ramos_varios", "consulta_global"],
    "Consulta_Siniestros":["caja_pagadora","caja_penta","siniestros_ramos_varios", "sinestroVehiculo"],
    "Ejecutivo":["consulta_general","consulta_global"],
    "Analista_Recuperos":["analista_recuperos", "sinestroVehiculo"]
};