import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import ListItemText from "@material-ui/core/ListItemText";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { MENU } from "../../Constants/menu";
import { translate } from "react-i18next";
import "./SideBar.scss";
import { asyncComponent } from "react-async-component";
import Tooltip from "@material-ui/core/Tooltip";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import {roleList} from "../../Constants/roleConfig";

const drawerWidth = 230;

const styles = theme => ({
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing.unit * 0,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 0
    }
  },
  bootstrapTooltip: {
    backgroundColor: "#3f51b5"
  },
  overrides: {
    ListItem: {
      root: {
        "padding-top": "5px",
        "padding-bottom": "5px"
      }
    }
  }
});

/**
 * Function to generate dynamic icon
 * @param {icon} name.
 */
const MaterialIcon = icon => {
  let iconName = icon.replace(/Icon$/, "");
  try {
    let resolved = require(`@material-ui/icons/${iconName}`).default;

    return React.createElement(resolved, {
      className: "font-white"
    });
  } catch (ex) {
    return null;
    // throw Error(`Could not find material-ui-icons/${iconName}`);
    // }
  }
};

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOfApplicationAccessible:[],
      open: this.props.sideBarOpen || true,
      activeNav: "",
      currentRole:localStorage.getItem("userRole")?JSON.parse(localStorage.getItem("userRole"))[0]:undefined
    };
    this.findActiveClass = this.findActiveClass.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
  }

  componentWillMount() {
    let currentUrl = window.location.pathname;
    let listOfApplicationAccessible=(this.state.currentRole && roleList[this.state.currentRole] )?roleList[this.state.currentRole]:[];
    for (let keys in MENU) {
      let childData = MENU[keys].children;
      for (let childKey in childData) {
        if (childData[childKey].link.indexOf(currentUrl)>-1 || currentUrl.indexOf(childData[childKey].link)>-1) {
          this.setState({ activeNav: MENU[keys].key });
        }
      }
    }
    if (currentUrl === "/") {
      this.setState({ activeNav: "consulta_general" });
    }
    this.setState({
      listOfApplicationAccessible:listOfApplicationAccessible
    })
  }
  // TODO: temp logic need to change
  findActiveClass(text, currentUrl) {
    // let activeClassName = _.endsWith(currentUrl, text.link) ? "text-decoration-none active" : "text-decoration-none";
    // return activeClassName;
    let textLink = "";
    let url = "";
    if (text.link === "/items-list" && currentUrl === "/items") {
      return "text-decoration-none active";
    }
    if (text.link === "/" || currentUrl === "/") {
      url = currentUrl + "policy";
      textLink = text.link.includes("policy")
        ? text.link
        : text.link + "policy";
    } else {
      url = currentUrl;
      textLink = text.link;
    }
    let activeClassName =
      url.indexOf(textLink) > -1
        ? "text-decoration-none active"
        : "text-decoration-none";
    return activeClassName;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      open: nextProps.sideBarOpen
    });
  }

  handleDrawerClose = () => {
    this.props.handleDrawerClose();
  };
  openSubNavigation = keys => {
    let currentNav;
    if(keys && this.state.activeNav && this.state.activeNav.indexOf(keys)==-1){
      currentNav=keys+""+this.state.activeNav;
    }else{
      currentNav = this.state.activeNav===keys? "" :(this.state.activeNav.indexOf(keys)>-1? this.state.activeNav.replace(keys,""):keys);
    }
    this.setState({ activeNav: currentNav });
  };
  render() {
    const { classes } = this.props;
    const { i18n } = this.props;
    let translateObj =
      (i18n.store.data[i18n.language] &&
        i18n.store.data[i18n.language].translations) ||
      i18n.store.data["es"].translations;
    let currentUrl = window.location.pathname;
    const subMenu = { color: "grey" };
    const menuStyle = { color: "grey", fontSize: "15px" };
    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(
            classes.drawerPaper,
            !this.state.open && classes.drawerPaperClose
          )
        }}
        className="overflow-y-auto"
        open={this.state.open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={this.handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <Divider />
        <List className="no-padding">
          {MENU.map((text, index) => (
            <>
              {this.state.open && this.state.listOfApplicationAccessible.indexOf(text.key) >-1 ? (
                <li
                  key={translateObj.side_navigation[text.key]||text.name}
                  className="main-menu-style"
                >
                  <ListItem
                    onClick={this.openSubNavigation.bind(this, text.key)}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography type={translateObj.side_navigation[text.key] ||text.name} className="font-weight-600" style={menuStyle}>
                          {translateObj.side_navigation[text.key] ||text.name}
                        </Typography>
                      }
                      className="cursor-pointer uppercase letter-spacing"
                    />
                    {this.state.open && this.state.activeNav.indexOf(text.key)>-1 ? (
                      <ExpandLess
                        className="cursor-pointer"
                        style={menuStyle}
                      />
                    ) : (
                      <ExpandMore
                        className="cursor-pointer"
                        style={menuStyle}
                      />
                    )}
                  </ListItem>
                  {this.state.activeNav.indexOf(text.key)>-1 ? (
                    <ul className="no-padding">
                      {text.children.map((childData, key) => (
                        <Link
                          className={this.findActiveClass(
                            childData,
                            currentUrl
                          )}
                          to={childData.link}
                          key={key}
                        >
                          {" "}
                          <ListItem
                            button
                            key={childData.name}
                            className="paddingtop-10"
                            //className="button-style active-link navigation-style side-navigation sub-menu-style"
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  type={translateObj.side_navigation[childData.key] ||childData.name}
                                  style={subMenu}
                                  className="font-16"
                                >
                                  {translateObj.side_navigation[childData.key] ||childData.name }
                                </Typography>
                              }
                              // primary={childData.name}
                              className="sub-menu-style"
                            />
                          </ListItem>
                        </Link>
                      ))}
                    </ul>
                  ) : null}
                </li>
              ) : null
              }
            </>
          ))}
        </List>
      </Drawer>
    );
  }
}

SideBar.propTypes = {
  classes: PropTypes.object.isRequired
};
let data = withStyles(styles)(SideBar);
export default translate("translations")(data);
