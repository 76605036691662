import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import PolicyDetails from "../PolicyDetailsComponent/PolicyDetails";
import policydata from "../PolicyDetailsComponent/policydata.json";
import { translate, Trans } from "react-i18next";
import { getPolicyDetails } from "./../../api/policy";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./EmptyPage.scss";
import { css } from "glamor";
import CircularProgressComponent from "../../../BaseComponents/CircularProgressComponent/CircularProgressComponent";
import Loader from "../../../BaseComponents/Loader/Loader";
import { Link } from "react-router-dom";
import CreatableSelect from "react-select/lib/Creatable";

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto"
  },
  progress: {
    margin: theme.spacing.unit * 2
  }
});

class EmptyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPolicy: false,
      searchText: "",
      policyData: undefined,
      enableSearch: true,
      showLoader: false
    };
  }

  render() {
    const { i18n } = this.props;
    const { classes } = this.props;
    let translateObj =
      (i18n.store.data[i18n.language] &&
        i18n.store.data[i18n.language].translations) ||
      i18n.store.data["es"].translations;
    let showLoader = this.state.showLoader;
    return <main className={classes.content + " home-page"} />;
  }
}

EmptyPage.propTypes = {
  classes: PropTypes.object.isRequired
};

let data = withStyles(styles)(EmptyPage);
export default translate("translations")(data);
