import React from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { translate, Trans } from "react-i18next";
import Button from "@material-ui/core/Button";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import Card from '@material-ui/core/Card';
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import _ from 'lodash';
import './Vehiculos.scss'
import Paper from '@material-ui/core/Paper';
import { api } from '../../api/vehiculos'



const styles = theme => ({});

class DetalleDelPago extends React.Component {
  constructor(props) {
    super(props);
    this.state = {     
      showLoader :  false,
    }; 
   
  }
  
  componentDidMount() {
    const { match: { params } } = this.props;
    const {folio, secuen , nroSolicitudPago} = params;
    if(!_.isEmpty(folio) && !_.isEmpty(secuen) && !_.isEmpty(nroSolicitudPago)){
        let data = {
            "folio": parseInt(folio),
            "secuen": parseInt(secuen),
            "nroSolictudPago": parseInt(nroSolicitudPago),
        }
        this.getSinestros(data);        
    }

    this.setState({params:params})
  }

  getSinestros(data){
    this.showLoaderAction();
    try{
      api.getSinestros(data)
      .then((res)=>{
        if(res && !_.isEmpty(res.data)){
            const {sinestro} =   res.data;
            this.setState({sinestro: sinestro})
        }else{
            toast.error(res.msg, {position: toast.POSITION.TOP_RIGHT});
        }
        this.showLoaderAction();
      })
    }catch(ex){
        toast.error("Something went wrong.", {position: toast.POSITION.TOP_RIGHT});
    }
  }

  openLink=(chequeNo)=>{
    const {folio, secuen , nroSolicitudPago} = this.state.params;
    console.log("this.state.params", this.state.params)
    chequeNo && this.props.history.push(`/caja-pagadora/consulta-de-cheques/check-details/${chequeNo}/${folio}/${secuen}/${nroSolicitudPago}`)
  }
  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  goBackToMainPage =()=>{
    let {sinestro} =  this.state.sinestro;
    this.props.history.push(`/vehiculos/siniestros-vehiculos/${sinestro}`)
  }
 
  render() {
    const { i18n } = this.props;
    const { classes } = this.props;
    let translateObj = (i18n.store.data[i18n.language] && i18n.store.data[i18n.language].translations) ||  i18n.store.data["es"].translations;
    
    const {sinestro}  = this.state

    let polizaItem,rutDv;

    if(!_.isEmpty(sinestro)){
        polizaItem = `${sinestro.poliza}/${sinestro.item}`;
        rutDv = `${sinestro.rut}-${sinestro.dv}`; 
    }   


    return (
      <main className="main-container">
        {this.state.showLoader ? <Loader /> : null}
        {this.state.sinestro ?
        
        <div>
            <div className="text-align-right">
                <Button
                variant="contained"
                size="medium"
                color="primary"
                className="search-policy"
                onClick={this.goBackToMainPage}
                >
                {"Volver"}
            </Button>
        </div>
        <div>
        <Card className="Card-area box-show-none">
            <fieldset className="custom-border">
            <legend className="legend-box">Datos Siniestro</legend>
                <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare"
                            label="Siniestro"
                            className="textField"
                            value={sinestro.sinestro}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 225}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{   shrink: true }}
                            id="standard-bare"
                            label="Póliza/Item"
                            className="textField"
                            value={polizaItem}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 225}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare"
                            label="Ramo"
                            className="textField"
                            value={sinestro.ramo}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 225}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{   shrink: true }}
                            id="standard-bare"
                            label="Estado Siniestro"
                            className="textField"
                            value={sinestro.estadoSin}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 240}}
                        />
                    </span>
                </div>
                <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare"
                            label="Patente"
                            className="textField"
                            value={sinestro.patente}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 225}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{   shrink: true }}
                            id="standard-bare"
                            label="Tipo Daño"
                            className="textField"
                            value={sinestro.tipoDano}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 225}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare"
                            label="Folio"
                            className="textField"
                            value={sinestro.folio}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 225}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{   shrink: true }}
                            id="standard-bare"
                            label="Secuencia"
                            className="textField"
                            value={sinestro.secuencia}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 240}}
                        />
                    </span>
                </div>
            </fieldset>
        </Card>
        </div>
        <div className="div-margin">
        <Card className="Card-area box-show-none">
            <fieldset className="custom-border">
            <legend className="legend-box">Datos Pago</legend>
                <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare"
                            label="Tipo Pago"
                            className="textField"
                            value={sinestro.tipoPago}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 225}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{   shrink: true }}
                            id="standard-bare"
                            label="Rut"
                            className="textField"
                            value={rutDv}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 225}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare"
                            label="Pagar a"
                            className="textField"
                            value={sinestro.pagarA}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 420}}
                        />
                    </span>
                </div>
                <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{   shrink: true }}
                            id="standard-bare"
                            label="Estado Pago"
                            className="textField"
                            value={sinestro.estadoPago}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 300}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{   shrink: true }}
                            id="standard-bare"
                            label="Moneda Pago"
                            className="textField"
                            value={sinestro.monedaPago}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 300}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare"
                            label="Monto Neto"
                            className="textField"
                            value={sinestro.mtoNeto}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 270}}
                        />
                    </span>
                </div>
                <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{   shrink: true }}
                            id="standard-bare"
                            label="Monto Impuesto"
                            className="textField"
                            value={sinestro.mtoImpto}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare"
                            label="Monto Bruto"
                            className="textField"
                            value={sinestro.mtoBruto}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{   shrink: true }}
                            id="standard-bare"
                            label="Número Orden"
                            className="textField"
                            value={sinestro.nroOrden}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 225}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare"
                            label="Tipo Orden"
                            className="textField"
                            value={sinestro.tipoOrden}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 225}}
                        />
                    </span>
                </div>
            </fieldset>
        </Card>
        </div>
        <div className="div-margin">
        <Card className="Card-area box-show-none">
            <fieldset className="custom-border">
            <legend className="legend-box">Emisión Pago</legend>
                <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare"
                            label="Oficina Destino"
                            className="textField"
                            value={sinestro.ofiDestino}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 225}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{   shrink: true }}
                            id="standard-bare"
                            label="Fecha Pago"
                            className="textField"
                            value={sinestro.fechaPago}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 225}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare"
                            label="Monto Pago"
                            className="textField"
                            value={sinestro.montoPago}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 225}}
                        />
                    </span>
                </div>
                <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare"
                            label="Boleta / Factura / Nota Crédito"
                            className="textField"
                            value={sinestro.nroBolFac}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 225}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{   shrink: true }}
                            id="standard-bare"
                            label="Fecha Boleta / Factura / Nota Crédito"
                            className="textField"
                            value={sinestro.fechaBolFac}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 225}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare "
                            label="Serie Cheque"
                            className="textField text-link-click"
                            value={sinestro.serieCheq}
                            margin="normal"
                            variant="outlined"
                            onClick={this.openLink.bind(this, sinestro.serieCheq)}
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 225}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{   shrink: true }}
                            id="standard-bare"
                            label="Monto Cheque"
                            className="textField"
                            value={sinestro.montoCheq}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 240}}
                        />
                    </span>
                </div>
            </fieldset>
        </Card>
        </div>
        <div className="div-margin">  
        <Card className="Card-area box-show-none">
            <fieldset className="custom-border">
            <legend className="legend-box">Datos Internos</legend>
                <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true}}
                            id="standard-bare"
                            label="Liquidador"
                            className="textField"
                            value={sinestro.liquidador}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 450}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{   shrink: true }}
                            id="standard-bare"
                            label="Analista"
                            className="textField"
                            value={sinestro.analista}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width: 450}}
                        />
                    </span>
                </div>
            </fieldset>
        </Card>
        </div>
        </div>
        : null 
        }
        <ToastContainer />
      </main>
    );
  }
}


DetalleDelPago.propTypes = {
  classes: PropTypes.object.isRequired
};

export default translate("translations")( withStyles(styles)(DetalleDelPago));

