import React from 'react';
import PropTypes from 'prop-types';
// import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Divider from "@material-ui/core/Divider";
import Typography from '@material-ui/core/Typography';
import { translate, Trans } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';

import TextField from '@material-ui/core/TextField';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import {
    MuiPickersUtilsProvider,
    DatePicker
} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import DyanmicTableWithSorting from "./../../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting"
import {ramosTabTableOneKeys, ramosTabTableTwoKeys} from "../../../../Constants/reaseguradoresKeys"
import EmptyState from "../../../../BaseComponents/EmptyState/EmptyState";

class RamosYCoberturas extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showRamosYCoberturas: true,
            seleccioneRamo: "",
            showTableTwo:false

        };
    }

    selectHandleChangeSeleccioneRamo = actionMeta => {
        if (actionMeta && actionMeta.value) {
            this.setState({
                seleccioneRamo: actionMeta.value,
            });
        }
        console.groupEnd();
    };
    showTableTwo=()=>{
        this.setState({
            showTableTwo:true
        })
    }
    goToPreviousPage = () => {
        this.props.goToClasesDeContrato(5)
    };
    submitForm=()=>{
        // this.props.getTabEightData();
        this.props.goToClasesDeContrato(7)
    }
    showTableData=()=>{
        this.setState({
            showTableTwo:true
        })
    }
    render() {
        const showRamosYCoberturas =true;// this.props.tableOne?true:false;
        let tableOne=this.props.tableOne;
        let tableTwo=this.props.tableTwo;

        return (
            <div className="tab-class">
                        <Card className="Card-area box-show-none margin-top-30px" >
                            {showRamosYCoberturas ? (
                                    <div className="container">
                                        <DyanmicTableWithSorting
                                            data={tableOne}
                                            keyArray={ramosTabTableOneKeys}
                                            orderBy={ramosTabTableOneKeys[0]}
                                            virticalScroll={true}
                                            tableMaxHeight={"450"}
                                            hideColoumn={["clase","tipo"]}
                                            passClick ={this.showTableData}
                                        />
                                        {this.state.showTableTwo?
                                            <DyanmicTableWithSorting
                                                data={tableTwo}
                                                keyArray={ramosTabTableTwoKeys}
                                                orderBy={ramosTabTableTwoKeys[0]}
                                                virticalScroll={true}
                                                tableMaxHeight={"450"}
                                                hideColoumn={["clase","tipo"]}
                                                defaultRowPerPage={20}
                                                isPaginationReq={true}
                                            />
                                        :null}

                                    </div>
                            ) : (
                                <div className={"content"}>
                                    <EmptyState
                                        emoticon={"🔍"}
                                        message={"No se encontraron datos"}
                                    />
                                </div>
                            )}        
                            <div className="reso-btn-group margin-top-30px" >
                                    <div className="btn">
                                        <Button
                                            variant="contained"
                                            size="medium"
                                            color="primary"
                                            onClick={this.goToPreviousPage}
                                            className="search-policy pull-right ">Volver</Button>
                                    </div>
                                    <div className="btn" >
                                        <Button variant="contained" size="medium" color="primary" className="search-policy pull-right" onClick={this.submitForm}>
                                            Confirmar cambios y continuar
                                        </Button>
                                    </div>
                                </div>
                        </Card>                  

               
            </div>
        )
    }
}

export default translate("translations")(RamosYCoberturas);