import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import SinistroComponent from "./SinistroComponent/SinistroComponent";
import SinistralidadComponent from "./SinistralidadComponent/SinistralidadComponent";
import Button from "@material-ui/core/Button";
import "./ClaimByPolicyNoComponent.scss";
import { translate, Trans } from "react-i18next";
import { getClaimDetails, getClaimDetailsByPolicyId } from "./../../api/policy";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../BaseComponents/Loader/Loader";
import _ from "lodash";
class ClaimByPolicyNoComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sinistro: true,
      sinistralidad: false,
      showClaim: false,
      searchText: "",
      enableSearch: true,
      claimData: undefined,
      showLoader: false,
      showSinistroButton: true,
      showSinistralidadButton: false,
      searchItemText: "",
      showVolver: false
    };
    this.showSinistro = this.showSinistro.bind(this);
    this.showSinistralidad = this.showSinistralidad.bind(this);
    this.showClaimDetails = this.showClaimDetails.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleItemChange = this.handleItemChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    const policyNo = params.policyNo;
    let itemNo = params.itemNo;

    if (policyNo || itemNo) {
      this.setState({
        searchText: policyNo,
        searchItemText: itemNo,
        showVolver: true
      });
      this.showClaimDetails(policyNo, itemNo);
      this.setState({ enableSearch: false });
    }
  }
  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }
  showSinistro() {
    this.setState({
      sinistro: true,
      sinistralidad: false,
      showSinistroButton: true,
      showSinistralidadButton: false
    });
  }

  showSinistralidad() {
    this.setState({
      sinistro: false,
      sinistralidad: true,
      showSinistroButton: false,
      showSinistralidadButton: true
    });
  }
  handleChange(event) {
    const value = event.target.value;
    let bln = value.length > 0 ? false : true;
    this.setState({ searchText: value, enableSearch: bln });
  }
  handleItemChange(event) {
    const value = event.target.value;
    this.setState({ searchItemText: value });
  }

  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.showClaimDetails();
    }
  }

  resetSearch() {
    this.setState({
      showClaim: false,
      searchText: ""
    });
  }
  /* function to display policy details information */
  showClaimDetails(policyNumber, itemNo) {
    this.setState({ claimData: undefined });
    let policyNo = this.state.searchText || policyNumber;
    let searchItemText = this.state.searchItemText || itemNo;
    if (policyNo && searchItemText) {
      this.props.history.push("/claims/" + policyNo + "/" + searchItemText);
    } else {
      this.props.history.push("/claims/" + policyNo);
    }
    this.showLoaderAction();
    if (policyNo && searchItemText) {
      getClaimDetails(policyNo, searchItemText)
        .then(response => {
          if (response && response.data && !_.isEmpty(response.data)) {
            this.setState({
              showClaim: true,
              claimData: response.data
            });
            this.showLoaderAction();
          } else {
            toast.error(
              response.msg ||
                "No Claim found for given Policy no : " + policyNo,
              {
                position: toast.POSITION.TOP_RIGHT
              }
            );
            this.setState({
              claimData: undefined
            });
            this.showLoaderAction();
          }
        })
        .catch(err => {
          console.error(err);
        });
    } else {
      getClaimDetailsByPolicyId(policyNo)
        .then(response => {
          if (response && response.data && !_.isEmpty(response.data)) {
            this.setState({
              showClaim: true,
              claimData: response.data
            });
            this.showLoaderAction();
          } else {
            toast.error(
              response.msg ||
                "No Claim found for given Policy no : " + policyNo,
              {
                position: toast.POSITION.TOP_RIGHT
              }
            );
            this.setState({
              claimData: undefined
            });
            this.showLoaderAction();
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  }

  backToPreviousPage = () => {
    window.history.back();
    window.history.back();
  };

  render() {
    const { sinistro, sinistralidad } = this.state;
    const data = this.state.claimData;
    const { i18n } = this.props;
    let translateObj =
      (i18n.store.data[i18n.language] &&
        i18n.store.data[i18n.language].translations) ||
      i18n.store.data["es"].translations;
    let showLoader = this.state.showLoader;

    return (
      <main className="main-container">
        {showLoader ? <Loader /> : null
        //showLoader ?  <CircularProgressComponent /> : null
        }
        <div className="main-screen">
          <div className="search-wrapper">
            <TextField
              id="standard-search"
              label={translateObj.policy_page.search_placeholder}
              type="search"
              className="text-field"
              margin="normal"
              value={this.state.searchText}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
            />
            <TextField
              id="standard-item-search"
              label={translateObj.policy_page.search_item_placeholder}
              type="search"
              className="text-field"
              margin="normal"
              value={this.state.searchItemText}
              onChange={this.handleItemChange}
              onKeyPress={this.handleKeyPress}
            />
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className="search-policy"
              disabled={this.state.enableSearch}
              onClick={this.showClaimDetails.bind()}
            >
              {translateObj.policy_page.search_btn}
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className="search-policy"
              onClick={this.resetSearch.bind()}
            >
              {translateObj.policy_page.reset_btn}
            </Button>
            {this.state.showVolver ? (
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className="search-policy"
                onClick={this.backToPreviousPage}
              >
                {"Volver"}
              </Button>
            ) : null}
          </div>
          {this.state.showClaim ? (
            <div>
              <div>
                {data ? (
                  <div>
                    <Card className="card-space">
                      <div>
                        <div>
                          <span className="Space-around">
                            <TextField
                              InputLabelProps={{
                                shrink: true
                              }}
                              id="standard-bare"
                              label="Póliza"
                              className="textField"
                              value={data.poliza}
                              margin="normal"
                              variant="outlined"
                              InputProps={{
                                readOnly: true
                              }}
                              style={{ width: 150 }}
                            />
                          </span>
                          <span className="Space-around">
                            <TextField
                              InputLabelProps={{
                                shrink: true
                              }}
                              id="standard-bare"
                              label="Ramo"
                              className="textField"
                              value={data.ramo}
                              margin="normal"
                              variant="outlined"
                              InputProps={{
                                readOnly: true
                              }}
                              style={{ width: 280 }}
                            />
                          </span>
                          <span className="Space-around">
                            <TextField
                              InputLabelProps={{
                                shrink: true
                              }}
                              id="standard-bare"
                              label="Moneda Origen"
                              className="textField"
                              value={data.MOrigen}
                              margin="normal"
                              variant="outlined"
                              InputProps={{
                                readOnly: true
                              }}
                              style={{ width: 150 }}
                            />
                          </span>
                          <span className="Space-around">
                            <TextField
                              InputLabelProps={{
                                shrink: true
                              }}
                              id="standard-bare"
                              label="Vigencia Desde"
                              value={data.VDesde}
                              margin="normal"
                              variant="outlined"
                              InputProps={{
                                readOnly: true
                              }}
                              style={{ width: 150 }}
                            />
                          </span>
                          <span className="Space-around">
                            <TextField
                              InputLabelProps={{
                                shrink: true
                              }}
                              id="standard-bare"
                              label="Vigencia Hasta"
                              className="textField"
                              value={data.VHasta}
                              margin="normal"
                              variant="outlined"
                              InputProps={{
                                readOnly: true
                              }}
                              style={{ width: 150 }}
                            />
                          </span>
                        </div>
                        <div>
                          <span className="Space-around">
                            <TextField
                              InputLabelProps={{
                                shrink: true
                              }}
                              id="standard-bare"
                              label="Contratante"
                              className="textField"
                              value={data.Contratante}
                              margin="normal"
                              variant="outlined"
                              InputProps={{
                                readOnly: true
                              }}
                              style={{ width: 470 }}
                            />
                          </span>
                          <span className="Space-around">
                            <TextField
                              InputLabelProps={{
                                shrink: true
                              }}
                              className="text-field-space"
                              id="standard-bare"
                              label="Corredor"
                              className="textField"
                              value={data.Ccorredor}
                              margin="normal"
                              variant="outlined"
                              InputProps={{
                                readOnly: true
                              }}
                              style={{ width: 470 }}
                            />
                          </span>
                        </div>
                      </div>
                    </Card>
                    <div>
                      <Card>
                        <div>
                          <div className="margin-10">
                            <span className="button-space">
                              <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                className={
                                  this.state.showSinistroButton
                                    ? "search-policy"
                                    : "search-policy selected-button"
                                }
                                onClick={this.showSinistro.bind()}
                              >
                                Siniestro
                              </Button>
                            </span>
                            <span className="button-space">
                              <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                className={
                                  this.state.showSinistralidadButton
                                    ? "search-policy"
                                    : "search-policy selected-button"
                                }
                                onClick={this.showSinistralidad.bind()}
                              >
                                Siniestralidad
                              </Button>
                            </span>
                          </div>
                          <div>
                            {sinistro ? (
                              <SinistroComponent sinistro={data.sinistro} />
                            ) : null}
                          </div>
                          <div>
                            {sinistralidad ? (
                              <SinistralidadComponent
                                sinistralidad={data.sinistralidad}
                              />
                            ) : null}
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
        <ToastContainer />
      </main>
    );
  }
}

export default translate("translations")(ClaimByPolicyNoComponent);
