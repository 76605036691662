import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DetaileDeCheques from "../Phoenix/components/CajaPagadora/DetaileDeCheques";
import CheckDetails from "../Phoenix/components/CajaPagadora/CheckDetails";

export const CajaPagadora = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}/consulta-de-cheques`} component={DetaileDeCheques}/>
    <Route exact path={`${match.url}/consulta-de-cheques/check-details/:numero`} component={CheckDetails}/>
    <Route exact path={`${match.url}/consulta-de-cheques/check-details/:numero/:folio/:secuen/:nroSolicitudPago`} component={CheckDetails}/>

  </Switch>
);