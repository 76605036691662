import React from 'react';



class PDFGenerator extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {
    const {data , resData , dpi} = this.props.data;

    console.log('this.props.data.ramo' , this.props.data , this.props.data.ramo)

    return (

      <div>
        { this.props.data ? 
        <div 
          style={{
            width: '0px',
            height: '0px',
            overflow: 'hidden',
            position: 'fixed',
            top: '0px',
            left: '0px'
          }}>
          <div
            id="print-content"
            style={{
              width: '760px',
              height: '860px'
            }}>

            <div className="space">
              <p className="float-left">
                <span>
                  <b>Penta Security </b>
                </span>
              </p>
              <p className="float-right ">
                <span>
                  <b>Fetcha De consulta  : </b> {this.props.data.dt}
                </span>
              </p>
            </div>
            <div className="padding-space">
                <h5 className="center-text">CONSALTA GENERAL</h5>
                <h6 className="center-text">CONSULTA DE MINUTA</h6>
            </div>

            <div className="center-text"> 
               <p>
                <span className="space">
                  <b>Poliza :  </b>
                </span>
                <span className="space">
                   {this.props.data.policy}
                </span>
                <span className="space">
                  <b>Item  : </b> 
                </span>
                <span className="space">
                {this.props.data.item}
                </span>
                <span className="space">
                  <b>Ramo : </b>
                </span>
                <span className="space">
                  {this.props.data.resData.ramo}
                </span>
              </p>
            </div>
            <div className="center-text"> 
              <p> <b  className="under-line"> MINUTA DIGITADA </b></p>
            </div>
            <div className="space">
            {
                 <div>
                {    this.props.data.resData.textoMinuta.map((data , index) => (
                    <p key={index}>{data.textoMinuta}</p>
                ))}
                </div>
                 
             }
             
            </div>

            {this.props.children}
          </div>
        </div> :  null }
      </div >
    );
  };

  
}

export default PDFGenerator;
