export function scrollToBottom(){
    setTimeout(() => {
      if(document.querySelector(".main-container")){
        document.querySelector(
          ".main-container"
        ).scrollTop = document.querySelector(".main-container").scrollHeight;
      }else{
        document.querySelector(
          ".home-page"
        ).scrollTop = document.querySelector(".home-page").scrollHeight;
      }
    }, 500);
  };
