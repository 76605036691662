import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ConsultaReaseguro from '../Phoenix/components/Reaseguradores/ConsultaReaseguro';
import DetaileReaseguroItem from '../Phoenix/components/Reaseguradores/DetaileReaseguroItem';
import DetalleReaseguroItemPorCobertura from '../Phoenix/components/Reaseguradores/DetalleReaseguroItemPorCobertura';
import ConsultaPorDistribucion from "../Phoenix/components/Reaseguradores/ConsultaPorDistribucionComponent/ConsultaPorDistribucion";
import Contratos from "../Phoenix/components/Reaseguradores/ContratosComponent/Contratos";
import ReporteDistribucion from '../Phoenix/components/Reaseguradores/ConsultaPorDistribucionComponent/ReporteDistribucion';

export const Reaseguradores = ({ match }) => (
    <Switch>
        <Route exact path={`${match.url}/consulta-reaseguro`} component={ConsultaReaseguro}/>
        <Route exact path={`${match.url}/consulta-reaseguro/detaile-reaseguro-item`} component={DetaileReaseguroItem}/>
        <Route exact path={`${match.url}/consulta-reaseguro/:policyNo/:itemNo/:fechaConsulta`} component={ConsultaReaseguro}/>
        <Route exact path={`${match.url}/consulta-reaseguro/detaile-reaseguro-item/:policyNo/:itemNo/:fechaConsulta/:vigenciaDesde/:vigenciaHasta/`} component={DetaileReaseguroItem}/>
        <Route exact path={`${match.url}/consulta-reaseguro/detaile-reaseguro-item-for-Cobertura`} component={DetalleReaseguroItemPorCobertura}/>
        <Route exact path={`${match.url}/consulta-por-distribucion`} component={ConsultaPorDistribucion}/>
        <Route exact path={`${match.url}/consultapordistribucion/:policyID`} component={ConsultaPorDistribucion}/>
        <Route exact path={`${match.url}/contratos`} component={Contratos}/>  
        <Route exact path={`${match.url}/consulta-por-distribucion/:id`} component={ReporteDistribucion} />
    </Switch>
   
  ) 