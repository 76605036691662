import React from "react";
import { translate, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import _ from "lodash";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";

class VegeneComponet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}
  render() {
    const { i18n } = this.props;
    let translateObj =
      (i18n.store.data[i18n.language] &&
        i18n.store.data[i18n.language].translations) ||
      i18n.store.data["es"].translations;
    const data = this.props.data;
    return (
      <div>
        {data ? (
          <Card className="Card-area box-show-none">
            <fieldset className="custom-border">
              <legend className="legend-box">Documento Padre</legend>
              <div>
                <span className="Space-around">
                  <TextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    id="standard-bare"
                    label="Mandato"
                    className="textField"
                    value={data.mandato}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true
                    }}
                    style={{ width: 120 }}
                  />
                </span>
                <span className="Space-around">
                  <TextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    id="standard-bare"
                    label="Documento Padre"
                    className="textField"
                    value={data.documentoPadre}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true
                    }}
                    style={{ width: 130 }}
                  />
                </span>
                <span className="Space-around">
                  <TextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    id="standard-bare"
                    label="Teléfono"
                    className="textField"
                    value={data.telefono}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true
                    }}
                    style={{ width: 130 }}
                  />
                </span>
                <span className="Space-around">
                  <TextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    id="standard-bare"
                    label="Estado Mandato"
                    className="textField"
                    value={data.estadoMandato}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </span>
              </div>
              <div>
                <span className="Space-around">
                  <TextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    id="standard-bare"
                    label="Dirección"
                    className="textField"
                    value={data.direccion}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true
                    }}
                    style={{ width: 650 }}
                    multiline="true"
                    rows="1"
                    rowsMax="5"
                  />
                </span>
                </div>
                <div>
                <span className="Space-around">
                  <TextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    id="standard-bare"
                    label="Titular"
                    className="textField"
                    value={data.nombreTitular}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true
                    }}
                    style={{ width: 650 }}
                  />
                </span>
              </div>
            </fieldset>
            <br />
            <fieldset className="custom-border">
              <legend className="legend-box"> Cta Corriente </legend>
              <div>
                <span className="Space-around">
                  <TextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    id="standard-bare"
                    label="Banco"
                    className="textField"
                    value={data.banco}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </span>
                <span className="Space-around">
                  <TextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    id="standard-bare"
                    label="Cta Corriente"
                    className="textField"
                    value={data.ctaCorriente}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </span>
                <span className="Space-around">
                  <TextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    id="standard-bare"
                    label="Dia cargo"
                    className="textField"
                    value={data.diaCargo}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </span>
              </div>
            </fieldset>
          </Card>
        ) : null}
      </div>
    );
  }
}

export default translate("translations")(VegeneComponet);
