import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import './BrokerReference.scss'
import Button from "@material-ui/core/Button";
import { getTotalBrokerReference , getBrokerReference } from '../../api/brokerReference'
import _ from 'lodash'
import { translate, Trans } from "react-i18next";
import Loader from "../../../BaseComponents/Loader/Loader";
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import { ToastContainer, toast } from "react-toastify";
import { NoOptionsMessage, inputComponent, Control, Option , Placeholder ,SingleValue ,ValueContainer, MultiValue, Menu} from  '../../../Constants/dailog'

const styles = theme => ({
    root: {
      flexGrow: 1,
      height: 350,
    },
    input: {
      display: 'flex',
      padding: 0,
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    chip: {
      margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
        0.08,
      ),
    },
    noOptionsMessage: {
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
      fontSize: 16,
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      fontSize: 16,
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing.unit,
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing.unit * 2,
    },
  });


  
  const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
  }; 



class BrokerReference extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        single: undefined,
        searchText : undefined,
        showLoader : false,
        suggestions : [],
        suggestionList:[],
        disableSearch : true,
        BuscaPolIteRefExterna :  undefined,
        isPaginationReq: true,
        isEnhancedTableHeadReq: true,
        defaultRowPerPage: 20,
        expandSelectWidth:false
    };

    this.handleChangeText = this.handleChangeText.bind(this);
    this.showBrokerReference = this.showBrokerReference.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
    this.disableSearchBtn =  this.disableSearchBtn.bind(this);
  }

  componentDidMount(){
    this.getTotalBrokerReferenceData();
  }


  getTotalBrokerReferenceData(){
    this.showLoader()
    try {
        getTotalBrokerReference()
        .then((res)=>{
                if(res && res.data && !_.isEmpty(res.data)){
                    this.setState({suggestions :  res.data.rut, suggestionList:res.data.rut.slice(0,200)})
                }else {
                    toast.error(res.msg , { position: toast.POSITION.TOP_RIGHT }); 
                }
                this.showLoader()
        })
        .catch((ex)=>{
            toast.error("Something went wrong." , { position: toast.POSITION.TOP_RIGHT }); 
        })
    }catch(exception) {
        toast.error("Something went wrong." , { position: toast.POSITION.TOP_RIGHT }); 
    }   
  }

  showBrokerReference() {
    let RutCorredor = this.state && this.state.single && this.state.single.value ? this.state.single.value : null;
    let Referencia = this.state.searchText;
    this.showLoader()
    this.setState({BuscaPolIteRefExterna: undefined})    
    getBrokerReference(RutCorredor && RutCorredor.toString(),Referencia)
    .then((res)=>{
        try{
            if(res && res.data && !_.isEmpty(res.data) && !_.isEmpty(res.data.BuscaPolIteRefExterna)) {
                this.setState({BuscaPolIteRefExterna: res.data.BuscaPolIteRefExterna})

            }else {
                toast.error(res.msg , { position: toast.POSITION.TOP_RIGHT }); 
            }
            this.showLoader()
        }catch(ex) {
            toast.error("Something went wrong." , { position: toast.POSITION.TOP_RIGHT }); 
        }          
    })
    .catch((ex)=>{
        toast.error("Something went wrong." , { position: toast.POSITION.TOP_RIGHT }); 
    })   
      
  }

  handleChange = name => value => {
    let expandSelectWidth=(value && value.label.length>18)?true:false;
    this.setState({  [name]: value, expandSelectWidth:expandSelectWidth });
  };
  customFilter=(text)=>{
    let filterSuggestion=this.state.suggestions.filter((element)=>{
      return (element.agente_rut.toString().indexOf(text)>-1 || element.agente_Nombre.toLocaleLowerCase().indexOf(text.toLocaleLowerCase())>-1)
    }).slice(0,200);
    this.setState({suggestionList:filterSuggestion});
  }

  handleChangeText(event) {
    const value = event.target.value;
    this.setState({ searchText: value  });
  }

  showLoader() {
    let showLoader = this.state.showLoader;
    this.setState({
      showLoader: !showLoader
    });
  }

  resetSearch() {
    this.setState({
        single: "",
        searchText : "",
        BuscaPolIteRefExterna: "",
        disableSearch: true,

    });
  }

  disableSearchBtn() {
      // let singleBln  = _.isEmpty(this.state.single) ?  true:  false;
      let searchTextEmpty = _.isEmpty(this.state.searchText) ?  true:  false;
      return searchTextEmpty;
  }


  render() {

    const { classes, theme } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };
    const data = {
      route: "/items-list/",
      keys: ["Poliza", "Item"]
    };
    const BuscaPolIteRefExterna =  this.state.BuscaPolIteRefExterna
    const showLoader = this.state.showLoader
    const keyArray = {"Poliza": "Poliza", "Endoso": "Endoso", "Item": "Item", "Rut": "Rut", "Nombre": "Nombre", "Ramo": "Ramo", "V_Desde": "V_Desde", "V_Hasta": "V_Hasta"}
    const suggestions= this.state.suggestionList;
    const width450px=this.state.expandSelectWidth?"width450px":"";
    const { i18n } = this.props;
    let translateObj =     (i18n.store.data[i18n.language] && i18n.store.data[i18n.language].translations) ||  i18n.store.data["es"].translations;
    return (
      <main className="main-container">
        { showLoader ? <Loader /> : null}
        { suggestions ?
        <div className="drop-down-menu">
            <div className="select-style">
                <Select
                    className={"font-14px "+width450px}
                    classes={classes}
                    styles={selectStyles}
                    options={suggestions.map(suggestion => ({
                        value: suggestion.agente_rut, 
                        label: suggestion.agente_Nombre,
                      }))}
                    NoOptionsMessage="Sin información"
                    components={components}
                    value={this.state.single}
                    onChange={this.handleChange('single')}
                    placeholder="Corredor"
                    isClearable
                    onInputChange={this.customFilter}
                /> 
            </div>
            <div className="search-wrapper">
                <TextField
                    id="custom-css-standard-input"
                    label="Referencia"
                    type="search"
                    className="text-field"
                    margin="normal"
                    value={this.state.searchText}
                    onChange={this.handleChangeText}
                />   
                <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    className="search-policy"
                    disabled = {this.disableSearchBtn()}
                    onClick={this.showBrokerReference.bind()}
                >
                  {translateObj.policy_page.accept_btn}
                </Button>
                <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    className="search-policy"
                    onClick = { this.resetSearch.bind()}
                >                
                {translateObj.policy_page.reset_btn}
                </Button>   
                </div>    
            </div>
            :  null}
       <div>
       {
            BuscaPolIteRefExterna ? 
            <DyanmicTableWithSorting 
            data={BuscaPolIteRefExterna}
            keyArray={keyArray}
            orderBy={keyArray[0]}
            pathData={data}
            isPaginationReq={this.state.isPaginationReq}
            isEnhancedTableHeadReq={this.state.isEnhancedTableHeadReq}
            defaultRowPerPage={this.state.defaultRowPerPage}
            virticalScroll={true}
            tableMaxHeight={"450"}
            /> : null
        }    
        </div>
        <ToastContainer />
      </main>
    );
  }
}


BrokerReference.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
  };
  
export default translate("translations")(withStyles(styles, { withTheme: true })(BrokerReference));
