export const siniestrotableKeys = {
  "siniestro": "Siniestro",
  "poliza": "Poliza",
  "item": "Item",
  "ramo": "Ramo",
  "oficinaAtencion": "Oficina de atencion",
  "nombreAsegurado": "Nombre asegurado",
  "patente": "Patente",
  "fechaSiniestro": "Fecha siniestro",
  "fechaDenuncio": "Fecha denuncio",
  "liquidador": "Liquidador",
  "tipoContacto": "Tipo Contacto"
};

export const siniestrotableForAreatrabajoKeys = {
  "nroSiniestro": "Siniestro",
  "fechaSiniestro": "Fecha siniestro",
  "tipoSiniestro": "Tipo sinestro",
  "estadoLiquidacion": "Estado liquidacion",
  "costo": "Costo",
  "provisionDefensa": "Provision defensa",
  "juzgado": "Juzgado",
  "gestion": "Gestion",
  "responsable": "Responsable"
};

export const gestionKeys = {
  "nroSiniestro": "Nro Siniestro",
  "nroTicket": "Nro Ticket",
  "concepto": "Concepto",
  "dscClasificacion": "Clasificacion",
  "nombreRazonSocial": "Nombre/Razon Social",
  "montoCIR": "Monto CIR",
  "fechaCreacion": "Fecha Creacion",
  "fecturaNC": "Factura/NC",
  "dscEstado": "Estado",
  "fechaRecaudacion":"Fecha Recaudacion"
};