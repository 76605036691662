import React from "react";
import TableComponet from "../../../BaseComponents/TableComponent/TableComponet";
export default class UserInformationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <main className="main-container">
        <div>
          <h3>User Information Page (WIP)</h3>
          <TableComponet />
        </div>
      </main>
    );
  }
}
