import React from "react";
import { translate, Trans } from "react-i18next";
import { getDocumentosByPolicyId } from "../../api/policy";
import { ToastContainer, toast } from "react-toastify";
import _ from "lodash";
import DyanmicTable from "../../../BaseComponents/DyanmicTable/DyanmicTable";
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import Loader from "../../../BaseComponents/Loader/Loader";
import Button from "@material-ui/core/Button";

class DocumentosComponents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      documentoData: undefined,
      searchText: "",
      showVolver: false
    };
    this.showDocumentos = this.showDocumentos.bind(this);
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    const policyNo = params.policyNo;
    if (policyNo) {
      this.setState({ searchText: policyNo, showVolver: true });
      this.showDocumentos(policyNo);
    }
  }
  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  showDocumentos(policyNumber) {
    this.showLoaderAction();
    let policyNo = policyNumber ? policyNumber : this.state.searchText;
    if (policyNo) {
      getDocumentosByPolicyId(policyNo).then(response => {
        this.showLoaderAction();
        if (!_.isEmpty(response.data)) {
          this.setState({
            showClaim: true,
            documentoData: response.data
          });
        } else {
          toast.error(response.msg || "No details found for given policy", {
            position: toast.POSITION.TOP_RIGHT
          });
          this.setState({
            showClaim: true,
            documentoData: undefined
          });
        }
      });
    }
  }

  backToPreviousPage = () => {
    window.history.back();
  };

  render() {
    let showLoader = this.state.showLoader;
    let itemKeyArray =
      this.state.documentoData && this.state.documentoData.documentos
        ? Object.keys(this.state.documentoData.documentos[0])
        : undefined;
    let itemKeyJson = itemKeyArray
      ? itemKeyArray.reduce(function(o, val) {
          o[val] = val;
          return o;
        }, {})
      : undefined;
    return (
      <main className="main-container">
        <h3> Liquidadores por Póliza: {this.state.searchText} </h3>
        {showLoader ? <Loader /> : null}
        {this.state.documentoData && this.state.documentoData.documentos ? (
          <>
            <DyanmicTableWithSorting
              data={this.state.documentoData.documentos}
              keyArray={itemKeyJson}
              virticalScroll={true}
              tableMaxHeight={"400"}
              coloumnLeftAlign={"nombre"}
            />
            {this.state.showVolver ? (
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className="search-policy"
                onClick={this.backToPreviousPage}
                style={{ float: "right", marginTop: 10 }}
              >
                {"Volver"}
              </Button>
            ) : null}
          </>
        ) : null}
        <ToastContainer />
      </main>
    );
  }
}

export default translate("translations")(DocumentosComponents);
