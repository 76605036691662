import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeComponent from "./Phoenix/components/HomePage/HomePage";
import EmptyPage from "./Phoenix/components/EmptyPage/EmptyPage";
import ClaimByPolicyNoComponent from "./Phoenix/components/ClaimByPolicyNoComponent/ClaimByPolicyNoComponent";
import ItemsComponent from "./Phoenix/components/ItemsComponent/ItemsComponent";
import ItemsListComponent from "./Phoenix/components/ItemsListComponent/ItemsListComponent";
import EndorsementsComponent from "./Phoenix/components/EndorsementsComponent/EndorsementsComponent";
import EndorsementDetails from "./Phoenix/components/EndorsementsComponent/EndorsementDetails/EndorsementDetails";
import CoInsurance from "./Phoenix/components/CoInsurance/CoInsurance";
import Buskar from "./Phoenix/components/Buskar/Buskar";
import CollectionsComponent from "./Phoenix/components/CollectionsComponent/CollectionsComponent";
import GlobalSearch from "./Phoenix/components/GlobalSearch/GlobalSearch";
import GlobalSearchPorClient from "./Phoenix/components/GlobalSearch/GlobalSearchPorClient";
import PorPoliza from "./Phoenix/components/GlobalSearch/PorPoliza";
import PorSiniestro from "./Phoenix/components/GlobalSearch/PorSiniestro";
import UserInformationComponent from "./Phoenix/components/UserInformationComponent/UserInformationComponent";
import PaymentsComponent from "./Phoenix/components/PaymentsComponent/PaymentsComponent";
import DocumentosComponents from "./Phoenix/components/DocumentosComponents/documentosComponents";
import CoverageComponent from "./Phoenix/components/CoverageComponent/CoverageComponent";
import ResumenComponet from "./Phoenix/components/CollectionsComponent/ResumenComponet/ResumenComponet";
import LineOfBusiness from "./Phoenix/components/GlobalSearch/LineOfBusiness";
import LineOfBusinessDetail from "./Phoenix/components/GlobalSearch/LineOfBusinessDetail";
import DetaileDePoliza from "./Phoenix/components/GlobalSearch/DetaileDePoliza";
import Header from "./Partials/Header/Header";
import SideBar from "./Partials/SideBar/SideBar";
import "./App.scss";
import Page403 from "./Phoenix/components/Page403/Page403";
import BrokerReference from "./Phoenix/components/BrokerReference/BrokerReference";
import PatenteMotorComponent from "./Phoenix/components/PatenteMotorComponent/PatenteMotorComponent";
import PorRamos from "./Phoenix/components/GlobalSearch/PorRamos";
import PorNegocio from "./Phoenix/components/GlobalSearch/PorNegocio";
import { PacPat } from "./Routes/pac-pat";
import { Reaseguradores } from "./Routes/reaseguradores";
import { RamosVarios } from "./Routes/ramos-varios";
import { AnalistaRecuperos } from "./Routes/use-case25";
import PorReferenciaCorredor from "./Phoenix/components/GlobalSearch/PorReferenciaCorredor";
import { Vehiculos } from "./Routes/vehiculos";
import { CajaPagadora } from "./Routes/caja-pagadora";
import { CajaPenta } from "./Routes/caja-penta";
import { ConsultaGlobal } from "./Routes/consulta-global";
import PageNotFound from "./Phoenix/components/ErrorPage/pageNotFound";
import UnAuthrorisedPage from "./Phoenix/components/ErrorPage/unAuthrorisedPage";
import { roleList } from "./Constants/roleConfig";
import { MENU } from "./Constants/menu";
import ProtectedComponents from "./Phoenix/components/protectedDownloads/protectedComponents";

export default class RouterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flagLinkMached: false
    };
  }
  componentDidMount() {
    let userRole = localStorage.getItem("userRole")
      ? JSON.parse(localStorage.getItem("userRole"))[0]
      : "";
    if (userRole) {
      let listOfApp = roleList[userRole];
      let currentUrl =
        window.location.pathname === "/" ? "/policy" : window.location.pathname;
      let flagLinkMached = false;
      MENU.forEach(item => {
        if (listOfApp.indexOf(item.key) > -1) {
          item.children.forEach(child => {
            if (currentUrl.indexOf(child.link) > -1) {
              flagLinkMached = true;
            }
          });
        }
      });
      this.setState({ flagLinkMached: flagLinkMached });
    }
  }

  render() {
    return (
      <Router>
        <div>
          {this.props.data.authorizedUser ? (
            <div className="dsp-flex">
              <Header
                className="remove-margin"
                sideBarOpen={this.props.data.open}
                handleDrawerOpen={this.props.handleDrawerOpen}
                handleDrawerClose={this.props.handleDrawerClose}
              />
              <SideBar
                sideBarOpen={this.props.data.open}
                handleDrawerClose={this.props.handleDrawerClose}
              />
              {this.state.flagLinkMached ? (
                <>
                  <Switch>
                    {["/", "/policy", "/policy/:policyNo"].map(
                      (path, index) => (
                        <Route
                          exact
                          path={path}
                          component={HomeComponent}
                          key={index}
                        />
                      )
                    )}
                    <Route
                      exact
                      path="/policy/:policy/:rut"
                      component={HomeComponent}
                    />
                    <Route
                      exact
                      path="/porcliente/line-of-business"
                      component={LineOfBusiness}
                    />
                    <Route
                      exact
                      path="/porcliente/line-of-business/:ramo"
                      component={LineOfBusinessDetail}
                    />
                    <Route
                      exact
                      path="/porcliente/line-of-business/poliza/:poliza"
                      component={DetaileDePoliza}
                    />
                    <Route
                      exact
                      path="/porcliente/line-of-business/poliza/:poliza/:item"
                      component={DetaileDePoliza}
                    />
                    {[
                      "/claims",
                      "/claims/:policyNo",
                      "/claims/:policyNo/:itemNo"
                    ].map((path, index) => (
                      <Route
                        exact
                        path={path}
                        component={ClaimByPolicyNoComponent}
                        key={"claim" + index}
                      />
                    ))}
                    <Route
                      exact
                      path="/items-list"
                      component={ItemsListComponent}
                    />
                    <Route
                      exact
                      path="/items-list/:policyNo"
                      component={ItemsListComponent}
                    />
                    <Route
                      exact
                      path="/items-list/:policyNo/:itemNo"
                      component={ItemsComponent}
                    />
                    <Route
                      exact
                      path="/items-list/coverage/:policyNo/:itemNo"
                      component={CoverageComponent}
                    />
                    <Route
                      exact
                      path="/documento/poliza/:policyNo"
                      component={DocumentosComponents}
                    />
                    <Route exact path="/items" component={ItemsComponent} />
                    <Route exact path="/buscar" component={Buskar} />
                    <Route
                      exact
                      path="/buscar/:policyNo/:rut"
                      component={Buskar}
                    />
                    <Route
                      exact
                      path="/collections"
                      component={CollectionsComponent}
                    />
                    {/* <Route
                      exact
                      path="/documento/"
                      component={CollectionsComponent}
                    />
                    <Route
                      exact
                      path="/documento/:documentoNo"
                      component={CollectionsComponent}
                    /> */}
                    {["/documento", "/documento/:documentoNo"].map(
                      (path, index) => (
                        <Route
                          exact
                          path={path}
                          component={CollectionsComponent}
                          key={"documento" + index}
                        />
                      )
                    )}
                    <Route
                      exact
                      path="/documento/resumen/:documentoNo"
                      component={ResumenComponet}
                    />
                    <Route
                      exact
                      path="/collections/:policyNo"
                      component={CollectionsComponent}
                    />
                    <Route
                      exact
                      path="/collections/resumen/:policyNo"
                      component={ResumenComponet}
                    />
                    <Route
                      exact
                      path="/endorsement"
                      component={EndorsementsComponent}
                    />
                    <Route
                      exact
                      path="/endorsement/:policyNo"
                      component={EndorsementsComponent}
                    />
                    <Route
                      exact
                      path="/endorsement-details/:policyNo/:itemNo"
                      component={EndorsementDetails}
                    />
                    <Route exact path="/coinsurance" component={CoInsurance} />
                    <Route
                      exact
                      path="/coinsurance/:policyNo"
                      component={CoInsurance}
                    />
                    <Route
                      exact
                      path="/global-search"
                      component={GlobalSearch}
                    />
                    <Route
                      exact
                      path="/user-information"
                      component={UserInformationComponent}
                    />
                    <Route
                      exact
                      path="/payments"
                      component={PaymentsComponent}
                    />
                    <Route
                      exact
                      path="/porcliente"
                      component={GlobalSearchPorClient}
                    />
                    <Route
                      exact
                      path="/broker-reference"
                      component={BrokerReference}
                    />
                    <Route
                      exact
                      path="/patento-motor"
                      component={PatenteMotorComponent}
                    />
                    <Route exact path="/porPoliza" component={PorPoliza} />
                    <Route
                      exact
                      path="/porPoliza/line-of-business/ramo"
                      component={LineOfBusinessDetail}
                    />
                    <Route
                      exact
                      path="/porPoliza/line-of-business/poliza/:poliza"
                      component={DetaileDePoliza}
                    />
                    <Route exact path="/porsinistro" component={PorSiniestro} />
                    <Route
                      exact
                      path="/porsinistro/line-of-business/ramo"
                      component={LineOfBusinessDetail}
                    />
                    <Route
                      exact
                      path="/porsinistro/line-of-business/poliza/:poliza"
                      component={DetaileDePoliza}
                    />
                    <Route exact path="/por-ramos" component={PorRamos} />
                    <Route
                      exact
                      path="/por-ramos/line-of-business"
                      component={LineOfBusiness}
                    />
                    <Route
                      exact
                      path="/por-ramos/line-of-business/:ramo"
                      component={LineOfBusinessDetail}
                    />
                    <Route
                      exact
                      path="/por-ramos/line-of-business/poliza/:poliza/:item"
                      component={DetaileDePoliza}
                    />
                    <Route exact path="/pornegocio" component={PorNegocio} />
                    <Route
                      exact
                      path="/pornegocio/line-of-business"
                      component={LineOfBusiness}
                    />
                    <Route
                      exact
                      path="/pornegocio/line-of-business/:ramo"
                      component={LineOfBusinessDetail}
                    />
                    <Route
                      exact
                      path="/pornegocio/line-of-business/poliza/:poliza"
                      component={DetaileDePoliza}
                    />
                    <Route
                      exact
                      path="/pornegocio/line-of-business/poliza/:poliza/:item"
                      component={DetaileDePoliza}
                    />
                    <Route
                      exact
                      path="/PorReferenciaCorredor"
                      component={PorReferenciaCorredor}
                    />
                    <Route
                      exact
                      path="/PorReferenciaCorredor/line-of-business/ramo"
                      component={LineOfBusinessDetail}
                    />
                    <Route
                      exact
                      path="/PorReferenciaCorredor/line-of-business/poliza/:poliza"
                      component={DetaileDePoliza}
                    />
                    <Route path="/pac-pat/" component={PacPat} />
                    <Route path="/reaseguradores/" component={Reaseguradores} />
                    <Route path="/vehiculos/" component={Vehiculos} />
                    <Route path="/reaseguradores/" component={Reaseguradores} />
                    <Route path="/caja-pagadora/" component={CajaPagadora} />
                    <Route
                      path="/consulta-global/"
                      component={ConsultaGlobal}
                    />
                    <Route path="/ramos/" component={RamosVarios} />
                    {/* <Route path="/consulta/" component={Consulta} /> */}
                    <Route path="/caja-penta/" component={CajaPenta} />
                    <Route
                      path="/analista-recuperos/"
                      component={AnalistaRecuperos}
                    />
                    <Route
                      exact
                      path="/excel/:downloadPath"
                      component={ProtectedComponents}
                    />
                    <Route path="/*" component={EmptyPage} />
                  </Switch>
                </>
              ) : (
                <>
                  <Route
                    exact
                    path="/excel/:downloadPath"
                    component={ProtectedComponents}
                  />
                  <Route path="/*" component={EmptyPage} />
                </>
              )}
            </div>
          ) : (
            <>
              <Route exact path="/" component={Page403} />
              <Route exact path="*" component={Page403} />
            </>
          )}
        </div>
      </Router>
    );
  }
}
