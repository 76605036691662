export const local =  {
    apiEndPoint:"https://m9n454aw8mvf.execute-api.us-east-1.amazonaws.com/dev/",
    env:"local",
    adalConfig: {
        tenant: 'LibertyMutual.onmicrosoft.com',
        tenantId: '08a844539-90e7-449bf-9075-957656561bf1',
        clientId: '77cf73d2-959a-4821-97c7-becb98f30758',// application id fro local env
        endpoints: {
            api: 'https://libertymutual.onmicrosoft.com//87dhfuf7-e0d6-422c-b8a8-41d22170cdac',
        },
        cacheLocation: 'localStorage',
    }
};

// export const local =  {
//     apiEndPoint: "https://nh8z31aqf9.execute-api.us-east-1.amazonaws.com/nonprod/",
//     env:"nonprod",    
//     adalConfig: {
//         tenant: 'LibertyMutual.onmicrosoft.com',
//         tenantId: '08a83339-90e7-49bf-9075-957ccd561bf1',
//         clientId: '5ff877e2-7ae6-4716-8962-a2e06d6dacc3',// application id fro non-prod env
//         endpoints: {
//             api: 'https://libertymutual.onmicrosoft.com/157a6bd1-a58b-4541-bb9e-eac5ccd36984',
//         },
//         cacheLocation: 'localStorage',
//     }
// };