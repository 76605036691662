import React from "react";
import { config, base_url, axiousInstance } from './index';
let axios=axiousInstance;



export function getfechaproceso() {
  let data = {};
  return axios
    .post(base_url + "pat-pac/getfechaproceso", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getrptcargosrechazados(pFechaProceso,pSucursal){
    let data = {
        "pFechaProceso":  pFechaProceso ,    ///"2008-08-15",
         "pSucursal":  pSucursal///"Santiago"
    };
   return axios
    .post(base_url + "pat-pac/getrptcargosrechazados", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });

}

export function getObtBitaCoraMandato(pRutTitular, pRutTitularDiv) {
  let data = {"pRutTitular" : pRutTitular, //10255726 
              "pRutTitularDiv" : pRutTitularDiv //3
   };
  return axios
    .post(base_url + "pat-pac/getObtBitaCoraMandato", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getBitaCoraMandato(pMandato) {
  let data = {'pMandato' : pMandato //20089223
            };
  return axios
    .post(base_url + "pat-pac/getBitaCoraMandato", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}



