import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { translate, Trans } from "react-i18next";
import Card from '@material-ui/core/Card';
import Button from "@material-ui/core/Button";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import './consultasiniestros.scss';
import { getRamosVariosDetail } from "./../../api/ramoVarios";
import _ from 'lodash'

const styles = theme => ({
});

class ConsultadeSiniestro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data : undefined,
      showData :  false,
      enableSearch : true
    };
  }
  
  componentDidMount() {
    const { match: { params } , location : {search} } = this.props;
    let query = ""
    let keyValue = {}
    if(search){
        query =  search.slice(1,search.length).split("&")
        _.forEach(query,(q)=>{
            let splitString =  q.split("=")
            let key = splitString[0];
            let value = splitString[1];
            keyValue[key] = value
            
        })
    }
    let siniestro=params.siniestro;
    let policy=params.policy;
    let item=params.item;
    this.setState({
        siniestro,
        policy,
        item ,
        keyValue,
        search
    })
    this.showLoaderAction();
    getRamosVariosDetail(siniestro, policy, item).then(response=>{
        this.showLoaderAction();
        if(response && Object.keys(response.data)){
            this.setState({
                data:response.data,
                showData:true
            })
        }else{
            this.setState({
                data:undefined,
                showData:false
            })
        }
    })
  }
  handleRadioChange = event => {
    let value = event.target.value;
    this.setState({
        selectedRadionValue: value,
    });
  };
  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  VolverClick=()=>{
    let { policyNo ,itemNo, nroSiniestro } = this.state.keyValue;
    let {policy ,item, siniestro } =  this.state;
    if(policyNo == "true" && itemNo == "true" ){
        this.props.history.push(`/ramos/consultasiniestros/?policyNo=${policy}&itemNo=${item}`)
    }else if(policyNo == "true"){
        this.props.history.push(`/ramos/consultasiniestros/?policyNo=${policy}`)
    }
    else if(nroSiniestro = "true") {
        this.props.history.push(`/ramos/consultasiniestros/?nroSiniestro=${siniestro}`)
    }
  }

  render() {
    const { i18n } = this.props;
    let translateObj = (i18n.store.data[i18n.language] && i18n.store.data[i18n.language].translations) ||  i18n.store.data["es"].translations;
    return (
      <main className="main-container">
        {this.state.showLoader ? <Loader /> : null}
        <h3>{"Consulta de Siniestro"}</h3>
        { this.state.showData ? <>
            <div className="search-wrapper">
            <Button variant="contained" size="medium" onClick={this.VolverClick} color="primary" className="search-policy button-position-style">
                            {"Volver"}
                        </Button>
                    <Link  to={{ pathname: `/ramos/consultasiniestros/pagos/${this.state.siniestro}/${this.state.policy}/${this.state.item}${this.state.search}`}} style={{ textDecoration: "none" }}>
                        <Button variant="contained" size="medium" color="primary" className="search-policy button-position-style">
                            {"Pagos"}
                        </Button>
                    </Link>
                    <Link  to={{ pathname: `/ramos/consultasiniestros/perdidaprobable/${this.state.siniestro}/${this.state.policy}/${this.state.item}${this.state.search}`}} style={{ textDecoration: "none" }}>
                        <Button variant="contained" size="medium" color="primary" className="search-policy button-position-style">
                            {"Pérdida Probable"}
                        </Button>
                    </Link>
            </div>
            <Card className="Card-area box-show-none">
            <div class="margin-top-30">
                <fieldset className="custom-border">
                    <legend className="legend-box">Antecedentes de Póliza</legend>
                    <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Póliza / Ítem:"
                            className="textField"
                            value={this.state.data.antecedentesDePoliza.polizaItem}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 250}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Vig. Desde:"
                            className="textField"
                            value={this.state.data.antecedentesDePoliza.vigDesde}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Vig. Hasta:"
                            className="textField"
                            value={this.state.data.antecedentesDePoliza.vigHasta}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Moneda:"
                            className="textField"
                            value={this.state.data.antecedentesDePoliza.moneda}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 250}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Ramo:"
                            className="textField"
                            value={this.state.data.antecedentesDePoliza.ramo}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 320}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Oficina Póliza:"
                            className="textField"
                            value={this.state.data.antecedentesDePoliza.oficinaPoliza}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 400}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Rut Contratante:"
                            className="textField"
                            value={this.state.data.antecedentesDePoliza.rutContratante}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Nombre Contratante:"
                            className="textField"
                            value={this.state.data.antecedentesDePoliza.nombreContratante}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 550}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Rut Asegurado:"
                            className="textField"
                            value={this.state.data.antecedentesDePoliza.rutAsegurado}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 400}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Nombre Asegurado:"
                            className="textField"
                            value={this.state.data.antecedentesDePoliza.nombreAsegurado}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 550}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Rut Corredor:"
                            className="textField"
                            value={this.state.data.antecedentesDePoliza.rutCorredor}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 400}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Nombre Corredor:"
                            className="textField"
                            value={this.state.data.antecedentesDePoliza.nombreCorredor}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 300}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Ref. Corredor:"
                            className="textField"
                            value={this.state.data.antecedentesDePoliza.refCorredor}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Nº Póliza Otra Cía.:"
                            className="textField"
                            value={this.state.data.antecedentesDePoliza.nPolizaOtraCia}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Compañía Lider:"
                            className="textField"
                            value={this.state.data.antecedentesDePoliza.companiaLider}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                </div>
                </fieldset>
            </div>
            <div class="margin-top-30">
                <fieldset className="custom-border">
                    <legend className="legend-box">Antecedentes Generales</legend>
                    <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Nro. Siniestro:"
                            className="textField"
                            value={this.state.data.antecedentesGenerales.nroSiniestro}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 250}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Nro. Siniestro Otra Cía.:"
                            className="textField"
                            value={this.state.data.antecedentesGenerales.nroSiniestroOtrasCia}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Estado:"
                            className="textField"
                            value={this.state.data.antecedentesGenerales.estado}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 250}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Fecha Siniestro:"
                            className="textField"
                            value={this.state.data.antecedentesGenerales.fechaSiniestro}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Hora Siniestro:"
                            className="textField"
                            value={this.state.data.antecedentesGenerales.horaSiniestro}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Fecha Denuncio:"
                            className="textField"
                            value={this.state.data.antecedentesGenerales.fechaDenuncio}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 250}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Oficina Atención:"
                            className="textField"
                            value={this.state.data.antecedentesGenerales.oficinaAtencion}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Lugar Ocurrencia:"
                            className="textField"
                            value={this.state.data.antecedentesGenerales.lugarOcurrencia}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 250}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Comuna:"
                            className="textField"
                            value={this.state.data.antecedentesGenerales.comuna}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Consultora:"
                            className="textField"
                            value={this.state.data.antecedentesGenerales.consultor}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Liquidador:"
                            className="textField"
                            value={this.state.data.antecedentesGenerales.liquidador}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 520}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Catástrofe:"
                            className="textField"
                            value={this.state.data.antecedentesGenerales.catastrofe}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Tiene Impugnación Asegurado"
                            className="textField"
                            value={this.state.data.antecedentesGenerales.tieneImpugnacionAsegurado}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                </div>
                </fieldset>
            </div>
            <div class="margin-top-30">
                <fieldset className="custom-border">
                    <legend className="legend-box">Relato de los Hechos</legend>
                    <div className="vertical-align-middle">
                        <span className="Space-around">
                            <TextField InputLabelProps={{
                                shrink: true,
                                }}
                                id="standard-bare"
                                className="textField"
                                value={this.state.data.relatoDeLosHechos.lista}
                                margin="normal"
                                variant="outlined"
                                multiline="true"
                                rows="2"
                                rowsMax="5"
                                InputProps={{
                                readOnly: true,
                                }}
                                fullWidth
                                style = {{wordWrap: 'break-word'}}
                            />
                        </span>
                    </div>
                </fieldset>
            </div>
            <div class="margin-top-30">
                <fieldset className="custom-border">
                    <legend className="legend-box">Antecedentes Legales</legend>
                    <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Fecha Constancia:"
                            className="textField"
                            value={this.state.data.antecedentesLegales.fechaConstancia}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 250}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Comisaria:"
                            className="textField"
                            value={this.state.data.antecedentesLegales.comisaria}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Nro. Constancia:"
                            className="textField"
                            value={this.state.data.antecedentesLegales.nroConstancia}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Folio:"
                            className="textField"
                            value={this.state.data.antecedentesLegales.folio}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Párrafo:"
                            className="textField"
                            value={this.state.data.antecedentesLegales.parrafo}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Rol de la Causa:"
                            className="textField"
                            value={this.state.data.antecedentesLegales.roldelaCausa}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 250}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Fiscalía"
                            className="textField"
                            value={this.state.data.antecedentesLegales.fiscalia}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                </div>
                </fieldset>
            </div>
            <div class="margin-top-30">
                <fieldset className="custom-border">
                    <legend className="legend-box">Datos Contacto</legend>
                    <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Nombre"
                            className="textField"
                            value={this.state.data.datosContacto.nombre}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 500}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Dirección"
                            className="textField"
                            value={this.state.data.datosContacto.direccion}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 400}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Comuna"
                            className="textField"
                            value={this.state.data.datosContacto.comuna}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 220}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Teléfono"
                            className="textField"
                            value={this.state.data.datosContacto.telefono}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Celular"
                            className="textField"
                            value={this.state.data.datosContacto.celular}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Email"
                            className="textField"
                            value={this.state.data.datosContacto.email}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 250}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Tipo Notificación"
                            className="textField"
                            value={this.state.data.datosContacto.tipoNotificacion}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Email Corredor Denuncio"
                            className="textField"
                            value={this.state.data.datosContacto.emailCorredorDenuncio}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 250}}
                        />
                    </span>
                </div>
                </fieldset>
            </div>
            <div class="margin-top-30">
                <fieldset className="custom-border">
                    <legend className="legend-box">Antecedentes Terceros</legend>
                    <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Rut"
                            className="textField"
                            value={this.state.data.object2.rut}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 250}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Nombre"
                            className="textField"
                            value={this.state.data.object2.nombre}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 350}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Dirección"
                            className="textField"
                            value={this.state.data.object2.direccion}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 270}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Comuna"
                            className="textField"
                            value={this.state.data.object2.comuna}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Teléfono"
                            className="textField"
                            value={this.state.data.object2.telefono}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 200}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Email"
                            className="textField"
                            value={this.state.data.object2.email}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 250}}
                        />
                    </span>
                </div>
                </fieldset>
            </div>
            <div class="margin-top-30">
                <fieldset className="custom-border">
                    <legend className="legend-box">Datos Adicionales</legend>
                    <div className="vertical-align-middle">
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Nro. Vuelo"
                            className="textField"
                            value={this.state.data.object3.nroVuelo}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 250}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="B/L"
                            className="textField"
                            value={this.state.data.object3.bL}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 350}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Lugar Ocurrencia"
                            className="textField"
                            value={this.state.data.object3.lugarOcurrencia}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 270}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Nombre Transportista"
                            className="textField"
                            value={this.state.data.object3.nombreTransportista}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 250}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
                            shrink: true,
                            }}
                            id="standard-bare"
                            label="Identificación Medio Transportador"
                            className="textField"
                            value={this.state.data.object3.identificacionMedioTransportador}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 350}}
                        />
                    </span>
                </div>
                </fieldset>
            </div>
            </Card></>
        :null}
        <ToastContainer />
      </main>
    );
  }
}


ConsultadeSiniestro.propTypes = {
  classes: PropTypes.object.isRequired
};

export default translate("translations")( withStyles(styles)(ConsultadeSiniestro));


