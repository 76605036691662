import React from 'react'
import TextField from '@material-ui/core/TextField';
import  './AntecedentesProponente.scss'
import Card from '@material-ui/core/Card';

export default function AntecedentesProponente(props) {
const antecedentesProponente = props.data;
  return (
    <div className="Main-container">
     {antecedentesProponente ?
        <Card className="Card-area box-show-none">
        <fieldset className="custom-border">
         <legend className="legend-box">Antecedentes Proponente</legend>
            {/* <h3 className="Heading-name">Antecedentes Proponente</h3> */}
            <div>
                <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Nombre"
                        className="textField"
                        margin="normal"
                        variant="outlined"
                        value={antecedentesProponente.nombre}
                        InputProps={{
                            readOnly: true,
                          }}
                        style = {{width: 900}}
                    />
                </span>
            </div>
            <div className="vertical-align-middle">
                <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Dirección"
                        className="textField"
                        margin="normal"
                        value={antecedentesProponente.direccion}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                        style = {{width: 500}}
                    />
                </span>
                <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Rut"
                        className="textField"
                        margin="normal"
                        value={antecedentesProponente.rut[0]}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                        style = {{width: 240}}
                    />
                    <span className="minus-sign">-</span>
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label=""
                        className="textField"
                        margin="normal"
                        value={antecedentesProponente.rut[1]}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                        style = {{width: 100}}
                    />
                </span>
            </div>
            <div>
                <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Comuna"
                        className="textField"
                        margin="normal"
                        value={antecedentesProponente.comuna}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                        style = {{width: 300}}
                    />
                </span>
                <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Región"
                        className="textField"
                        margin="normal"
                        value={antecedentesProponente.region}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                        style = {{width: 300}}
                    />
                </span>
                <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Teléfono"
                        className="textField"
                        margin="normal"
                        value={antecedentesProponente.telefono}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                        style = {{width: 260}}
                    />
                </span>
            </div>
            </fieldset>
        </Card>
        :null
        }
    </div>
  )
}
