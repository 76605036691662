import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { translate, Trans } from "react-i18next";
import Button from "@material-ui/core/Button";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import './pacpat.scss'
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import _ from 'lodash';
import { NoOptionsMessage, inputComponent, Control, Option , Placeholder ,SingleValue ,ValueContainer, MultiValue, Menu} from  '../../../Constants/dailog'
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import Select from 'react-select';
import { getfechaproceso , getrptcargosrechazados } from '../../api/pacpat'
import ExcelDownload  from '../../../utils/ExcelDownloadComponent'
import moment from "moment";
import 'moment/min/locales';
import SearchIcon from "@material-ui/icons/CloudDownload";

const styles = theme => ({
    root: {
      flexGrow: 1,
      height: 350,
    },
    input: {
      display: 'flex',
      padding: 0,
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    chip: {
      margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
        0.08,
      ),
    },
    noOptionsMessage: {
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
      fontSize: 16,
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      fontSize: 16,
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing.unit,
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing.unit * 2,
    },
  });


  
  const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
  }; 


class InformeDeCargosRechazados extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      single: undefined,
      date :  undefined,
      data : undefined,
      disableSearch: true,
      suggestions : [],
      suggestionList : [],
      dateRangeList : [],
      showTable :  false,
      showLoader: false,
      isPaginationReq: true,
      isEnhancedTableHeadReq: true,
      defaultRowPerPage: 5,
      expandSelectWidth:false ,
      showForm : false ,
      excelDownlaod : false,
      excelTitle:"Informe de Cargos rechazados del proceso ",
      disableSearchBtn:true    
    };
   
   
    this.resetSearch = this.resetSearch.bind(this);
    this.disableSearchBtn =  this.disableSearchBtn.bind(this);
    this.showTableData   =  this.showTableData.bind(this);
    this.handleSuggestionsChange   = this.handleSuggestionsChange.bind(this);
    this.customFilterSuggestions = this.customFilterSuggestions.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.customFilterDate =  this.customFilterDate.bind(this);
    this.donwloadExcel = this.donwloadExcel.bind(this);
    this.getExcelName =  this.getExcelName.bind(this);

  }
  
  componentDidMount() {
    this.showLoaderAction()
    try {
      getfechaproceso()
      .then((res)=>{
        this.showLoaderAction()
        if(res && res.data && !_.isEmpty(res.data)){
          this.setState({
            suggestions :  res.data.sucursalDetails,
            suggestionList: res.data.sucursalDetails,
            dateRange : res.data.fechaProcesoDetails,
            dateRangeList : res.data.fechaProcesoDetails,
            showForm : true
          })
        }else {
          toast.error(res.msg, {position: toast.POSITION.TOP_RIGHT});
        }
      })
      .catch((ex)=>{
        toast.error("Something went wrong.", {position: toast.POSITION.TOP_RIGHT});
      })

    }catch(ex){
      toast.error("Something went wrong.", {position: toast.POSITION.TOP_RIGHT});
    }
    
  }

  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  handleSuggestionsChange = name => value => {
    let expandSelectWidth=(value && value.label.length>18)?true:false;
    this.setState({  [name]: value, expandSelectWidth:expandSelectWidth, disableSearchBtn:(value && this.state.date)?false:true });
  };
 

  customFilterSuggestions =(text)=>{
    let filterSuggestion=this.state.suggestions.filter((element)=>{
      return (element.sucursal.toString().indexOf(text)>-1 || element.sucursal.toLocaleLowerCase().indexOf(text.toLocaleLowerCase())>-1)
    }).slice(0,200);
    this.setState({suggestionList:filterSuggestion});
  }

  handleDateChange = name => value => {
    let expandSelectWidth=(value && value.label.length>18)?true:false;
    this.setState({  [name]: value, expandSelectWidth:expandSelectWidth, disableSearchBtn:(value && this.state.single)?false:true });
  };
 

  customFilterDate =(text)=>{
    console.log("chnage select")
    let filterSuggestion=this.state.dateRange.filter((element)=>{
      return (element.fechaProceso.toString().indexOf(text)>-1 || element.fechaProceso.toLocaleLowerCase().indexOf(text.toLocaleLowerCase())>-1)
    }).slice(0,200);
    this.setState({dateRangeList:filterSuggestion});
  }

  resetSearch() {
    this.setState({
        single: "",
        date : "",
        data:  "",
        showTable : false,
        disableSearch: true,
        disableSearchBtn:true
    });
  }

  disableSearchBtn() {
      let singleBln  = _.isEmpty(this.state.single) ?  true:  false;
      let dateValue = _.isEmpty(this.state.dateRange) ?  true:  false;
      return singleBln || dateValue;
  }

  showTableData(){
    this.showLoaderAction()
    let p_fechaProceso = this.state.date.value
    let  p_sucursal    = this.state.single.value
    this.setState({
      data :  undefined,
      showTable  : false
    })
    getrptcargosrechazados(p_fechaProceso , p_sucursal)
    .then((res)=>{
      this.showLoaderAction()
      if(res && res.data && !_.isEmpty(res.data)){
        this.setState({
          data :  res.data.RptCargosRechazados,
          showTable  : true
        })
      }else {
       
        toast.error(res.msg, {position: toast.POSITION.TOP_RIGHT});
      }
    })
    .catch((ex)=>{
      toast.error("Something wenyt wrong.", {position: toast.POSITION.TOP_RIGHT});

    })   
  }

  donwloadExcel(){
    this.setState({
      excelDownlaod : true
    })
    setTimeout(()=>{
      this.setState({
        excelDownlaod : false
      })
    },100)
  }

  getExcelName(){
    let p_fechaProceso = this.state.date.value
    let  p_sucursal    = this.state.single.value

    if( p_fechaProceso && p_sucursal) {
      return  p_sucursal + "_"  + p_fechaProceso ;
    }
  }

  render() {
    let excelFirstColoulnValue=this.state.excelTitle;
    if(this.state.date &&  this.state.date.value){
      let dateInSpanish= moment(this.state.date.value).locale('es').format('DD/MMMM/YYYY');
      excelFirstColoulnValue=excelFirstColoulnValue+" "+dateInSpanish;
    }
    const { i18n } = this.props;
    let translateObj =     (i18n.store.data[i18n.language] && i18n.store.data[i18n.language].translations) ||  i18n.store.data["es"].translations;
    const { classes, theme } = this.props;
    const width450px=this.state.expandSelectWidth?"width450px":"";
    const keyArray =  {
      "Sucursal": "Sucursal",
      "Rut Titular": "Rut Titular",
      "Nombre Titular": "Nombre Titular",
      "Entidad Recaudadora": "Entidad Recaudadora",
      "TipoDocumento": "TipoDocumento",
      "Rut Cliente": "Rut Cliente",
      "Rut Corredor": "Rut Corredor",
      "Nombre Corredor": "Nombre Corredor",
      "Negocio": "Negocio",
      "Rut Contratante": "Rut Contratante",
      "Documento": "Documento",
      "Cuota": "Cuota",
      "Moneda": "Moneda",
      "Monto Cuota MO": "Monto Cuota MO",
      "Motivo rechazo": "Motivo rechazo"
    }
    const data = this.state.data;
    const showTable = this.state.showTable;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    let newFormatData= data && data.map((singleObj)=>{
      return Object.values(singleObj);
    })
    let finalData=[{
      columns: [excelFirstColoulnValue],
      data: [
          []
      ]
      },
      {
          xSteps: 0, // Will start putting cell with 0 empty cell on left most
          ySteps: 0, //will put space of 2 rows,
          columns: [
            "Sucursal",
            "Rut Titular",
            "Nombre Titular",
            "Entidad Recaudadora",
            "TipoDocumento",
            "Rut Cliente",
            "Rut Corredor",
            "Nombre Corredor",
            "Negocio",
            "Rut Contratante",
            "Documento",
            "Cuota",
            "Moneda",
            "Monto Cuota MO",
            "Motivo rechazo"
          ],
          data:newFormatData
      }
    ];
    let sheetName="sheet1";

    return (
      <main className="main-container">
          <div>
              {this.state.showLoader ? <Loader/> : null}
          </div>
          { this.state.showForm ?
          <div>  
          <div className="drop-down-menu">
             <div className="select-style">
                  <Select
                      className={"font-14px "+width450px}
                      classes={classes}
                      styles={selectStyles}
                      options={this.state.dateRange.map(d => ({
                          value: d.fechaProceso, 
                          label: d.fechaProceso,
                        }))}
                      components={components}
                      value={this.state.date}
                      onChange={this.handleDateChange('date')}
                      placeholder="Fecha de Proceso"
                      isClearable
                      onInputChange={this.customFilterDate}
                  /> 
             </div>
             <div className="select-style">
              <Select
                  className={"font-14px "+width450px}
                  classes={classes}
                  styles={selectStyles}
                  options={this.state.suggestions.map(suggestion => ({
                      value: suggestion.sucursal, 
                      label: suggestion.sucursal,
                    }))}
                  components={components}
                  value={this.state.single}
                  onChange={this.handleSuggestionsChange('single')}
                  placeholder="Sucursal"
                  isClearable
                  onInputChange={this.customFilterSuggestions}
              /> 
             </div>
             <div  className="search-wrapper select-style">
                <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    className="search-policy"
                    disabled = {this.state.disableSearchBtn}
                    onClick={this.showTableData.bind()}
                    >
                  {translateObj.policy_page.accept_btn}
                </Button>
                <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    className="search-policy"
                    onClick = { this.resetSearch.bind()}
                >                
                  {translateObj.policy_page.reset_btn}
                </Button>
              </div> 
           </div>
           </div>
          : null 
          }
          <div>
              {
                showTable ? 
                <div>
                  <DyanmicTableWithSorting 
                  data={data}
                  keyArray={keyArray}
                  orderBy={keyArray[0]}
                  pathData={{}}
                  isPaginationReq={this.state.isPaginationReq}
                  isEnhancedTableHeadReq={this.state.isEnhancedTableHeadReq}
                  defaultRowPerPage={this.state.defaultRowPerPage}
                />
                 <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    className="search-policy download-button-style"
                    onClick={this.donwloadExcel.bind()}
                    >
                  <SearchIcon className="download-icon" />
                  {"Excel"}
                </Button>
                </div> : null
              } 
              {
                this.state.excelDownlaod ?  <ExcelDownload excelData={finalData} sheetName={sheetName} excelName={this.getExcelName()}/> : null
              }
          </div>
          <ToastContainer />
      </main>
    );
  }
}

InformeDeCargosRechazados.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default translate("translations")(withStyles(styles, { withTheme: true })(InformeDeCargosRechazados));
