import React from "react";
import Button from "@material-ui/core/Button";
import "./error.scss";
import { BrowserRouter as Link } from "react-router-dom";
import {roleList} from "../../../Constants/roleConfig";
import { MENU } from "../../../Constants/menu";

class PageNotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      homeLink:"/"
    }
  }

  componentDidMount(){
    let userRole=(localStorage.getItem("userRole"))?JSON.parse(localStorage.getItem("userRole"))[0]:"";
    let listOfApp=roleList[userRole];
    for( var i in MENU){
      if(listOfApp.indexOf(MENU[i].key)>-1){
        this.setState({
          homeLink:MENU[i].defaultPage
        })
        break;
      }
    }
  }

  render() {
    return (
      <>
        <main className="main-container">
        <div className="page403 centered-aligned">
            <h2 className="color-message">
              Page Not Found
            </h2>
            <div className="align-item-center align-margin">
              <Link to="/" className="remove-text-decpration">
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  className="search-policy"
                  onClick={()=>{return window.location.pathname=this.state.homeLink }}
                >
                  Back to home page
                </Button>
              </Link>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default PageNotFound;
