import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import GestionCir from '../Phoenix/components/AnalistaRecuperos/gestioncir';
import AreaTrabajo from '../Phoenix/components/AnalistaRecuperos/areatrabajo';
import ConsultaSiniestros from '../Phoenix/components/AnalistaRecuperos/consultasiniestros';


export const AnalistaRecuperos = ({ match }) => (
    <Switch>
        <Route exact path={`${match.url}/gestion-cir`} component={GestionCir}/>
        <Route exact path={`${match.url}/consultasiniestros`} component={ConsultaSiniestros}/>
        <Route exact path={`${match.url}/area-trabajo`} component={AreaTrabajo}/>
    </Switch>
  ) 