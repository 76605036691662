export const nonprod =  {
    apiEndPoint: "https://nh8z31aqf9.execute-api.us-east-1.amazonaws.com/nonprod/",
    env:"nonprod",    
    adalConfig: {
        tenant: 'LibertyMutual.onmicrosoft.com',
        tenantId: '08a83339-90e7-49bf-9075-957ccd561bf1',
        clientId: '5ff877e2-7ae6-4716-8962-a2e06d6dacc3',// application id fro non-prod env
        endpoints: {
            api: 'https://libertymutual.onmicrosoft.com/157a6bd1-a58b-4541-bb9e-eac5ccd36984',
        },
        cacheLocation: 'localStorage',
    }
};