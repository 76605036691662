import React from "react";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { getInventory, getInspectionRecord } from "../../../api/inventory";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import InputBase from "@material-ui/core/InputBase";
import DyanmicTable from "../../../../BaseComponents/DyanmicTable/DyanmicTable";
import RegistroDeinspeccion from "./registroDeinspeccion";
import Loader from "../../../../BaseComponents/Loader/Loader";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";

let inventarioKeys={
  id: "",
  cantidad: "Cantidad",
  description: "Descripción",
  valor: "Valor",
  otros: "Otros"
}
export default class DescriptionDelRiesgo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inventarioData: undefined,
      showInventario: false,
      openDailog: false,
      registroDeInspeccion: undefined,
      showRegistroDeInspeccion: false,
      showLoader: false,
      fidelidadObjArray: [
        {
          alianzado: "Sin Descrip",
          rut: "0",
          cargo: "",
          "manejo Fondos": "Sin Descrip",
          "monto Aseq.": ""
        }
      ],
      showUbicacionRiesgo : false,
      showUbicacionRiesgoData:undefined
    };

    this.showRegistroDeInspeccion = this.showRegistroDeInspeccion.bind(this);
    this.showInventario = this.showInventario.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.showUbicacionRiesgo = this.showUbicacionRiesgo.bind(this)
    this.showUbicacionRamo19 = this.showUbicacionRamo19.bind(this)
    
  }
  componentDidMount() {
    this.setState({ data: this.props.data, itemData: this.props.itemData });
  }

  componentWillReceiveProps() {
    this.setState({ data: this.props.data, itemData: this.props.itemData });
  }

  showInventario() {
    this.showLoaderAction();
    this.setState({
      inventarioData: undefined,
      showRegistroDeInspeccion: false,
      showInventario: false,
      openDailog: false
    });
    const data = {
      policyNo: Number.parseInt(this.state.itemData.policyNo),
      itemNo: Number.parseInt(this.state.itemData.itemNo)
    };

    getInventory(data)
      .then(res => {
        if (res && res.data && !_.isEmpty(res.data)) {
          this.setState({
            inventarioData: res.data,
            showInventario: true,
            openDailog: true
          });
          this.showLoaderAction();
        } else {
          toast.error(
            res.msg ||
              "No Data found for Policy No : " +
                this.state.itemData.policyNo +
                " item No : " +
                this.state.itemData.itemNo,
            {
              position: toast.POSITION.TOP_RIGHT
            }
          );
          this.setState({ inventarioData: undefined });
          this.showLoaderAction();
        }
      })
      .catch(ex => {
        toast.error("Something went wrong.", {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  }
  showUbicacionRiesgo(){
    let showUbicacionRiesgoData=this.state.data;
    this.setState({
      showUbicacionRiesgo: true,
      openDailog: true,
      showUbicacionRiesgoData:showUbicacionRiesgoData
    });
  }
  showUbicacionRamo19(){

  }
  showRegistroDeInspeccion() {
    this.showLoaderAction();
    this.setState({
      inventarioData: undefined,
      showRegistroDeInspeccion: false,
      showInventario: false,
      openDailog: false
    });
    const data = {
      policyNo: Number.parseInt(this.state.itemData.policyNo),
      itemNo: Number.parseInt(this.state.itemData.itemNo) || 1
    };
    getInspectionRecord(data)
      .then(res => {
        if (res && res.data && !_.isEmpty(res.data)) {
          this.setState({
            registroDeInspeccion: res.data,
            showRegistroDeInspeccion: true,
            openDailog: true
          });
          this.showLoaderAction();
        } else {
          toast.error(
            res.msg ||
              "No Data found for Policy No : " +
                this.state.itemData.policyNo +
                " item No : " +
                this.state.itemData.itemNo,
            {
              position: toast.POSITION.TOP_RIGHT
            }
          );
          this.setState({ registroDeInspeccion: undefined });
          this.showLoaderAction();
        }
      })
      .catch(ex => {
        toast.error("Something went wrong.", {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  }

  handleClickOpen() {
    this.setState({ openDailog: true });
  }

  handleClose = () => {
    this.setState({ openDailog: false , showUbicacionRiesgo: false });
  };

  getInventoryTitle() {
    return (
      "Inventario ( poliza: " +
      this.state.itemData.policyNo +
      "  - Item: " +
      this.state.itemData.itemNo +
      " )"
    );
  }

  getRegistroDeInspeccionTitle() {
    return (
      "Registro De Inspeccion ( poliza: " +
      this.state.itemData.policyNo +
      "  - Item: " +
      this.state.itemData.itemNo +
      " )"
    );
  }

  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  render() {
    let dataObj = this.state.data;
    if (dataObj && dataObj.ramo == 13) {
      for (let key in dataObj) {
        dataObj[key] =
          typeof dataObj[key] == "string"
            ? dataObj[key].split(":")[1]
            : dataObj[key];
      }
    }
    if (dataObj && typeof dataObj.Glosa == "string") {
      dataObj["Glosa"] = [{ Glosa: dataObj.Glosa }];
    }
    let conductor = dataObj && dataObj.conductor;
    let vehiculo = dataObj && dataObj.vehiculo;
    const { fullScreen } = this.props;
    return (
      <div className="margin-top-30">
        {dataObj && dataObj != "None" ? (
          <Card className="Card-area">
            <div className="heading-style inline-blk">
              <span className="search-wrapper text-font heading-text-style">
                Descripción del riesgo
              </span>
              <div className="align-right"> 
              {dataObj.ramo == 16||dataObj.ramo == 18||dataObj.ramo == 21||dataObj.ramo == 25||dataObj.ramo == 39||dataObj.ramo == 35 ?
                <TextField InputLabelProps={{
                            shrink: true,
                        }}
                        id="standard-bare"
                        label="Uso/Destino:"
                        className="textField"
                        value={this.props.usoDestino}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                        style = {{width: 300}}
                      />
                      :null}
                <span className="search-wrapper">
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className="search-policy button-item-position"
                  onClick={this.showInventario.bind()}
                >
                  Inventario
                </Button>
              </span>
              </div>
            </div>
            {dataObj.ramo == 18 ||
            dataObj.ramo == 21 ||
            dataObj.ramo == 25 ||
            dataObj.ramo == 39 ? (
              <div className="case-8 fidelidad Space-around">
                <DyanmicTable
                  data={dataObj.Glosa}
                  id="Glosa"
                  align={"center"}
                  keyArray={Object.keys(dataObj.Glosa[0])}
                />
              </div>
            ) : null}
          {dataObj.ramo==23?
            <div className="usecase-11 equi movil">
              <div className="Space-around">
                {dataObj.Glosa?<>
                  <DyanmicTable data={dataObj.Glosa} align={"center"} id="Glosa" keyArray={Object.keys(dataObj.Glosa[0]) } />
                  <div className="margin-top-20px">
                  <TextField InputLabelProps={{
                            shrink: true,
                        }}
                        id="standard-bare"
                        label="Rut"
                        className="textField"
                        value={dataObj.Rut}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                        style = {{width: 303}}
                      />
                    </div>
                  </>
                :null}
                {dataObj.details?<>
                  <InputBase disabled className="label-style" defaultValue="Comuna" />
                  <DyanmicTable data={dataObj.details} id="alianzado" keyArray={Object.keys(dataObj.details[0])} />
                  </>:null}
              </div>
            </div>   
          :null}
            {dataObj.ramo == 26 ? (
              <div className="case-8 fidelidad Space-around">
                <DyanmicTable
                  data={dataObj.details}
                  id="details"
                  keyArray={Object.keys(dataObj.details[0])}
                />
              </div>
            ) : null}
            {dataObj.ramo == 16 ? (
              <div className="usecase-10 accidentes personalase3 ">
                <span className="Space-around">
                  <span className="search-wrapper margin-right-15">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Comuna"
                      className="textField"
                      value={dataObj.Comuna}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="search-wrapper">
                  <TextField InputLabelProps={{
    shrink: true,
}}
                    id="standard-bare"
                    label="Dirección"
                    className="textField"
                    value={dataObj.Direccion}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true
                    }}
                    style = {{width: 303}}
                  />
                </span>
                </span>
                <div className="Space-around" >
                  <DyanmicTable
                    data={dataObj.details}
                    id="Maquina"
                    keyArray={Object.keys(dataObj.details[0])}
                  />
                </div>
              </div>
            ) : null}
            {dataObj.ramo == 17 ? (
              <div className="usecase-10 accidentes personalase3 ">
                <span className="search-wrapper">
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    className="search-policy add-position-style"
                    onClick={this.showUbicacionRiesgo.bind()}
                  >
                    Ubicacion Riesgo
                  </Button>
                </span>
                <div className="Space-around">
                <DyanmicTable
                  data={dataObj.Glosa}
                  id="Glosa"
                  align={"center"}
                  keyArray={Object.keys(dataObj.Glosa[0])}
                />
                </div>
              </div>
            ) : null}
            {dataObj.ramo == 19 ? (
              <div className="usecase-10 accidentes personalase3 ">
                <span className="search-wrapper">
                  {/* <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    className="search-policy add-position-style"
                    onClick={this.showUbicacionRamo19.bind()}
                  >
                    Ubicacion Riesgo
                  </Button> */}
                </span>
                <div className="Space-around">                
                  <DyanmicTable
                    data={dataObj.details}
                    id="Glosa"
                    keyArray={Object.keys(dataObj.details[0])}
                  />
                </div>
              </div>
            ) : null}
            {dataObj.ramo == 35 ? (
              <div className="usecase-11 equi movil Space-around">
                <DyanmicTable
                  data={dataObj.Glosa}
                  id="alianzado"
                  align={"center"}
                  keyArray={Object.keys(dataObj.Glosa[0])}
                />
                <DyanmicTable
                  data={dataObj.details}
                  id="alianzado"
                  keyArray={Object.keys(dataObj.details[0])}
                />
              </div>
            ) : null}
            {dataObj.ramo == 9 ? (
              <div className="case-1">
                <div className="padding-10">
                  <fieldset className="custom-border">
                    <legend className="legend-box">Conductor</legend>
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="rut"
                        className="textField"
                        value={conductor.rut || " "}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </span>
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="eDad"
                        className="textField"
                        value={conductor.eDad || " "}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </span>
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Sexo"
                        className="textField"
                        margin="normal"
                        value={conductor.sexo || " "}
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </span>
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Tipo"
                        className="textField"
                        margin="normal"
                        value={conductor.tipo || " "}
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </span>
                  </fieldset>
                </div>
                <div className="padding-10">
                  <fieldset className="custom-border">
                    <legend className="legend-box">Vehículo</legend>
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Tipo"
                        className="textField"
                        value={vehiculo.tipo || " "}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </span>
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Patente"
                        className="textField"
                        value={vehiculo.patente || " "}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </span>
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Año"
                        className="textField"
                        margin="normal"
                        value={vehiculo.ano || " "}
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </span>
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Uso"
                        className="textField"
                        margin="normal"
                        value={vehiculo.uso || " "}
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </span>
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="N* Motor"
                        className="textField"
                        margin="normal"
                        value={vehiculo.nMotor || " "}
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </span>
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Marca"
                        className="textField"
                        margin="normal"
                        value={vehiculo.marca || " "}
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </span>
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Modelo"
                        className="textField"
                        margin="normal"
                        value={vehiculo.model || " "}
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </span>
                    <span className="Space-around">
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className="search-policy button-position button-item-position"
                        onClick={this.showRegistroDeInspeccion.bind()}
                      >
                        Registro de inspeccion
                      </Button>
                    </span>
                  </fieldset>
                </div>
              </div>
            ) : null}
            {dataObj.ramo == "Incendio-Robo" || dataObj.ramo == 1 || dataObj.ramo == 8 ? (
              <div className="use-case2">
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Materia"
                      className="textField"
                      value={dataObj.materia || " "}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 950}}
                    />
                  </span>
                </div>
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Calle"
                      className="textField"
                      value={dataObj.calle || " "}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Numero"
                      className="textField"
                      value={dataObj.numbre || " "}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Depto"
                      className="textField"
                      value={dataObj.depto || " "}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                </div>
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Comuna"
                      className="textField"
                      value={dataObj.comuna || " "}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Región"
                      className="textField"
                      value={dataObj.region || " "}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Const"
                      className="textField"
                      value={dataObj.const || " "}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                </div>
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Ubicacion"
                      className="textField"
                      value={dataObj.ubicacion || " "}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Periodo PxP"
                      className="textField"
                      value={dataObj.periodoPxP || " "}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Monto PxP"
                      className="textField"
                      value={dataObj.montoPxP || " "}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                </div>
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Uso Destino"
                      className="textField"
                      value={dataObj.usoDestino || " "}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 950}}
                    />
                  </span>
                </div>
              </div>
            ) : null}
            {dataObj.ramo == 11 ? (
              <div className="use-case3">
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Tipo Nave"
                      className="textField"
                      value={dataObj.tipoNave}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Nombre Nave"
                      className="textField"
                      value={dataObj.nombreNave}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Material Construccion"
                      className="textField"
                      value={dataObj.materialConstruccion}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                </div>
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Ano Fabricacion"
                      className="textField"
                      value={dataObj.anoFabrication}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Puerto Base"
                      className="textField"
                      value={dataObj.puetroBase}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Ton Grueso"
                      className="textField"
                      value={dataObj.tonGrueso}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                </div>
              </div>
            ) : null}
            {dataObj.ramo == 44 ? (
              <div className="use-case4 hogar">
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Cale"
                      className="textField"
                      value={dataObj.Calle}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Numero"
                      className="textField"
                      value={dataObj.Numero}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Depto"
                      className="textField"
                      value={dataObj.Depto}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                </div>
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Comuna"
                      className="textField"
                      value={dataObj.comuna}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Region"
                      className="textField"
                      value={dataObj.region}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Propiedad"
                      className="textField"
                      value={dataObj.Propiedad}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                </div>
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Mto Edificio"
                      className="textField"
                      value={dataObj.Mtoedificio}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Mto Contenido"
                      className="textField"
                      value={dataObj.Mtocontenido}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Sector"
                      className="textField"
                      value={dataObj.Sector}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                </div>
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Mto Robo 1"
                      className="textField"
                      value={dataObj.Mtorobo1}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Mto Robo 2"
                      className="textField"
                      value={dataObj.Mtorobo2}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Vivienda"
                      className="textField"
                      value={dataObj.vivienda}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                </div>
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Mto Cristales"
                      className="textField"
                      value={dataObj.Mtocristales}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Mto R. Civil"
                      className="textField"
                      value={dataObj.MtoRCivil}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Proteccion V."
                      className="textField"
                      value={dataObj.ProteccionV}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 140}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Alarma"
                      className="textField"
                      value={dataObj.Alarma}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 140}}
                    />
                  </span>
                </div>
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Mto Acc. Pers"
                      className="textField"
                      value={dataObj.MtoAccPers}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Tipo Contr"
                      className="textField"
                      value={dataObj.TipoContr}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Sistema Vigi"
                      className="textField"
                      value={dataObj.Sistemavigi}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      
                      style = {{width: 140}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Otros"
                      className="textField"
                      value={dataObj.Otros}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      
                      style = {{width: 140}}
                    />
                  </span>
                </div>
              </div>
            ) : null}
            {dataObj.ramo == 12 ? (
              <div className="use-case5 casco aereo">
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Tipo Nave"
                      className="textField"
                      value={dataObj.tipoNave}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Uso"
                      className="textField"
                      value={dataObj.uso}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Marca"
                      className="textField"
                      value={dataObj.marca}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                </div>
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Matricula"
                      className="textField"
                      value={dataObj.marticula}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Modelo"
                      className="textField"
                      value={dataObj.modelo}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Zona de Vuelo"
                      className="textField"
                      value={dataObj.zonadeVuelo}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                </div>
              </div>
            ) : null}
            {dataObj.ramo == 13 ? (
              <div className="use-case6 transporte terrestre">
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Modalidad:"
                      className="textField"
                      value={dataObj.Modalidad}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 950}}
                    />
                  </span>
                </div>
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Tipo Transporte:"
                      className="textField"
                      value={dataObj.TipoTransporte}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: dataObj.k==4?303: 465}}
                    />
                  </span>
                  {dataObj.k == 4 ? (
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Ano:"
                        className="textField"
                        value={dataObj.Ano}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                        style = {{width: 303}}
                      />
                    </span>
                  ) : null}
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Tipo Embalaje:"
                      className="textField"
                      value={dataObj.TipoEmbalaje}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: dataObj.k==4?303: 465}}
                    />
                  </span>
                </div>
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Referencia:"
                      className="textField"
                      value={dataObj.Referencia}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: dataObj.k==4?303: 465}}
                    />
                  </span>
                  {dataObj.k == 4 ? (
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Marca:"
                        className="textField"
                        value={dataObj.Marca}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                        style = {{width: 303}}
                      />
                    </span>
                  ) : null}
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Nombre Liquidador:"
                      className="textField"
                      value={dataObj.NombreLiquidador}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: dataObj.k==4?303: 465}}
                    />
                  </span>
                </div>
                <div>
                  {dataObj.k == 4 ? (
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Medio de Transporte:"
                        className="textField"
                        value={dataObj.MediodeTransporte}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                        style = {{width: 465}}
                      />
                    </span>
                  ) : null}
                  {dataObj.k == 3 ? (
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Empresa Transportista:"
                        className="textField"
                        value={dataObj.EmpresaTransportista}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                        style = {{width: 950}}
                      />
                    </span>
                  ) : null}
                  {dataObj.k == 4 ? (
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Modelo:"
                        className="textField"
                        value={dataObj.Modelo}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                        style = {{width: 465}}
                      />
                    </span>
                  ) : null}
                </div>
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Fecha Embarque:"
                      className="textField"
                      value={dataObj.FechaEmbarque}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: dataObj.k==4?250:303}}
                    />
                  </span>
                  {dataObj.k == 4 ? (
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Patente:"
                        className="textField"
                        value={dataObj.Patente}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                        style = {{width: 250}}
                      />
                    </span>
                  ) : null}
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Fecha Inicio de Viaje:"
                      className="textField"
                      value={dataObj.FechaIniciodeViaje}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: dataObj.k==4?250: 303}}
                    />
                  </span>
                  {dataObj.k == 4 || dataObj.k == 5 ? (
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Tipo:"
                        className="textField"
                        value={dataObj.Tipo}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                        style = {{width: dataObj.k==4? 140: 303}}
                      />
                    </span>
                  ) : null}
                </div>
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Materia Asegurada:"
                      className="textField"
                      value={dataObj.MateriaAsegurada}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  {dataObj.k == 3 || dataObj.k == 4 ? (
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Tasa:"
                        className="textField"
                        value={dataObj.Tasa}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                        style = {{width: 303}}
                      />
                    </span>
                  ) : null}
                  {dataObj.k == 4 || dataObj.k == 5 ? (
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Trayecto:"
                        className="textField"
                        value={dataObj.Trayecto}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                        style = {{width: 303}}
                      />
                    </span>
                  ) : null}
                  {dataObj.k == 4 ? (
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Responsabilidad Maxima:"
                        className="textField"
                        value={dataObj.ResponsabilidadMaxima}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                        style = {{width: 303}}
                      />
                    </span>
                  ) : null}
                  {dataObj.k == 3 ? (
                    <>
                      {dataObj.k == 5 ? (
                        <>
                          <span className="Space-around">
                            <TextField InputLabelProps={{
    shrink: true,
}}
                              id="standard-bare"
                              label="Tasa Ajuste:"
                              className="textField"
                              value={dataObj.TasaAjuste}
                              margin="normal"
                              variant="outlined"
                              InputProps={{
                                readOnly: true
                              }}
                            />
                          </span>
                          <span className="Space-around">
                            <TextField InputLabelProps={{
    shrink: true,
}}
                              id="standard-bare"
                              label="Prima Ajuste:"
                              className="textField"
                              value={dataObj.PrimaAjuste}
                              margin="normal"
                              variant="outlined"
                              InputProps={{
                                readOnly: true
                              }}
                            />
                          </span>
                          <span className="Space-around">
                            <TextField InputLabelProps={{
    shrink: true,
}}
                              id="standard-bare"
                              label="Prima Minimay Deposito:"
                              className="textField"
                              value={dataObj.PrimaMinimayDeposito}
                              margin="normal"
                              variant="outlined"
                              InputProps={{
                                readOnly: true
                              }}
                            />
                          </span>
                          <span className="Space-around">
                            <TextField InputLabelProps={{
    shrink: true,
}}
                              id="standard-bare"
                              label="Monto Maxpor Viaje:"
                              className="textField"
                              value={dataObj.MontoMaxporViaje}
                              margin="normal"
                              variant="outlined"
                              InputProps={{
                                readOnly: true
                              }}
                            />
                          </span>
                          <span className="Space-around">
                            <TextField InputLabelProps={{
    shrink: true,
}}
                              id="standard-bare"
                              label="Monto Est Anual:"
                              className="textField"
                              value={dataObj.MontoEstAnual}
                              margin="normal"
                              variant="outlined"
                              InputProps={{
                                readOnly: true
                              }}
                            />
                          </span>
                        </>
                      ) : null}
                      <span className="Space-around">
                        <TextField InputLabelProps={{
    shrink: true,
}}
                          id="standard-bare"
                          label="Carta de Porte:"
                          className="textField"
                          value={dataObj.CartadePorte}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            readOnly: true
                          }}
                          style = {{width: 303}}
                        />
                      </span>
                      <span className="Space-around">
                        <TextField InputLabelProps={{
    shrink: true,
}}
                          id="standard-bare"
                          label="Origen:"
                          className="textField"
                          value={dataObj.Origen}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            readOnly: true
                          }}
                          style = {{width: 465}}
                        />
                      </span>
                      {dataObj.k == 3 ?
                        <span className="Space-around">
                        <TextField InputLabelProps={{
    shrink: true,
}}
                          id="standard-bare"
                          label="Destino:"
                          className="textField"
                          value={dataObj.Destino}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            readOnly: true
                          }}
                          style = {{width: 465}}

                        />
                      </span>
                      :null }
                    </>
                  ) : null}
                </div>
              </div>
            ) : null}
            {dataObj.ramo == 14 || dataObj.ramo == 15 || dataObj.ramo == 48 ? (
              <div className="use-case7 transporte maritimo, areo, multi transporte">
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Tipo de Operacion"
                      className="textField"
                      value={dataObj.tra_tin_tiptra}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Embalaje"
                      className="textField"
                      value={dataObj.Embalaje}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Fecha Emb."
                      className="textField"
                      value={dataObj.FechaEmb}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                </div>
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Liquidador"
                      className="textField"
                      value={dataObj.Liquidador}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 465}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Referencia"
                      className="textField"
                      value={dataObj.Referencia}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 465}}
                    />
                  </span>
                </div>
                <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Transbordo"
                      className="textField"
                      value={dataObj.Transbordo}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Origen"
                      className="textField"
                      value={dataObj.Origen}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                  {dataObj.k == 3 ? (
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Destino"
                        className="textField"
                        value={dataObj.Destino}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                        style = {{width: 303}}
                      />
                    </span>
                  ) : null}
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Materia Asegurada"
                      className="textField"
                      value={dataObj.MatriaAsegurada}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                      style = {{width: 303}}
                    />
                  </span>
                </div>
              </div>
            ) : null}
          </Card>
        ) : null}

        {this.state.showInventario &&
        Object.keys(this.state.inventarioData).length ? (
          <Dialog
            fullScreen={fullScreen}
            open={this.state.openDailog}
            onClose={this.handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle
              id="responsive-dialog-title"
              className="dailog-title-style"
            >
              {this.getInventoryTitle()}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <DyanmicTable
                  data={this.state.inventarioData.inventario}
                  id="item"
                  keyArray={inventarioKeys}
                />
              </DialogContentText>
            </DialogContent>
          </Dialog>
        ) : null}

        {this.state.showRegistroDeInspeccion ? (
          <Dialog
            open={this.state.openDailog}
            onClose={this.handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth="true"
          >
            <div style={{ width: 1200 }}>
              <DialogTitle
                id="responsive-dialog-title"
                className="dailog-title-style"
              >
                {this.getRegistroDeInspeccionTitle()}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <RegistroDeinspeccion
                    data={this.state.registroDeInspeccion}
                  />
                </DialogContentText>
              </DialogContent>
            </div>
          </Dialog>
        ) : null}
        {this.state.showLoader ? <Loader /> : null}
        { this.state.showUbicacionRiesgo  ? 
        <Dialog  open={this.state.openDailog}  onClose={this.handleClose} aria-labelledby="responsive-dialog-title" maxWidth="true" maxHeight="true">
          <DialogContent>
              <div>
                <span className="Space-around">
                  <TextField InputLabelProps={{
    shrink: true,
}}
                    id="standard-bare"
                    label="Comuna"
                    className="textField"
                    value={this.state.showUbicacionRiesgoData.Comuna}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true
                    }}
                    style = {{width: 140}}
                  />
                </span>
                <span className="Space-around">
                  <TextField InputLabelProps={{
    shrink: true,
}}
                    id="standard-bare"
                    label="Direccion"
                    className="textField"
                    value={this.state.showUbicacionRiesgoData.Direccion}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true
                    }}
                    style = {{width: 400}}
                  />
                </span>
              </div>
          </DialogContent>
        </Dialog> : null }
        <ToastContainer />
      </div>
    );
  }
}
