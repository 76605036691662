import React from "react";
import TableComponet from "../../../BaseComponents/TableComponent/TableComponet";
import { translate, Trans } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  getCollectionByPolicyNo,
  getCollectionByDocumentNo
} from "../../api/collection";
import { ToastContainer, toast } from "react-toastify";
import _ from "lodash";
import "./CollectionsComponent.scss";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../../BaseComponents/Loader/Loader";
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import {
  collectionColumn,
  resumenColumns,
  PolizaDetailColumns,
  insertDataArray
} from "../../../Constants/collection";
import { Link } from "react-router-dom";

class CollectionsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enableSearch: true,
      collectionData: undefined,
      searchText: "",
      showLoader: false,
      showCollection: false,
      showResumen: false,
      showPolizaDetail: false,
      openDailog: false,
      isPaginationReq: true,
      isEnhancedTableHeadReq: true,
      showCollectionTable: true,
      showResumenTable: false,
      showCollectionDetailButton: false,
      collections: undefined,
      policyNo: undefined,
      polizaData: undefined,
      collectionSearch: true
    };

    this.showCollectionDetails = this.showCollectionDetails.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.resumenShow = this.resumenShow.bind(this);
    this.showPolizaDetail = this.showPolizaDetail.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.showCollectionDetailTable = this.showCollectionDetailTable.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    // let collectionSearch = true;
    // if (nextProps.location.pathname === "/documento") {
    //   collectionSearch = false;
    // }
    // this.setState({
    //   collectionSearch: collectionSearch,
    //   searchText: "",
    //   enableSearch: true,
    //   collectionData: undefined,
    //   showLoader: false,
    //   showCollection: false,
    //   showResumen: false,
    //   showPolizaDetail: false,
    //   openDailog: false,
    //   isPaginationReq: true,
    //   isEnhancedTableHeadReq: true,
    //   showCollectionTable: true,
    //   showResumenTable: false,
    //   showCollectionDetailButton: false,
    //   collections: undefined,
    //   policyNo: undefined,
    //   polizaData: undefined,
    //   showVolver: false
    // });
  }
  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    let policyNo = params.policyNo ? params.policyNo : params.documentoNo;
    let collectionSearch = true;

    if (this.props.location.pathname.includes("/documento")) {
      collectionSearch = false;
    }
    this.setState({
      collectionSearch: collectionSearch,
      searchText: ""
    });
    if (policyNo) {
      this.setState({ searchText: policyNo, showVolver: true });
      this.showCollectionDetails(policyNo, collectionSearch);
      this.setState({ enableSearch: false });
    }
  }
  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  resetSearch() {
    this.setState({
      collectionData: undefined,
      showClaim: false,
      searchText: ""
    });
  }

  handleChange(event) {
    const value = event.target.value;
    this.setState({ searchText: value });
    if (value > 0) {
      this.setState({ enableSearch: false });
    } else {
      this.setState({ enableSearch: true });
    }
  }

  resumenShow() {
    this.setState({
      showResumen: true,
      showPolizaDetail: false,
      openDailog: true,
      showResumenTable: true,
      showCollectionTable: false,
      showCollectionDetailButton: true
    });
  }

  showPolizaDetail() {
    this.setState({
      showPolizaDetail: true,
      showResumen: false,
      openDailog: true
    });
  }

  showCollectionDetailTable() {
    this.setState({
      showResumenTable: false,
      showCollectionTable: true,
      showCollectionDetailButton: false
    });
  }

  showCollectionDetails(policyNumber, collectionSearch) {
    this.showLoaderAction();
    this.setState({ collectionData: undefined });
    let policyNo = this.state.searchText || policyNumber;
    let collectionSearchValue=(collectionSearch===undefined)?this.state.collectionSearch:collectionSearch;
    if (
      !collectionSearchValue &&
      window.location.pathname.indexOf("/documento/") === -1
    ) {
      this.setState({
        collectionSearch: false
      });
      let documentoNo = this.state.searchText || policyNumber;
      this.props.history.push("/documento/" + documentoNo);
    } else if (
      collectionSearchValue &&
      window.location.pathname.indexOf("/collections/") === -1
    ) {
      let policyNo = this.state.searchText || policyNumber;
      this.props.history.push("/collections/" + policyNo);
    }else if(collectionSearch===undefined){
      let path=this.props.location.pathname.split("/");
      path[2]=policyNo;
      this.props.history.push(path.join("/"));
    }

    if (policyNo) {
      (collectionSearchValue
        ? getCollectionByPolicyNo(policyNo)
        : getCollectionByDocumentNo(policyNo)
      )
        .then(res => {
          if (res && res.data && !_.isEmpty(res.data)) {
            let collection = res.data.collection;
            const collection_data = _.cloneDeep(res.data.collection);
            let total = res.data.total;
            const polizaData = res.data.finalTab || [
              {
                poliza: policyNo,
                endoso: collection_data[0].endoso || "0",
                item: collection_data[0].item | "0",
                captial: total.amortizacionTotal
              }
            ];
            let collectionData = this.getInsertDataInCollection(
              total,
              collection,
              policyNo
            );
            this.setState({
              showClaim: true,
              collectionData: collectionData,
              collections: collection_data,
              polizaData: polizaData
            });
            // toast.success(res.msg||" Successfully Collection Data found", {position: toast.POSITION.TOP_RIGHT });
          } else {
            toast.error(
              res.msg || `No se encontraron datos de cobranza para la póliza`,
              { position: toast.POSITION.TOP_RIGHT }
            );
            this.setState({
              collectionData: undefined
            });
          }
          this.showLoaderAction();
        })
        .catch(ex => {
          console.error(ex);
          this.showLoaderAction();
          toast.error(`Something went wrong.`, {
            position: toast.POSITION.TOP_RIGHT
          });
        });
    }
  }

  getInsertDataInCollection(total, collection, policyNo) {
    let totalObj = total && total.length > 0 ? total[0] : {};
    var data = insertDataArray(totalObj);

    collection.push(data[0]);
    return collection;
  }

  handleClickOpen() {
    this.setState({ openDailog: true });
  }

  handleClose = () => {
    this.setState({ openDailog: false });
  };

  backToPreviousPage = () => {
    window.history.back();
  };

  render() {
    const { i18n } = this.props;
    let translateObj =
      (i18n.store.data[i18n.language] &&
        i18n.store.data[i18n.language].translations) ||
      i18n.store.data["es"].translations;
    const { fullScreen } = this.props;
    const polizaColumns = collectionColumn;
    const resumenColumn = resumenColumns;
    const PolizaDetail = ["poliza", "endoso", "item", "amortizacion"];
    const PolizaDetailColumn = PolizaDetailColumns;
    const route = {
      route: "/policy/",
      key: "poliza"
    };
    return (
      <main className="main-container">
        {this.state.showLoader ? <Loader /> : null}
        <div className="main-screen">
          <div className="search-wrapper">
            <TextField
              id="standard-search"
              label={
                this.state.collectionSearch
                  ? translateObj.policy_page.search_placeholder
                  : translateObj.policy_page.document_placeholder
              }
              type="search"
              className="text-field"
              margin="normal"
              value={this.state.searchText}
              onChange={this.handleChange}
              style={{ width: 250 }}
            />
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className="search-policy"
              disabled={this.state.enableSearch}
              onClick={this.showCollectionDetails.bind()}
            >
              {translateObj.policy_page.search_btn}
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className="search-policy"
              onClick={this.resetSearch.bind()}
            >
              {translateObj.policy_page.reset_btn}
            </Button>
            {this.state.showVolver ? (
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className="search-policy"
                onClick={this.backToPreviousPage}
              >
                {"Volver"}
              </Button>
            ) : null}
          </div>
        </div>
        {this.state.collectionData ? (
          <div>
            <div className="collection-button">
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className="search-policy collection-button-space"
                onClick={this.showPolizaDetail.bind()}
              >
                {"Ver Detalle Poliza"}
              </Button>
              <Link
                to={{
                  pathname: this.state.collectionSearch
                    ? `/collections/resumen/${this.state.searchText}`
                    : `/documento/resumen/${this.state.searchText}`
                }}
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className="search-policy collection-button-space"
                  onClick={this.resumenShow.bind()}
                >
                  {"Resumen"}
                </Button>
              </Link>
            </div>
          </div>
        ) : null}
        {this.state.collectionData && this.state.showCollectionTable ? (
          <DyanmicTableWithSorting
            data={this.state.collectionData}
            keyArray={polizaColumns}
            orderBy={polizaColumns[0]}
            pathData={{}}
            isEnhancedTableHeadReq={this.state.isEnhancedTableHeadReq}
            virticalScroll={true}
            tableMaxHeight={"400"}
          />
        ) : null}

        {this.state.showPolizaDetail && this.state.collectionData ? (
          <Dialog
            open={this.state.openDailog}
            onClose={this.handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <div style={{ width: 600, overflowX: "hidden" }}>
              <DialogTitle
                id="responsive-dialog-title"
                className="dailog-title-style"
              >
                {" "}
                [ Poliza : {this.state.polizaData[0].poliza} ]
              </DialogTitle>
              <DialogContent>
                <DyanmicTableWithSorting
                  data={this.state.polizaData}
                  keyArray={PolizaDetailColumn}
                  orderBy={PolizaDetailColumn[0]}
                  pathData={route}
                  isPaginationReq={true}
                  isEnhancedTableHeadReq={false}
                />
              </DialogContent>
            </div>
          </Dialog>
        ) : null}
        <ToastContainer />
      </main>
    );
  }
}

export default translate("translations")(CollectionsComponent);
