import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./DynamicTableWithCheckBox.scss";
import EnhancedTableHead from "./EnhancedTableHead";
import TablePagination from "@material-ui/core/TablePagination";
import Loader from "../Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import Checkbox from "@material-ui/core/Checkbox";


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: "100%"
  },
  tableWrapper: {
    overflowX: "auto"
  },
});

class DynamicTableWithCheckBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: this.props.orderBy || "nItem",
      page: 0,
      rowsPerPage: 20,
      rutData: undefined,
      showRutData: false,
      showLoader: false
    };
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };
  
  // showTableData(d) {
  //   this.setState({
  //     rutData: undefined
  //   });
  //   this.showLoaderAction();
  //   const Id = d && d["rut"];
  //   if (Id != undefined) {
  //     ObtenerelNumerodePolizaPorrutina(Id)
  //       .then(res => {
  //         if (
  //           res &&
  //           res.data &&
  //           !_.isEmpty(res.data.informaciónDeLaPolíticaDelUsuario)
  //         ) {
  //           scrollToBottom();
  //           this.showLoaderAction();
  //           this.setState({
  //             rutData: res.data.informaciónDeLaPolíticaDelUsuario,
  //             showRutData: true
  //           });
  //         } else {
  //           this.showLoaderAction();
  //           toast.error(res.msg || "No found for Rut Id : " + Id, {
  //             position: toast.POSITION.TOP_RIGHT
  //           });
  //           this.setState({
  //             rutData: undefined,
  //             showRutData: true
  //           });
  //         }
  //       })
  //       .catch(ex => {
  //         this.showLoaderAction();
  //         toast.error("Something went wrong", {
  //           position: toast.POSITION.TOP_RIGHT
  //         });
  //         console.log(ex);
  //       });
  //   }
  // }

  // showLoaderAction() {
  //   let showLoader = this.state.showLoader;
  //   this.setState({ showLoader: !showLoader });
  // }

  getClassName(row) {
    if (row == "rut") {
      return "centered-align hyper-link-color";
    }
    return "centered-align";
  }

  render() {
    const { order, orderBy } = this.state;
    const { classes } = this.props;
    const data = this.props.data;
    const id = this.props.id;
    const rows = this.props.keyArray;
    const showRutData = this.state.showRutData;
    const rutData = this.state.rutData;
    const showLoader = this.state.showLoader;
    const checkBoxColumn = ["cont", "aseg", "deud", "agen"];
    // const keys = [  "poliza","ramo","vDesde","vHasta","estado","origen"]
    
   
    return (
      <div>
        <div>
          {data ? (
            <Paper className={classes.root}>
              <div className={classes.tableWrapper +" max-height-" + this.props.tableMaxHeight}>
              <Table className={classes.table}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                  rowCount={data.length}
                  keyArray={rows}
                  virticalScroll={this.props.virticalScroll}
                />
                <TableBody>
                  {stableSort(data, getSorting(order, orderBy))
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )
                    .map((d, index) => (
                      <TableRow key={index} className="cursor-pointer">
                        {(Array.isArray(rows)?rows: Object.keys(rows)).map((row, index) => (
                          <TableCell
                            align="center"
                            onClick={() => this.props.showTableData(d)}
                            key={index}
                            className={this.getClassName(row)}
                          >
                            {checkBoxColumn.includes(row) ? (
                              <Checkbox
                                checked={d[row]}
                                color="primary"
                                value={d[row].toString()}
                              />
                            ) : (
                              d[row]
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              </div>
              <TablePagination
                labelRowsPerPage=""
                rowsPerPageOptions={[]}
                component="div"
                count={data.length}
                rowsPerPage={this.state.rowsPerPage}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`} 
                page={this.state.page}
                backIconButtonProps={{
                  "aria-label": "Previous Page"
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page"
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Paper>
          ) : null}
        </div>
      </div>
    );
  }
}

DynamicTableWithCheckBox.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DynamicTableWithCheckBox);
