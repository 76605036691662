import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { translate, Trans } from "react-i18next";
import Button from "@material-ui/core/Button";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import _ from 'lodash';
import { NoOptionsMessage, inputComponent, Control, Option, Placeholder, SingleValue, ValueContainer, MultiValue, Menu } from '../../../Constants/dailog'
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import Select from 'react-select';
import ExcelDownload from '../../../utils/ExcelDownloadComponent'
import moment from "moment";
import 'moment/min/locales';
import SearchIcon from "@material-ui/icons/CloudDownload";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker
} from "material-ui-pickers";
import Grid from '@material-ui/core/Grid';
import './CajaPenta.scss'
import { api } from '../../api/caja-penta'
import TextField from "@material-ui/core/TextField";
import { scrollToBottom } from '../../../utils/scrollDown'
import { consultaDeTicket } from './constants'
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";

const localeMap = {
  es: esLocale,
  en: enLocale
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 150,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  grid: {
    width: "58%"
  }
});


const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};


class ConsultaDeTickets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: false,
      showLoader: false,
      isPaginationReq: true,
      isEnhancedTableHeadReq: true,
      defaultRowPerPage: 5,
      expandSelectWidth: false,
      dropDownData: {},
      estadoTicket: undefined,
      usuario: undefined,
      nombreSucursalDetails: undefined,
      startDate: null,
      endDate: null,
      documento: undefined,
      poliza: undefined,
      showForm: false,
      excelDownlaod:false
    };

    this.resetSearch = this.resetSearch.bind(this);
    this.disableSearchBtn = this.disableSearchBtn.bind(this);
    this.showTableData = this.showTableData.bind(this);
    this.handleChangePoliza = this.handleChangePoliza.bind(this)
    this.handleChangeDocumento = this.handleChangeDocumento.bind(this)
  }

  componentDidMount() {
    this.showLoaderAction()
    try {
      api.getDiscretaDetails1()
        .then((res) => {
          if (res && res.data && !_.isEmpty(res.data)) {
            let { estadoTicket, usuario, nombreSucursalDetails } = res.data;
            this.setState({
              estadoTicketData: estadoTicket,
              nombreSucursalDetailsData: nombreSucursalDetails,
              usuarioData: usuario,
              showForm: true
            })
          } else {
            toast.error(res.msg, { position: toast.POSITION.TOP_RIGHT });
          }
          this.showLoaderAction();
        })
        .catch((ex) => {
          toast.error("Something went wrong.", { position: toast.POSITION.TOP_RIGHT });
          this.showLoaderAction();
        })

    } catch (ex) {
      toast.error("Something went wrong.", { position: toast.POSITION.TOP_RIGHT });
    }

    const { match: { params } , location : {search}     } = this.props;
    let query = ""
    let keyValue = {}
    if(search){
        query =  search.slice(1,search.length).split("&")
        _.forEach(query,(q)=>{
            let splitString =  q.split("=")
            if(splitString){
              let key = splitString[0];
              let value = splitString[1];
              keyValue[key] = value 
            }           
        })
    }
    
    let keys = _.keys(keyValue)
    _.forEach(keys, (key)=>{
      if(key == "oficina-label") {
        this.setState({nombreSucursalDetails : {value : keyValue["oficina-value"] , label : keyValue["oficina-label"]  }})
      }else if(key == "usuario-label") {
        this.setState({usuario : {value : keyValue["usuario-value"] , label : keyValue["usuario-label"]  }})
      }else if(key == "estadoTicket-label") {
        this.setState({estadoTicket : {value : keyValue["estadoTicket-value"] , label : keyValue["estadoTicket-label"]  }})
      }else if(key == "policyNo") {
        this.setState({policy : keyValue.policyNo})
      }else if(key == "documento") {
        this.setState({documento : keyValue.documento})
      }else if(key == "startDate") {
        let startDate =  moment(keyValue.startDate).format("YYYY/MM/DD")
        this.setState({startDate : new Date(startDate)})
      }else if(key == "endDate") {
        let endDate =  moment(keyValue.endDate).format("YYYY/MM/DD")
        this.setState({endDate : endDate})
      }
    })

  }

  handleDateChange = name => value => {
    let expandSelectWidth = (value && value.label.length > 18) ? true : false;
    this.setState({ [name]: value, expandSelectWidth: expandSelectWidth });
  };

  customFilterEstadoTicket = (text) => {
    let filterSuggestion = this.state.estadoTicketData.filter((element) => {
      return (element.descripcion.toString().indexOf(text) > -1 || element.descripcion.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1)
    }).slice(0, 200);
    this.setState({ estadoTicketList: filterSuggestion });
  }

  customFilterUsuario = (text) => {
    let filterSuggestion = this.state.usuarioData.filter((element) => {
      return (element.nombreUsuario.toString().indexOf(text) > -1 || element.nombreUsuario.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1)
    }).slice(0, 200);
    this.setState({ usuarioList: filterSuggestion });
  }

  customFilterNombreSucursalDetails = (text) => {
    let filterSuggestion = this.state.nombreSucursalDetailsData.filter((element) => {
      return (element.nombreSucursal.toString().indexOf(text) > -1 || element.nombreSucursal.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1)
    }).slice(0, 200);
    this.setState({ nombreSucursalDetailsList: filterSuggestion });
  }


  handleChangeEndDate = (chageDate, event) => {
    this.setState({
      endDate: chageDate
    });
  };

  handleChangeStartDate = (chageDate, event) => {
    this.setState({
      startDate: chageDate
    });
  };

  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  handleChangePoliza(event) {
    const value = event.target.value;
    this.setState({ poliza: value });
  }

  handleChangeDocumento(event) {
    const value = event.target.value;
    this.setState({ documento: value });
  }


  resetSearch() {
    this.setState({
      documento: "",
      poliza: "",
      startDate: null,
      endDate: null,
      usuario: null,
      estadoTicket: null,
      nombreSucursalDetails: null,
      data:undefined
    })

  }


  showTableData() {
    const { documento, poliza } = this.state;
    let startDate = this.state.startDate ? moment(this.state.startDate).format("YYYYMMDD") : "";
    let endDate = this.state.endDate ? moment(this.state.endDate).format("YYYYMMDD") : "";
    let usuario = this.state.usuario ? this.state.usuario.value : "";
    let estadoTicket = this.state.estadoTicket ? this.state.estadoTicket.value : "";
    let oficina = this.state.nombreSucursalDetails ? this.state.nombreSucursalDetails.value : "";

    const data = {
      "oficina": oficina,
      "documento": documento,
      "poliza": poliza,
      "usuario": usuario,
      "estado": estadoTicket,
      "fechdesde": startDate,
      "fechHasta": endDate
    }
    this.getDiscretaDetails2(data);

  }

  getDiscretaDetails2(data) {
    this.setState({ data: "" })
    this.showLoaderAction()
    try {
      api.getDiscretaDetails2(data)
        .then((res) => {
          if (res && !_.isEmpty(res.data)) {
            const { vistaDeTicketDetalia: data } = res.data
            this.setState({ data: data })
            scrollToBottom();
          } else {
            toast.error(res.msg, { position: toast.POSITION.TOP_RIGHT });
            this.setState({ data: undefined })
          }
          this.showLoaderAction();
        })
        .catch((ex) => {
          toast.error("Something went wrong.", { position: toast.POSITION.TOP_RIGHT });
          this.showLoaderAction();
        })

    } catch (ex) {
      toast.error("Something went wrong.", { position: toast.POSITION.TOP_RIGHT });
      this.showLoaderAction();
    }
  }


  disableSearchBtn() {
    let bln = true;
    const { documento, poliza } = this.state;

    let startDate = this.state.startDate ? moment(this.state.startDate).format("YYYYMMDD") : "";
    let endDate = this.state.endDate ? moment(this.state.endDate).format("YYYYMMDD") : "";
    let usuario = this.state.usuario ? this.state.usuario.value : "";
    let estadoTicket = this.state.estadoTicket ? this.state.estadoTicket.value : "";
    let oficina = this.state.nombreSucursalDetails ? this.state.nombreSucursalDetails.value : "";


    if ((_.isEmpty(startDate) && _.isEmpty(endDate) && _.isEmpty(usuario) && _.isEmpty(estadoTicket) && _.isEmpty(oficina) &&
      _.isEmpty(documento) && _.isEmpty(poliza)) || (startDate > endDate)) {
      bln = true
    } else {
      bln = false
    }

    return bln;
  }

  getDocumentData=(data)=>{
    this.showLoaderAction();
    api.getDocumentoDataNumber(data).then((respose)=>{
      this.showLoaderAction();
      if(respose && respose.data&& respose.data.dettaleDeTicket){
        this.setState({
          excelDownlaod:true,
          dettaleDeTicket:respose.data.dettaleDeTicket,
          currentNTciket:data.nroTicket
        })
        setTimeout(() => {
          this.setState({
            excelDownlaod:false
          })
        }, 1000);
      }else{
        this.setState({
          dettaleDeTicket:undefined,
          currentNTciket:""
        })
        toast.error(respose.msg, { position: toast.POSITION.TOP_RIGHT });
      }
    }).catch((err)=>{
      toast.error("Tiempo de espera agotado. Inténtalo de nuevo", { position: toast.POSITION.TOP_RIGHT });
      this.showLoaderAction();
    })
  }

  clickLink =(data, row)=>{
    if(row==="nroTicket"){
      this.getDocumentData(data)
    }else{
      let  {oficina, anoContable, mesContable, tipoDocumento, correlativoint}  = data ;
      let { nombreSucursalDetails, documento,  poliza,  usuario,  estadoTicket,   startDate, endDate} = this.state;
      let query="?";
      if(nombreSucursalDetails) {
        query +=  `oficina-value=${nombreSucursalDetails.value}&oficina-label=${nombreSucursalDetails.label}&`;
      }
      if(documento) {
        query +=  `documento=${documento}&`;
      }
      if(poliza) {
        query +=  `poliza=${poliza}&`;
      }
      if(usuario) {
        query +=  `usuario-label=${usuario.label}&usuario-value=${usuario.value}&`;
      }
      if(estadoTicket) {
        query +=  `estadoTicket-label=${estadoTicket.label}&estadoTicket-value=${estadoTicket.value}&`;
      }
      if(startDate) {
        query +=  `startDate=${ moment(this.state.startDate).format("YYYYMMDD")}&`;
      }
      if(endDate) {
        query +=  `endDate=${ moment(this.state.endDate).format("YYYYMMDD")}`;
      }

      this.props.history.push(`/caja-penta/consulta-de-tickets/detalle-de-comprobante/${oficina}/${anoContable}/${mesContable}/${tipoDocumento}/${correlativoint}${query}`)
    }
  }


  render() {

    const { i18n } = this.props;
    let translateObj = (i18n.store.data[i18n.language] && i18n.store.data[i18n.language].translations) || i18n.store.data["es"].translations;
    const { classes, theme } = this.props;


    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
          width: '150px',
        },
      }),
    };
    let columnNames = consultaDeTicket;
    if(this.state.excelDownlaod){
      var newFormatData= this.state.dettaleDeTicket.map((singleObj)=>{
        return Object.values(singleObj);
      })
      var finalData=[{
        columns: ["Documento"],
        data:newFormatData
        }
      ];
      var sheetName="sheet1";
    }
    return (
      <main className="main-container">
        <div>
          {this.state.showLoader ? <Loader /> : null}
        </div>
        {this.state.showForm ?
          <div>
            <fieldset className="custom-border">
              <legend className="legend-box">Consultar Mandatos Cliente</legend>
              <div>
                <div className="drop-down-menu">
                  <label className="margin-top-right-form">Nº Póliza</label>
                  <TextField InputLabelProps={{ shrink: true }}
                    id="standard-bare"
                    className="textField"
                    value={this.state.poliza}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: false,
                    }}
                    style={{ width: 220 }}
                    onChange={this.handleChangePoliza}
                  />
                  <label className="margin-top-right-form margin-left-form">Nº Documento</label>
                  <TextField InputLabelProps={{ shrink: true }}
                    id="standard-bare"
                    className="textField"
                    value={this.state.documento}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: false,
                    }}
                    style={{ width: 220 }}
                    onChange={this.handleChangeDocumento}
                  />
                </div>
              </div>
              <div>
                <div className="drop-down-menu">
                  <label className="margin-top-30px width-118px">Fecha Desde</label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap["es"]} >
                    <Grid
                      container
                      className={classes.grid}
                      justify="space-around"
                      style={{ width: "40%" }}
                    >
                      <DatePicker
                        margin="normal"
                        value={this.state.startDate}
                        onChange={this.handleChangeStartDate}
                        format="dd/MM/yyyy"
                        placeholder="dd/mm/yyyy"
                        disableOpenOnEnter
                        minDateMessage={"Rango fecha erroneo"}
                        invalidDateMessage	= {"Rango fecha erroneo"}
                        keyboard
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <label className="margin-top-30px width-118px">Fecha Hasta</label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap["es"]}>
                    <Grid
                      container
                      className={classes.grid}
                      justify="space-around"
                      style={{ width: "40%" }}
                    >
                      <DatePicker
                        margin="normal"
                        value={this.state.endDate}
                        onChange={this.handleChangeEndDate}
                        minDate={this.state.startDate}
                        minDateMessage={translateObj.policy_page.date_error_msg}
                        invalidDateMessage={"Rango fecha erroneo"}
                        format="dd/MM/yyyy"
                        placeholder="dd/mm/yyyy"
                        disableOpenOnEnter
                        keyboard
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div>
                <div className="drop-down-menu-1">
                  <label className="margin-top-right-form">Estado ticket</label>
                  <div className="select-style">
                    <Select
                      classes={classes}
                      styles={selectStyles}
                      width="220"
                      options={this.state.estadoTicketData.map(d => ({
                        value: d.descripcion,
                        label: d.descripcion,
                      }))}
                      components={components}
                      value={this.state.estadoTicket}
                      onChange={this.handleDateChange('estadoTicket')}
                      placeholder="--Seleccione--"
                      isClearable
                      onInputChange={this.customFilterEstadoTicket}
                    />
                  </div>
                  <label className="margin-top-right-form margin-left-form-Usuario">Usuario  </label>
                  <div className="select-style">
                    <Select
                      classes={classes}
                      styles={selectStyles}
                      options={this.state.usuarioData.map(d => ({
                        value: d.rutUsuario,
                        label: d.nombreUsuario,
                      }))}
                      width="220"
                      components={components}
                      value={this.state.usuario}
                      onChange={this.handleDateChange('usuario')}
                      placeholder="--Seleccione--"
                      isClearable
                      onInputChange={this.customFilterUsuario}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="drop-down-menu-2">
                  <label className="margin-top-right-form">Oficina</label>
                  <div className="select-style">
                    <Select
                      classes={classes}
                      styles={selectStyles}
                      width="220"
                      options={this.state.nombreSucursalDetailsData.map(d => ({
                        value: d.nombreSucursal,
                        label: d.nombreSucursal,
                      }))}
                      components={components}
                      value={this.state.nombreSucursalDetails}
                      onChange={this.handleDateChange('nombreSucursalDetails')}
                      placeholder="--Seleccione--"
                      isClearable
                      onInputChange={this.customFilternombreSucursalDetails}
                    />
                  </div>
                </div>
              </div>
              <div className="search-wrapper select-style center-div">
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className="search-policy"
                  disabled={this.disableSearchBtn()}
                  onClick={this.showTableData.bind()}
                >
                  {translateObj.policy_page.accept_btn}
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className="search-policy"
                  onClick={this.resetSearch.bind()}
                >
                  {translateObj.policy_page.reset_btn}
                </Button>
              </div>
            </fieldset>
            <div>
              <div>
                {this.state && this.state.data ?
                  <div>
                    <h4 className="caja-h3">Vista de tickets del día</h4>
                    <DyanmicTableWithSorting
                      data={this.state.data}
                      keyArray={columnNames}
                      orderBy={columnNames[0]}
                      isPaginationReq={true}
                      isEnhancedTableHeadReq={true}
                      defaultRowPerPage={20}
                      passClick = {this.clickLink}
                      virticalScroll={true}
                      tableMaxHeight={"450"}
                    />
                  </div>
                  : null
                }
              </div>
            </div>
          </div>

          : null
        }

        {
          this.state.excelDownlaod ?  <ExcelDownload excelData={finalData} sheetName={sheetName} excelName={this.state.currentNTciket}/> : null
        }
        <ToastContainer />
      </main>
    );
  }
}

ConsultaDeTickets.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default translate("translations")(withStyles(styles, { withTheme: true })(ConsultaDeTickets));
