import configEnv from "../../config/config";
import axios from "axios";
const AdalConfig =configEnv.adalConfig;
import { authContext } from '../../utils/adalConfig';
import jwt from "jsonwebtoken";

const instance = axios.create();

let updatedToken=localStorage.getItem("adal.idtoken");
instance.interceptors.request.use((config) => {
  return new Promise((resolve, reject) => {
    let decodedObj=jwt.decode(updatedToken||localStorage.getItem("adal.idtoken"));
    let expireTime=decodedObj.exp;
    console.log("ession", expireTime, updatedToken)
    if(Date.now() >= expireTime * 1000){
      console.log("ession expired")
      authContext.logOut();
      localStorage.clear();
      resolve(config);
    }else{
      authContext.acquireToken(AdalConfig.endpoints.api, (message, token, msg) => {
        if (!!token) {
          let decodedObj=jwt.decode(token);
          decodedObj.exp=Math.floor(Date.now()/1000) + 600;
          localStorage.setItem("adal.expired", decodedObj.exp);
          var newToken = jwt.sign(decodedObj,'vscgaS&4TR21W56`34&');
          config.headers.Authorization = newToken;
          updatedToken = newToken;
          resolve(config)
        } else {
          resolve(config)
        }
      })
    }
  })
}, function(error) {
  return Promise.reject(error)
})


export const config = {
  headers: {
    token: localStorage.getItem("adal.idtoken")
  }
};

export const base_url =
  configEnv.apiEndPoint ||
  "https://lrigoocw6l.execute-api.us-east-1.amazonaws.com/dev/";

export const axiousInstance=instance;
