import React from "react";
import TextField from "@material-ui/core/TextField";
import CreatableSelect from "react-select/lib/Creatable";
import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import "./DetaileDeCheques.scss";
import {
  getConsultaDeCheques,
  getCuentaCorriente
} from "../../api/CajaPagadora";
import Loader from "../../../BaseComponents/Loader/Loader";
import { Link } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { scrollToBottom } from "../../../utils/scrollDown";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import Select from "react-select";
import { getCheckList } from "../../api/CajaPagadora";
import { ToastContainer, toast } from "react-toastify";
import CheckList from "./CheckList";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";

const localeMap = {
  es: esLocale,
  en: enLocale
};

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto"
  },
  progress: {
    margin: theme.spacing.unit * 2
  },
  grid: {
    width: "80%"
  }
});

class DetaileDeCheques extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rutBeneficiario: "",
      rutBeneficiarioRange: "",
      rangoDeMontoCheque: "",
      rangoDeMontoChequeRng: "",
      nCheque: "",
      nNomina: "",
      nombreBeneficiario: "",
      nombreSucursal: "-Seleccion-",
      valueSucursal: "",
      tipoCheque: "-Seleccion-",
      tipoReferencia: "-Seleccion-",
      valTipoReferencia: "",
      banco: "",
      bancoVal: "",
      nCuentaCorriente: "",
      valNCuentaCorriente: "",
      estado: "-Seleccion-",
      ValEstado: "",
      concepto: "-Seleccion-",
      ValConcepto: "",
      showConsultaDeChequesDet: false,
      showLoader: false,
      detaileDeChequesList: undefined,
      startDate: null,
      endDate: null,
      CheckListData: undefined,
      emptyState: false,
      numeroReferencia: "",
      valtipoCheque: "",
      enableBuskar: false
    };
  }

  handleChangeRutBeneficiario = event => {
    const value = event.target.value;
    this.setState({
      rutBeneficiario: value
    });
  };

  handleChangeRutBeneficiarioRange = event => {
    const value = event.target.value;
    this.setState({
      rutBeneficiarioRange: value
    });
  };

  handleChangeRangoDeMontoCheque = event => {
    const value = event.target.value;
    this.setState({
      rangoDeMontoCheque: value
    });
  };

  handleChangeRangoDeMontoChequeRng = event => {
    const value = event.target.value;
    this.setState({
      rangoDeMontoChequeRng: value
    });
  };

  handleChangeNCheque = event => {
    const value = event.target.value;
    this.setState({
      nCheque: value
    });
  };

  handleChangenNomina = event => {
    const value = event.target.value;
    this.setState({
      nNomina: value
    });
  };

  handleChangeNombreBeneficiario = event => {
    const value = event.target.value;
    this.setState({
      nombreBeneficiario: value
    });
  };

  handleChangeStartDate = (chageDate, event) => {
    this.setState({
      startDate: chageDate
    });
    this.enableSearchButton();
  };

  handleChangeEndDate = (chageDate, event) => {
    this.setState({
      endDate: chageDate
    });
    this.enableSearchButton();
  };

  enableSearchButton = () => {
    let btn = false;
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate < this.state.endDate
    ) {
      btn = true;
    }
    this.setState({
      enableBuskar: btn
    });
  };

  showLoaderAction = () => {
    let showLoader = this.state.showLoader;
    this.setState({
      showLoader: !showLoader
    });
  };

  handleChangennumeroReferencia = event => {
    this.setState({
      numeroReferencia: event.target.value
    });
  };

  // selectHandleChangeSucursal

  selectHandleChangeSucursal = actionMeta => {
    if (actionMeta) {
      this.setState({
        nombreSucursal: actionMeta.label,
        valueSucursal: actionMeta.value
      });
    }
    console.groupEnd();
  };

  selectHandleChangeTipoCheque = actionMeta => {
    if (actionMeta) {
      this.setState({
        tipoCheque: actionMeta.label,
        valtipoCheque: actionMeta.value
      });
    }
    console.groupEnd();
  };

  selectHandleChangeBanco = actionMeta => {
    if (actionMeta) {
      this.setState({
        banco: actionMeta.label,
        bancoVal: actionMeta.value
      });
      let reqObj = {
        codigoBanco: actionMeta.value
      };
      if (actionMeta.value) {
        this.getCuentaCorrienteFun(reqObj);
      } else {
        this.setState({
          detaileDeChequesList: {
            ...this.state.detaileDeChequesList,
            cuentaCorrienteList: [
              {
                value: "",
                label: "-Seleccion-"
              }
            ]
          },
          nCuentaCorriente: "-Seleccion-",
          valNCuentaCorriente: ""
        });
      }
    }
    console.groupEnd();
  };

  selectHandleChangeNCuentaCorriente = actionMeta => {
    if (actionMeta && actionMeta.value) {
      this.setState({
        nCuentaCorriente: actionMeta.value,
        valNCuentaCorriente: actionMeta.value
      });
    }
    console.groupEnd();
  };

  selectHandleChangeTipoReferencia = actionMeta => {
    console.log("actionMeta", actionMeta.value);
    console.log("actionMeta.label", actionMeta.label);
    if (actionMeta) {
      this.setState({
        tipoReferencia: actionMeta.label,
        valTipoReferencia: actionMeta.value
      });
    }
    console.groupEnd();
  };
  selectHandleChangeEstado = actionMeta => {
    if (actionMeta) {
      this.setState({
        estado: actionMeta.label,
        ValEstado: actionMeta.value
      });
    }
    console.groupEnd();
  };

  selectHandleChangeConcepto = actionMeta => {
    if (actionMeta) {
      this.setState({
        concepto: actionMeta.label,
        ValConcepto: actionMeta.value
      });
    }
    console.groupEnd();
  };

  /* function to get getConsultaDeChequesFun */

  getConsultaDeChequesFun = () => {
    this.showLoaderAction();
    getConsultaDeCheques().then(response => {
      if (response && response.data) {
        let sucursalObj = {
          value: "",
          label: "-Seleccione-"
        };
        response.data.sucursal.unshift(sucursalObj);
        response.data.tipoCheque.unshift(sucursalObj);
        response.data.banco.unshift(sucursalObj);
        response.data.estado.unshift(sucursalObj);
        response.data.concepto.unshift(sucursalObj);
        response.data.tipoReferencia.unshift(sucursalObj);
        response.data.cuentaCorrienteList.unshift(sucursalObj);

        let con = _.find(response.data.cuentaCorrienteList, [
          "label",
          "13254-03"
        ]);

        let bancoVal = _.find(response.data.banco, ["label", "BANCO DE CHILE"]);

        this.setState({
          showConsultaDeChequesDet: true,
          detaileDeChequesList: response.data,
          nCuentaCorriente: con.label,
          valNCuentaCorriente: con.label,
          banco: bancoVal.label,
          bancoVal: bancoVal.value
        });
        this.showLoaderAction();
      } else {
        toast.error(response.msg || "No data found", {
          position: toast.POSITION.TOP_RIGHT
        });
        this.setState({
          showConsultaDeChequesDet: false,
          detaileDeChequesList: undefined
        });
        this.showLoaderAction();
      }
    });
  };

  /* function to get getCuentaCorrienteFun */

  getCuentaCorrienteFun = reqObj => {
    this.showLoaderAction();
    getCuentaCorriente(reqObj).then(response => {
      if (response && response.data && response.data.cuentaCorrienteList) {
        console.log(
          "response.data.cuentaCorrienteList----->",
          response.data.cuentaCorrienteList
        );

        this.setState({
          showConsultaDeChequesDet: true,
          detaileDeChequesList: {
            ...this.state.detaileDeChequesList,
            cuentaCorrienteList: response.data.cuentaCorrienteList
          },
          nCuentaCorriente: response.data.cuentaCorrienteList[0].label,
          valNCuentaCorriente: response.data.cuentaCorrienteList[0].label
        });
        this.showLoaderAction();
      } else {
        toast.error(response.msg || "No data found", {
          position: toast.POSITION.TOP_RIGHT
        });
        this.setState({
          showConsultaDeChequesDet: false,
          detaileDeChequesList: undefined
        });
        this.showLoaderAction();
      }
    });
  };

  resetSearch = () => {
    this.setState({
      pageNo:1,
      CheckListData: undefined,
      emptyState: false,
      rutBeneficiario: "",
      rutBeneficiarioRange: "",
      rangoDeMontoCheque: "",
      rangoDeMontoChequeRng: "",
      nCheque: "",
      nNomina: "",
      nombreBeneficiario: "",
      nombreSucursal: "-Seleccion-",
      valueSucursal: "",
      tipoCheque: "-Seleccion-",
      valtipoCheque: "",
      tipoReferencia: "-Seleccion-",
      valTipoReferencia: "",
      banco: "-Seleccione-",
      bancoVal: "",
      nCuentaCorriente: "-Seleccione-",
      valNCuentaCorriente: "",
      estado: "-Seleccione-",
      ValEstado: "",
      concepto: "-Seleccion-",
      ValConcepto: "",
      numeroReferencia: "",
      startDate: null,
      endDate: null
    });
  };



  componentDidMount() {
    this.getConsultaDeChequesFun();
  }
  getCheckListDetail = (pageNo) => {
    this.showLoaderAction();
    let reqObj = {};
    reqObj.checkNo = this.state.nCheque;
    reqObj.sucursal = this.state.valueSucursal;
    reqObj.tipoCheque = this.state.valtipoCheque;
    reqObj.codigoBanco = this.state.bancoVal;
    reqObj.cuentaCorriente = this.state.valNCuentaCorriente;
    reqObj.estadoChequeNo = this.state.ValEstado;
    reqObj.codOrigen = this.state.ValConcepto;
    reqObj.codRef = this.state.valTipoReferencia;
    if (this.state.startDate) {
      reqObj.fechaEmisionStartDate = moment(this.state.startDate).format(
        "YYYY-MM-DD 00:00:00"
      );
    } else {
      reqObj.fechaEmisionStartDate = this.state.startDate;
    }
    if (this.state.endDate) {
      reqObj.fechaEmisionEndDate = moment(this.state.endDate).format(
        "YYYY-MM-DD 00:00:00"
      );
    } else {
      reqObj.fechaEmisionEndDate = this.state.endDate;
    }
    reqObj.montoChequeAmtStart = this.state.rangoDeMontoCheque;
    reqObj.montoChequeAmtEnd = this.state.rangoDeMontoChequeRng;
    reqObj.nombreBeneficiario = this.state.nombreBeneficiario;
    reqObj.nomina = this.state.nNomina;
    reqObj.numeroReferncia = this.state.numeroReferencia;
    reqObj.rutBeneficiario = this.state.rutBeneficiario;
    reqObj.dvRutBenef = this.state.rutBeneficiarioRange;
    if(typeof pageNo!="number")
    {
      this.setState({
        CheckListData: undefined,
        emptyState: false,
        pageNo:1
      });
    }
    let pageNumber=(typeof pageNo==="number")?pageNo:undefined;
    getCheckList(reqObj, pageNumber)
      .then(res => {
        scrollToBottom();
        if (res.data && res.data.checkList) {
          let dataToDisplay = res.data.checkList;
          let emptyState = res.data.checkList.length > 0 ? false : true;
          let modifiedData = dataToDisplay;
          this.setState({
            CheckListData: modifiedData,
            emptyState: emptyState,
            totalElement:res.data.totalRecords||this.state.totalElement,
            pageNo:res.data.pageNo||1
          });
        } else {
          toast.error(res.msg || "Tiempo de espera agotado. Inténtalo de nuevo", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        this.showLoaderAction();
      })
      .catch(ex => {
        toast.error("Tiempo de espera agotado. Inténtalo de nuevo", {
          position: toast.POSITION.TOP_RIGHT
        });
        this.showLoaderAction();
        console.log(ex);
      });
  };

  errorMessage = (error, value) => {
    console.log(error);
    console.log(value);
  };
  fetchNextData=(page)=>{
    this.getCheckListDetail(page);
  }
  render() {
    const { classes, theme } = this.props;
    let showLoader = this.state.showLoader;
    return (
      <main className={classes.content + " home-page"}>
        <div className="mainComponent">
          <Card className="Card-area box-show-none overflowClass">
            <fieldset className="custom-border">
              <legend className="legend-box">Consulta General</legend>
              {showLoader ? <Loader /> : null}
              {this.state.showConsultaDeChequesDet ? (
                <div className="container">
                  <div className="row porClientRowClass">
                    <div className="col-sm-3">
                      <span className="label-class">Sucursal</span>
                      <Select
                        maxMenuHeight={180}
                        isSearchable={true}
                        value={{
                          label: this.state.nombreSucursal,
                          value: this.state.valueSucursal
                        }}
                        onChange={this.selectHandleChangeSucursal}
                        options={this.state.detaileDeChequesList.sucursal.map(
                          option => ({
                            value: option.value,
                            label: option.label
                          })
                        )}
                      />
                    </div>
                    <div className="col-sm-3">
                      <span className="label-class">Tipo cheque</span>
                      <Select
                        maxMenuHeight={180}
                        isSearchable={true}
                        value={{
                          label: this.state.tipoCheque,
                          value: this.state.valtipoCheque
                        }}
                        onChange={this.selectHandleChangeTipoCheque}
                        options={this.state.detaileDeChequesList.tipoCheque.map(
                          option => ({
                            value: option.value,
                            label: option.label
                          })
                        )}
                      />
                    </div>
                    <div className="col-sm-3">
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={localeMap["es"]}
                      >
                        <Grid
                          container
                          className={classes.grid}
                          justify="space-around"
                        >
                          <DatePicker
                            InputLabelProps={{
                              shrink: true
                            }}
                            margin="normal"
                            label="Rango fecha emisión"
                            placeholder="dd/mm/yyyy"
                            disableOpenOnEnter
                            keyboard
                            value={this.state.startDate}
                            onChange={this.handleChangeStartDate}
                            format="dd/MM/yyyy"
                            invalidDateMessage={"Rango fecha erroneo"}
                            minDateMessage={"Rango fecha erroneo"}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-sm-3">
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={localeMap["es"]}
                      >
                        <Grid
                          container
                          className={classes.grid}
                          justify="space-around"
                        >
                          <DatePicker
                            InputLabelProps={{
                              shrink: true
                            }}
                            disableOpenOnEnter
                            keyboard
                            margin="normal"
                            label="Rango fecha emisión"
                            placeholder="dd/mm/yyyy"
                            format="dd/MM/yyyy"
                            minDate={this.state.startDate}
                            minDateMessage={
                              "Fecha de finalización mayor que la fecha de inicio"
                            }
                            value={this.state.endDate}
                            onChange={this.handleChangeEndDate}
                            invalidDateMessage={"Rango fecha erroneo"}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                  <Divider />
                  <div className="row porClientRowClass">
                    <div className="col-sm-4">
                      <span className="label-class">Banco</span>
                      <Select
                        maxMenuHeight={180}
                        isSearchable={true}
                        value={{
                          label: this.state.banco,
                          value: this.state.bancoVal
                        }}
                        onChange={this.selectHandleChangeBanco}
                        options={this.state.detaileDeChequesList.banco.map(
                          option => ({
                            value: option.value,
                            label: option.label
                          })
                        )}
                      />
                    </div>
                    <div className="col-sm-4">
                      <span className="label-class">N° Cuenta Corriente</span>
                      <Select
                        maxMenuHeight={180}
                        isSearchable={true}
                        value={{
                          label: this.state.nCuentaCorriente,
                          value: this.state.nCuentaCorriente
                        }}
                        onChange={this.selectHandleChangeNCuentaCorriente}
                        options={this.state.detaileDeChequesList.cuentaCorrienteList.map(
                          option => ({
                            value: option.label,
                            label: option.label
                          })
                        )}
                      />
                    </div>
                    <div className="col-sm-4">
                      <span className="label-class">Estado</span>
                      <Select
                        maxMenuHeight={180}
                        isSearchable={true}
                        value={{
                          label: this.state.estado,
                          value: this.state.ValEstado
                        }}
                        onChange={this.selectHandleChangeEstado}
                        options={this.state.detaileDeChequesList.estado.map(
                          option => ({
                            value: option.value,
                            label: option.label
                          })
                        )}
                      />
                    </div>
                  </div>
                  <Divider />
                  <div className="row porClientRowClass">
                    <div className="col-sm-4">
                      <TextField
                        id="custom-css-standard-input"
                        label="Rut Beneficiario"
                        type="search"
                        className="porClenteInp"
                        margin="normal"
                        value={this.state.rutBeneficiario}
                        onChange={this.handleChangeRutBeneficiario}
                        style={{ width: 150 }}
                      />
                      <span className="minus-sign margin-right-10px">-</span>
                      <TextField
                        id="custom-css-standard-input"
                        label=" "
                        type="search"
                        className="porClenteInp"
                        margin="normal"
                        value={this.state.rutBeneficiarioRange}
                        onChange={this.handleChangeRutBeneficiarioRange}
                        style={{ width: 60 }}
                      />
                    </div>
                    <div className="">
                      <TextField
                        id="custom-css-standard-input"
                        label="Rango de monto cheque"
                        type="search"
                        className="porClenteInp"
                        margin="normal"
                        value={this.state.rangoDeMontoCheque}
                        onChange={this.handleChangeRangoDeMontoCheque}
                        style={{ width: 180 }}
                      />
                      <span className="minus-sign a-style margin-right-10px">
                        a
                      </span>
                      <TextField
                        id="custom-css-standard-input"
                        label=" "
                        type="search"
                        className="porClenteInp"
                        margin="normal"
                        value={this.state.rangoDeMontoChequeRng}
                        onChange={this.handleChangeRangoDeMontoChequeRng}
                        style={{ width: 180 }}
                      />
                    </div>
                    <div className="">
                      <TextField
                        id="custom-css-standard-input"
                        label="N° Cheque"
                        type="search"
                        className="porClenteInp"
                        margin="normal"
                        value={this.state.nCheque}
                        onChange={this.handleChangeNCheque}
                        style={{ width: 180 }}
                      />
                    </div>
                  </div>
                  <Divider />
                  <div className="row porClientRowClass">
                    <div className="col-sm-4">
                      <span className="label-class">Concepto</span>
                      <Select
                        maxMenuHeight={180}
                        isSearchable={true}
                        value={{
                          label: this.state.concepto,
                          value: this.state.ValConcepto
                        }}
                        onChange={this.selectHandleChangeConcepto}
                        options={this.state.detaileDeChequesList.concepto.map(
                          option => ({
                            value: option.value,
                            label: option.label
                          })
                        )}
                      />
                    </div>
                    <div className="col-sm-4">
                      <span className="label-class">Tipo referencia</span>
                      <Select
                        maxMenuHeight={180}
                        isSearchable={true}
                        value={{
                          label: this.state.tipoReferencia,
                          value: this.state.valTipoReferencia
                        }}
                        onChange={this.selectHandleChangeTipoReferencia}
                        options={this.state.detaileDeChequesList.tipoReferencia.map(
                          option => ({
                            value: option.value,
                            label: option.label
                          })
                        )}
                      />
                    </div>
                    <div className="col-sm-4">
                      <TextField
                        id="custom-css-standard-input"
                        label="Número referencia"
                        type="search"
                        margin="normal"
                        value={this.state.numeroReferencia}
                        onChange={this.handleChangennumeroReferencia}
                      />
                    </div>
                  </div>
                  <Divider />
                  <div className="row porClientRowClass">
                    <div className="col-sm-3">
                      <TextField
                        id="custom-css-standard-input"
                        label="N° Nómina"
                        type="search"
                        className="porClenteInp"
                        margin="normal"
                        value={this.state.nNomina}
                        onChange={this.handleChangenNomina}
                      />
                    </div>
                    <div className="col-sm-3">
                      <TextField
                        id="custom-css-standard-input"
                        label="Nombre Beneficiario"
                        type="search"
                        className="porClenteInp"
                        margin="normal"
                        value={this.state.nombreBeneficiario}
                        onChange={this.handleChangeNombreBeneficiario}
                      />
                    </div>
                    <div className="col">
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className="search-policy resetButtonClass"
                        onClick={this.resetSearch}
                      >
                        {"Limpiar"}
                      </Button>
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className="search-policy resetButtonClass"
                        disabled={this.state.enableBuskar}
                        onClick={this.getCheckListDetail.bind()}
                      >
                        {"Consulta"}
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null}
            </fieldset>
          </Card>
        </div>

        {showLoader ? <Loader /> : null}
        {this.state.CheckListData ? (
          <CheckList
            CheckListData={this.state.CheckListData}
            totalElement={this.state.totalElement}
            emptyState={this.state.emptyState}
            fetchNextData={this.fetchNextData}
            pageNo={this.state.pageNo}
          />
        ) : null}
        <ToastContainer />
      </main>
    );
  }
}

export default withStyles(styles)(DetaileDeCheques);
