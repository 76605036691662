import { config, base_url, axiousInstance } from './index';
let axios=axiousInstance;

class Cajapenta  {

    constructor(){

    }

    getDropDownDetails() {
      const data = {};
      const url =  base_url + "caja-penta/getDropDownDetails"
      return axios
          .post(url, data, config)
          .then(res => {
          return res.data;
          })
          .catch(error => {
          console.log("error " + error);
          return error;
      });        
    }

    getDropDownData(data) {
      const url =  base_url + "caja-penta/getDropDownData"
      return axios
            .post(url, data, config)
            .then(res => {
            return res.data;
            })
            .catch(error => {
            console.log("error " + error);
            return error;
        });
    }

    getTicketDetails(numeroCarga){
      let data = {
        "numeroCarga": parseInt(numeroCarga)
      };
      const url =  base_url + "caja-penta/getTicketDetails";
      return axios
            .post(url, data, config)
            .then(res => {
            return res.data;
            })
            .catch(error => {
            console.log("error " + error);
            return error;
        });
    }

    getDiscretaDetails1(){
      let data = {};
      const url =  base_url + "caja-penta/getDiscretaDetails1";
      return axios
            .post(url, data, config)
            .then(res => {
            return res.data;
            })
            .catch(error => {
            console.log("error " + error);
            return error;
        });
    }
    getDiscretaDetails2(data){
      const url =  base_url + "caja-penta/getDiscretaDetails2";
      return axios
            .post(url, data, config)
            .then(res => {
            return res.data;
            })
            .catch(error => {
            console.log("error " + error);
            return error;
        });
    }
    getDiscretaDetails3(data){
      const url =  base_url + "caja-penta/getDiscretaDetails3";
      return axios
            .post(url, data, config)
            .then(res => {
            return res.data;
            })
            .catch(error => {
            console.log("error " + error);
            return error;
        });
    }
    getDiscretaDetails4(data){
      const url =  base_url + "caja-penta/getDiscretaDetails4";
      return axios
            .post(url, data, config)
            .then(res => {
            return res.data;
            })
            .catch(error => {
            console.log("error " + error);
            return error;
        });
    }
    getDiscretaDetails5(data){
      const url =  base_url + "caja-penta/getDiscretaDetails5";
      return axios
            .post(url, data, config)
            .then(res => {
            return res.data;
            })
            .catch(error => {
            console.log("error " + error);
            return error;
        });
    }

    getExcelDetails(data){
      const url =  base_url + "caja-penta/getExcelDetails";
      let body={"ticketNumber":data}
      return axios
            .post(url, body, config)
            .then(res => {
            return res.data;
            })
            .catch(error => {
            throw error;
        });
    }
getExcelDownloadData() {
    let baseUrl="https://phoenix-np.liberty.cl/static/media/cajaPentaData.json";
   return axios.get(baseUrl)
        .then(res => {
          return res.data;
        })
        .catch(error => {
        console.log(error);
        return error;
      });
}

getDocumentoDataNumber(data){
  const url =  base_url + "caja-penta/getDiscretaDetails6";
  return axios
        .post(url, data, config)
        .then(res => {
        return res.data;
        })
        .catch(error => {
        throw error;
    });
}


}

export const api = new Cajapenta();

