import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { translate, Trans } from "react-i18next";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {
  getByPersonDetail,
  ObtenerelNumerodePolizaPorrutina
} from "./../../api/policy";
import { ToastContainer, toast } from "react-toastify";
import FormLabel from "@material-ui/core/FormLabel";
import { withStyles } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import _ from "lodash";
import DynamicTableWithCheckBox from "../../../BaseComponents/DynamicTableWithCheckBox/DynamicTableWithCheckBox";
import Loader from "../../../BaseComponents/Loader/Loader";
import "./Buskar.scss";
import { scrollToBottom } from "../../../utils/scrollDown";
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";

const styles = theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing.unit * 3
  },
  group: {
    margin: `$({theme.spacing.unit})px 0`
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  }
});

const radioButtonItem = {
  Rut: 1,
  Nombre: 2,
  NumerodeNegocio: 3,
  SoloAgente: 4
};

let removeHyphen = rutNumber => {
  return parseInt(rutNumber ? rutNumber.replace(/-/g, "") : rutNumber);
};

class Buskar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      personDetail: undefined,
      soloAgent: false,
      rut: null,
      persona: null,
      negotiatorID: null,
      blnPersonDetail: false,
      showLoader: false,
      radioSelected: "",
      inputFilled: true,
      inputValue: "",
      selectedRadioButton: "Rut",
      searchButtonEnbl: false,
      filterSelected:"1"
    };
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    const { policyNo, rut } = params;
    if (policyNo && rut) {
      console.log("Inside did mount on policy number");
      this.setState({
        rut: this.state.inputValue,
        persona: null,
        negotiatorID: null,
        soloAgent: false,
        inputFilled: true,
        searchButtonEnbl: false,
        inputValue: rut
      });
      this.getByPersonDetail(rut);
    }
  }

  showLoaderAction = () => {
    let showLoader = this.state.showLoader;
    this.setState({
      showLoader: !showLoader
    });
  };

  getByPersonDetail = rut => {
    console.log("Inside getByPersonDetail", typeof rut);
    let data = {
      rut: typeof rut !== "object" ? rut : removeHyphen(this.state.rut),
      persona: this.state.persona,
      soloAgent: this.state.soloAgent,
      negotiatorID: this.state.negotiatorID
    };
    this.showLoaderAction();
    getByPersonDetail(data)
      .then(res => {
        this.showLoaderAction();
        if (res && !_.isEmpty(res.data)) {
          this.setState({ personDetail: res.data, blnPersonDetail: true, rutData:undefined });
          if (typeof rut !== "object") {
            this.showTableData({ rut: rut });
          }
        } else {
          this.setState({ personDetail: undefined, blnPersonDetail: false, rutData:undefined });
          toast.error(res.msg || "No data found for Rut.", {
            position: toast.POSITION.TOP_RIGHT
          });
          this.setState({ personDetail: undefined, blnPersonDetail: false });
        }
      })
      .catch(ex => {
        toast.error("Something went wrong.", {
          position: toast.POSITION.TOP_RIGHT
        });
        this.showLoaderAction();
      });
  };

  handleChangeRadioButton = event => {
    let value = event.target.value;
    // this.setState.inputFilled = true;
    // this.setState.selectedRadioButton = value;
    this.setState({
      inputFilled: true,
      selectedRadioButton: value
    });
    if (radioButtonItem[value] === 1) {
      this.setState({
        rut: this.state.inputValue,
        persona: null,
        negotiatorID: null,
        soloAgent: false,
        inputFilled: true,
        searchButtonEnbl: false,
        inputValue: this.state.inputValue
      });
    } else if (radioButtonItem[value] === 2) {
      if (_.isString(value)) {
        this.setState({
          persona: this.state.inputValue,
          rut: null,
          negotiatorID: null,
          soloAgent: false,
          inputFilled: true,
          searchButtonEnbl: false,
          inputValue: this.state.inputValue
        });
      }
    } else if (radioButtonItem[value] === 3 && value !== "") {
      this.setState({
        negotiatorID: null,
        rut: null,
        negotiatorID: parseInt(this.state.inputValue),
        soloAgent: false,
        inputFilled: true,
        searchButtonEnbl: false,
        inputValue: this.state.inputValue
      });
    } else if (radioButtonItem[value] === 4) {
      this.setState({
        negotiatorID: null,
        rut: null,
        negotiatorID: null,
        soloAgent: true,
        inputFilled: false,
        searchButtonEnbl: true,
        inputValue: ""
      });
    }
  };

  handleChangeInputValue = event => {
    let value = event.target.value;
    if (value === "") {
      this.setState({
        searchButtonEnbl: false,
        inputValue: value || ""
      });
    } else if (this.state.selectedRadioButton === "Rut") {
      console.log("Inside rut condition", value);
      this.setState({
        rut: value,
        persona: null,
        negotiatorID: null,
        soloAgent: false,
        inputFilled: true,
        inputValue: value,
        searchButtonEnbl: true
      });
    } else if (this.state.selectedRadioButton === "Nombre") {
      if (_.isString(value)) {
        this.setState({
          persona: value,
          rut: null,
          negotiatorID: null,
          soloAgent: false,
          inputFilled: true,
          inputValue: value,
          searchButtonEnbl: true
        });
      }
    } else if (
      this.state.selectedRadioButton === "NumerodeNegocio" &&
      value !== ""
    ) {
      this.setState({
        negotiatorID: null,
        rut: null,
        negotiatorID: parseInt(value),
        soloAgent: false,
        inputFilled: true,
        inputValue: value,
        searchButtonEnbl: true
      });
    } else if (this.state.selectedRadioButton === "SoloAgente") {
      this.setState({
        negotiatorID: null,
        rut: null,
        negotiatorID: null,
        soloAgent: true,
        inputFilled: false,
        inputValue: "",
        searchButtonEnbl: true
      });
    }
  };

  resetSearch = () => {
    this.setState({
      soloAgent: false,
      rut: null,
      persona: null,
      negotiatorID: null,
      personDetail: undefined,
      blnPersonDetail: false,
      radioSelected: "",
      inputFilled: true,
      inputValue: "",
      searchButtonEnbl: false,
      rutData: undefined,
      filterSelected:"1"
    });
  };

  showTableData = d => {
    this.showLoaderAction();
    const Id = d && d["rut"];
    if (Id != undefined) {
      ObtenerelNumerodePolizaPorrutina(Id, this.state.filterSelected)
        .then(res => {
          if (
            res &&
            res.data &&
            !_.isEmpty(res.data.informaciónDeLaPolíticaDelUsuario)
          ) {
            this.setState({
              rutData: res.data.informaciónDeLaPolíticaDelUsuario,
              showRutData: true,
              searchRutId: Id
            });
            scrollToBottom();
          } else {
            toast.error(res.msg || "No found for Rut Id : " + Id, {
              position: toast.POSITION.TOP_RIGHT
            });
            this.setState({
              rutData: undefined,
              showRutData: false
            });
          }
          this.showLoaderAction();
        })
        .catch(ex => {
          this.showLoaderAction();
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_RIGHT
          });
          console.log(ex);
        });
    }
  };

  clickOnPolicy = data => {
    let { poliza: policyNo } = data;
    let { searchRutId } = this.state;
    this.props.history.push(`/policy/${policyNo}/${searchRutId}`);
  };
  handleRadioChange=event=>{
    this.setState({filterSelected:event.target.value})
  }

  render() {
    const { i18n } = this.props;
    const { classes } = this.props;
    let translateObj =
      (i18n.store.data[i18n.language] &&
        i18n.store.data[i18n.language].translations) ||
      i18n.store.data["es"].translations;
    const data =
      this.state.personDetail &&
      this.state.personDetail.informaciónDeLaPolíticaDelUsuario;
    const keys = {
      rut: "rut",
      dv: "dv",
      nombre: "nombre",
      cont: "Contratante",
      aseg: "Asegurado",
      deud: "Deudor",
      agen: "Agente"
    };
    const showLoader = this.state.showLoader;
    const pathData = {
      route: "/policy/",
      key: "poliza"
    };

    const keysForSorting = {
      poliza: "Poliza",
      ramo: "Ramo",
      vDesde: "Inicio de vigencia",
      vHasta: "Fin de vigencia",
      estado: "Estado",
      origen: "Origen"
    };

    return (
      <div className="main-container">
        <div className="search-wrapper">
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Seleccione el filtro</FormLabel>
            <RadioGroup
              aria-label="buscargroup"
              name="buscargroup"
              className={classes.group}
              value={this.state.selectedRadioButton}
              onChange={this.handleChangeRadioButton}
              row={true}
            >
              <FormControlLabel
                value="Rut"
                control={<Radio color="primary" />}
                label="Rut"
              />
              <FormControlLabel
                value="Nombre"
                control={<Radio color="primary" />}
                label="Nombre"
              />
              <FormControlLabel
                value="SoloAgente"
                control={<Radio color="primary" />}
                label="Sólo Agente"
              />
              <FormControlLabel
                value="NumerodeNegocio"
                control={<Radio color="primary" />}
                label="Número de Negocio"
              />
            </RadioGroup>
          </FormControl>
          <div>
            <TextField
              disabled={!this.state.inputFilled}
              id="standard-search"
              label="Rut, Nombre (Persona), Nro Negocio"
              margin="normal"
              className="text-field"
              value={this.state.inputValue}
              onChange={this.handleChangeInputValue}
              style={{ width: 400 }}
            />
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className="search-policy"
              onClick={this.getByPersonDetail}
              disabled={!this.state.searchButtonEnbl}
            >
              {translateObj.policy_page.search_btn}
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className="search-policy"
              onClick={this.resetSearch}
            >
              {translateObj.policy_page.reset_btn}
            </Button>
          </div>
        </div>
        {showLoader ? <Loader /> : null}
        {this.state.blnPersonDetail &&
        this.state.personDetail &&
        this.state.personDetail.informaciónDeLaPolíticaDelUsuario ? (
          <>
        <FormLabel component="legend">Buscar Como</FormLabel>
        <RadioGroup
          aria-label="Gender"
          name="gender1"
          className={classes.group}
          value={this.state.filterSelected}
          onChange={this.handleRadioChange}
          row={true}
        >
          <FormControlLabel value="1" control={<Radio color="primary"/>} label="Contratante" />
          <FormControlLabel value="2" control={<Radio color="primary"/>} label="Asegurado" />
          <FormControlLabel value="3" control={<Radio color="primary"/>} label="Deudor" />
        </RadioGroup>
          <DynamicTableWithCheckBox
            data={data}
            keyArray={keys}
            orderBy={keys[0]}
            virticalScroll={true}
            tableMaxHeight={"450"}
            showTableData={this.showTableData}
          /></>
        ) : null}
        <div>
          {this.state.showRutData && this.state.rutData ? (
            <DyanmicTableWithSorting
              data={this.state.rutData}
              keyArray={keysForSorting}
              orderBy={keysForSorting[0]}
              // pathData={pathData}
              virticalScroll={true}
              isEnhancedTableHeadReq={true}
              tableMaxHeight={"400"}
              passClick={this.clickOnPolicy}
            />
          ) : null}
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default translate("translations")(withStyles(styles)(Buskar));
