import React from "react";
// import axios from "axios";
import moment from "moment";
import { config, base_url, axiousInstance } from './index';
let axios=axiousInstance;

export function getTotalBrokerReference() {
  // url :  https://lrigoocw6l.execute-api.us-east-1.amazonaws.com/dev/getTotalBrokerReference 

  return axios
    .post(base_url + "getTotalBrokerReference", {})
    .then(res => {
      return res && res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}


export function getSiniestrosDetails(siniestrosNo) {
  let data = {
    "siniestrosNo": siniestrosNo,//15095390 
  };

  return axios
    .post(base_url + "gestion/getConsultaSiniestros", data, config)
    .then(res => {
      return res && res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });

}

export function getDropdownGestionCir(){
    return axiousInstance
    .post(base_url + "gestion/getGestionCirDropDownDetails", {}, config)
    .then(res => {
      return res && res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}
export function getGestionCirSearchResult(body){
  return axios
  .post(base_url + "gestion/getGestionSearchData", body, config)
  .then(res => {
    return res && res.data;
  })
  .catch(error => {
    console.log("error " + error);
    return error;
  });
}

export function getAreatrabajoDropdown(){ 
  return axios
  .post(base_url + "gestion/getAbogadosExternosDropdownDetails", {}, config)
  .then(res => {
    return res && res.data;
  })
  .catch(error => {
    console.log("error " + error);
    return error;
  });

}
export function getSiniestrosDetailsForAreatrabajo(siniestrosNo,fechaStart,fechaEnd,patente, estadoLiquidacion ) {
  const post = {
    "siniestroNumber": siniestrosNo,//15132984
    "fechaStart": (fechaStart && moment(fechaStart).format("YYYYMMDD"))||"",
    "fechaEnd": (fechaEnd && moment(fechaEnd).format("YYYYMMDD"))||"",
    "patente": patente,
    "estadoLiquidacion": estadoLiquidacion && estadoLiquidacion.value
  }
  return axios.post(base_url + "gestion/getabogadosexternos", post, config)
    .then(response => {
      return response && response.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}