import React from "react";

class ProtectedComponents extends React.Component {
    constructor(props) {
        super(props);
        this.state={
          
        }
      }

    componentWillMount(){
        const {
            match: { params }
        } = this.props;
        let downloadPath = params.downloadPath;
        if(downloadPath.includes(".xlsx") || downloadPath.includes(".pdf")){
            let fullPath=window.location.origin+"/static/downloads/"+downloadPath;
            window.open(
                fullPath,
                "_blank"
            )
        }
    }

  render() {
    return (
        <>
        <main className="main-container"></main>
        </>
    );
  }
}

export default ProtectedComponents;

