import React from "react";
import "./Headings.scss";

export const H1 = props => {

	return (
			<h2 {...props} className={" h1 "+ props.className} >{props.children}</h2>
	);
};


export const H2 = props => {

	return (
			<h2 {...props} className={" h2 "+props.className} >{props.children}</h2>
	);
};

export const B1 = props => {

	return (
			<h3 {...props} className={"b1 "+props.className }>{props.children}</h3>
	);
};

export const B2 = props => {

	return (
			<h4 {...props} className={ "b2 "+props.className}>{props.children}</h4>
	);
};
