export const production =  {
    apiEndPoint:"https://21gn89hr89.execute-api.us-east-1.amazonaws.com/prod/",
    env:"production",
    adalConfig: {
        tenant: 'LibertyMutual.onmicrosoft.com',
        tenantId: '08a83339-90e7-49bf-9075-957ccd561bf1',
        clientId: '9740c1bc-9729-4114-bf01-8d57910e3f59',// application id fro production env
        endpoints: {
            api: 'https://libertymutual.onmicrosoft.com/34ddebb7-ef25-4876-ad01-4ab62b4a998d',
        },
        cacheLocation: 'localStorage',
    }
  };
