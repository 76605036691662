import React from 'react';
import PropTypes from 'prop-types';
// import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Divider from "@material-ui/core/Divider";
import Typography from '@material-ui/core/Typography';
import { translate, Trans } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';

import TextField from '@material-ui/core/TextField';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import {
    MuiPickersUtilsProvider,
    DatePicker
} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import DyanmicTableWithSorting from "./../../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting"
import {limitesTabFiveKeys} from "../../../../Constants/reaseguradoresKeys";
import EmptyState from "../../../../BaseComponents/EmptyState/EmptyState";

class LimitesPorZona extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showLimitesPorZona: true,
            limitePorEvento:""

        };
    }

    handleChangeLimitePorEvento = event => {
        const value = event.target.value;
        this.setState({
            limitePorEvento: value
        });
    };
    goToPreviousPage = () => {
        this.props.goToClasesDeContrato(3)
    };
    submitForm=()=>{
        this.props.goToClasesDeContrato(5);
    }
    render() {
        let tableData=this.props.data
        const showLimitesPorZona = this.state.showLimitesPorZona;
        return (
            <div className="tab-class">
                    <Card style={{ marginTop: "25px" }} className="Card-area box-show-none">
                    {tableData ? (
                        <DyanmicTableWithSorting
                            data={tableData}
                            keyArray={limitesTabFiveKeys}
                            orderBy={limitesTabFiveKeys[0]}
                            virticalScroll={true}
                            tableMaxHeight={"450"}
                            hideColoumn={["clase","tipo"]}
                        />               
                    ) : (
                        <div className={"content"}>
                            <EmptyState
                                emoticon={"🔍"}
                                message={"No se encontraron datos"}
                            />
                        </div>
                    )}
                    <div className="reso-btn-group">
                        <div className="btn">
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                onClick={this.goToPreviousPage}
                                className="search-policy pull-right resetButtonClass">Volver</Button>
                        </div>
                        <div className="btn" >
                            <Button variant="contained" size="medium" color="primary" className="search-policy pull-right resetButtonClass" onClick={this.submitForm}>
                                Confirmar cambios y continuar
                            </Button>
                        </div>
                    </div>
                    </Card>
            </div>
        )
    }
}

export default translate("translations")(LimitesPorZona);