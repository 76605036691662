import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ConsultaDiniestros from '../Phoenix/components/RamosVarios/consultasiniestros';
import ConsultadeSiniestro from '../Phoenix/components/RamosVarios/ConsultadeSiniestro';
import Pagos from '../Phoenix/components/RamosVarios/Pagos';
import PerdidaProbable from '../Phoenix/components/RamosVarios/PerdidaProbable';
          

export const RamosVarios = ({ match }) => (
    <Switch>
        <Route exact path={`${match.url}/consultasiniestros/pagos/:siniestro/:policy/:item`} component={Pagos}/>
        <Route exact path={`${match.url}/consultasiniestros/perdidaprobable/:siniestro/:policy/:item`} component={PerdidaProbable}/>
        <Route exact path={`${match.url}/consultasiniestros`} component={ConsultaDiniestros}/>
        <Route exact path={`${match.url}/consultasiniestros/:siniestro/:policy/:item`} component={ConsultadeSiniestro}/>
    </Switch>
   
  ) 