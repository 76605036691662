import { config, base_url, axiousInstance } from './index';
let axios=axiousInstance;

export function getConsultaDeCheques() {
  let data = {};
  return axios
    .post(base_url + "caja-pagadora/getconsultadecheques", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getCheckList(reqParam, pageNo) {
  // let data = {
  //   checkNo: "",
  //   sucursal: "",
  //   tipoCheque: "",
  //   codigoBanco: "",
  //   cuentaCorriente: "",
  //   estadoChequeNo: "",
  //   codOrigen: "",
  //   codRef: "",
  //   fechaEmisionStartDate: "2014-10-22 00:00:00",
  //   fechaEmisionEndDate: "2014-10-22 00:00:00",
  //   montoChequeAmtStart: "",
  //   montoChequeAmtEnd: "",
  //   nombreBeneficiario: "",
  //   nomina: "",
  //   numeroReferncia: "",
  //   rutBeneficiario: "",
  //   dvRutBenef: ""
  // };
  if(pageNo){
    reqParam.pageNo=pageNo
  }
  let data = reqParam;
  return axios
    .post(base_url + "caja-pagadora/getchecklist", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      throw error;
    });
}

export function getCheckDetails(reqParam) {
  // let data = {
  //   origen: 5,
  //   banco: 9,
  //   ctacte: "07-01025-7",
  //   numero: 5055783
  // };
  let data = { checkno: reqParam.numero };
  return axios
    .post(base_url + "caja-pagadora/getCheckDetails2", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getCuentaCorriente(reqObj) {
  let data = reqObj;
  return axios
    .post(base_url + "caja-pagadora/getcuentacorriente", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}
