import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { translate, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../BaseComponents/Loader/Loader";
import moment from "moment";
import { connect } from "react-redux";
import _ from "lodash";

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto"
  },
  progress: {
    margin: theme.spacing.unit * 2
  }
});

class PorSiniestro extends React.Component {
  constructor(props) {
    let reqObject = {};
    reqObject.p_Criterio = 9;
    reqObject.p_R_VLO_CODIGO_UNO = 0;
    reqObject.p_R_VLO_COD_SUS = 0;
    reqObject.p_R_VLO_FEC_INICIO = 0;
    reqObject.p_R_VLO_FEC_TERMINO = moment(new Date()).format("YYYYMMDD");
    reqObject.p_R_VST_CODIGO_UNO = "T";
    reqObject.p_R_VST_CODIGO_DOS = "";
    reqObject.p_R_VLO_FEC_POL_INI = 0;
    reqObject.p_R_VLO_FEC_POL_TER = moment(new Date()).format("YYYYMMDD");
    reqObject.p_R_VLO_COD_RAMOS = "Incendio";
    reqObject.p_CodigoOficina = 0;
    reqObject.p_RutUsuario = 0;
    reqObject.slug = "consulta-global/por-siniestro";
    reqObject.porSinistro = true;
    reqObject.flag = "porSiniestro";

    super(props);
    this.state = {
      showPolicy: false,
      searchText: "",
      policyData: undefined,
      state1: undefined,
      enableSearch: true,
      showLoader: false,
      endDate: new Date(),
      reqObj: reqObject
    };
  }

  componentDidMount() {
    if (
      this.props.porSiniestrosObj &&
      !_.isEmpty(this.props.porSiniestrosObj)
    ) {
      let { searchText, enableSearch } = this.props.porSiniestrosObj;
      if (searchText) {
        this.setState({
          searchText: searchText,
          enableSearch: enableSearch
        });
      }
    }
  }

  getSiniestrosDetails = () => {
    this.setState(
      Object.assign(this.state.reqObj, {
        p_R_VLO_CODIGO_UNO: parseInt(this.state.searchText)
      })
    );

    this.props.addSinietrosFormData(this.state);
  };

  handleChange = event => {
    const value = event.target.value;
    let bln = value.length > 0 ? false : true;
    this.setState({ searchText: value, enableSearch: bln });
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.getSiniestrosDetails();
    }
  };

  resetSearch = () => {
    this.setState({
      showPolicy: false,
      blnAdvanceSearch: false,
      searchText: "",
      policyData: undefined,
      blnShowPorClientSearch: false
    });
    this.props.deleteSinietrosFormData();
  };

  render() {
    const { i18n } = this.props;
    const { classes } = this.props;
    let translateObj =
      (i18n.store.data[i18n.language] &&
        i18n.store.data[i18n.language].translations) ||
      i18n.store.data["es"].translations;
    let showLoader = this.state.showLoader;
    const reqObj = this.state.reqObj;

    return (
      <main className={classes.content + " home-page"}>
        {showLoader ? <Loader /> : null}
        <Trans i18nKey="title" />
        <div className={classes.appBarSpacer} />
        <div className="search-wrapper">
          <TextField
            id="custom-css-standard-input"
            label={"Siniestro"}
            type="search"
            className="text-field"
            margin="normal"
            value={this.state.searchText}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
          />
          <Link
            to={{
              pathname: `/consulta-global/por-siniestro/line-of-business/ramo`,
              state: reqObj
            }}
          >
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className="search-policy"
              disabled={this.state.enableSearch}
              onClick={this.getSiniestrosDetails}
            >
              {translateObj.policy_page.search_btn}
            </Button>
          </Link>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className="search-policy"
            onClick={this.resetSearch}
          >
            {translateObj.policy_page.reset_btn}
          </Button>
        </div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    porSiniestrosObj: state.porSiniestrosObj
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addSinietrosFormData: porReduxReqObj =>
      dispatch({ type: "ADD_POR_SINIESTROS_FORM_DATA", porReduxReqObj }),
    deleteSinietrosFormData: () =>
      dispatch({ type: "DELETE_POR_SINIESTROS_FORM_DATA" })
  };
}

PorSiniestro.propTypes = {
  classes: PropTypes.object.isRequired
};

let porSiniestroObj = connect(
  mapStateToProps,
  mapDispatchToProps
)(PorSiniestro);
let data = withStyles(styles)(porSiniestroObj);
export default translate("translations")(data);
