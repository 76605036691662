import React from "react";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import "./GlobalSearchPorClient.scss";
import { getreferencia } from "../../api/consulta";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../BaseComponents/Loader/Loader";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import _ from "lodash";
import Select from "react-select";
import { connect } from "react-redux";

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto"
  },
  progress: {
    margin: theme.spacing.unit * 2
  },
  grid: {
    width: "60%"
  }
});

class PorReferenciaCorredor extends React.Component {
  constructor(props) {
    super(props);

    let reqObject = {};
    reqObject.p_Criterio = 1026;
    reqObject.p_R_VLO_CODIGO_UNO = 0;
    reqObject.p_R_VLO_COD_SUS = 0;
    reqObject.p_R_VLO_FEC_INICIO = 0;
    reqObject.p_R_VLO_FEC_TERMINO = moment(new Date()).format("YYYYMMDD");
    reqObject.p_R_VST_CODIGO_UNO = "T";
    reqObject.p_R_VST_CODIGO_DOS = "";
    reqObject.p_R_VLO_FEC_POL_INI = 0;
    reqObject.p_R_VLO_FEC_POL_TER = moment(new Date()).format("YYYYMMDD");
    reqObject.p_R_VLO_COD_RAMOS = "";
    reqObject.p_CodigoOficina = 0;
    reqObject.p_RutUsuario = 0;
    reqObject.p_R_VST_PATENTE = "";
    reqObject.slug = "consulta-global/por-referencia-corredor";
    reqObject.flag = "porReferenciaCorredor";

    this.state = {
      referenciaNumber: "",
      referenciaText: "",
      listOfReferencia: undefined,
      ramosValue: 0,
      showReferenciaDropDown: false,
      referenciaVal: "",
      reqObj: reqObject
    };
  }

  showLoaderAction = () => {
    let showLoader = this.state.showLoader;
    this.setState({
      showLoader: !showLoader
    });
  };

  selectHandleChangeNegocio = actionMeta => {
    if (actionMeta && actionMeta.value) {
      this.setState({
        reqObj: {
          ...this.state.reqObj,
          p_R_VLO_CODIGO_UNO: parseInt(actionMeta.value)
        },
        referenciaNumber: actionMeta.value,
        referenciaText: actionMeta.label,
        referenciaNumber: actionMeta.value
      });
    }
    console.groupEnd();
  };

  handleChangeReferencia = event => {
    const value = event.target.value;
    this.setState({
      reqObj: {
        ...this.state.reqObj,
        p_R_VST_PATENTE: value
      },
      referenciaVal: value
    });
  };

  resetSearch = () => {
    this.props.deletePorReferenciaFormData();
    this.getReferncia();
  };

  /* function to get getReferncia */

  getReferncia = () => {
    this.showLoaderAction();
    getreferencia().then(response => {
      if (response && response.data) {
        let negocioDropDownVal = _.find(response.data, [
          "age_cod_age",
          11681632
        ]);

        this.setState({
          reqObj: {
            ...this.state.reqObj,
            p_R_VLO_CODIGO_UNO: parseInt(negocioDropDownVal.age_cod_age)
          },
          referenciaNumber: negocioDropDownVal.age_cod_age,
          referenciaText: negocioDropDownVal.Agt_Nombre,
          listOfReferencia: response.data,
          showReferenciaDropDown: true,
          referenciaVal: ""
        });

        this.showLoaderAction();
      } else {
        toast.error(response.msg || "Please enter R.u.t. number", {
          position: toast.POSITION.TOP_RIGHT
        });
        this.setState({
          listOfReferencia: undefined,
          showReferenciaDropDown: false
        });
        this.showLoaderAction();
      }
    });
  };

  changeRutNumber = event => {
    let value = event.target.value;
    let negocioDropDownVal = _.find(this.state.listOfReferencia, [
      "age_cod_age",
      parseInt(value)
    ]);
    if (negocioDropDownVal) {
      this.setState({
        referenciaNumber: negocioDropDownVal.age_cod_age,
        referenciaText: negocioDropDownVal.Agt_Nombre,
        reqObj: {
          ...this.state.reqObj,
          p_R_VLO_CODIGO_UNO: parseInt(negocioDropDownVal.age_cod_age)
        }
      });
    } else {
      this.setState({
        referenciaNumber: value
      });
    }
  };

  componentDidMount() {
    const { porReferenciaObj } = this.props;

    if (porReferenciaObj && !_.isEmpty(porReferenciaObj)) {
      let {
        listOfReferencia,
        reqObj,
        referenciaNumber,
        referenciaText,
        referenciaVal,
        showReferenciaDropDown
      } = this.props.porReferenciaObj;
      if (referenciaNumber && referenciaText) {
        this.setState({
          listOfReferencia: listOfReferencia,
          reqObj: reqObj,
          referenciaNumber: referenciaNumber,
          referenciaText: referenciaText,
          referenciaVal: referenciaVal,
          showReferenciaDropDown: showReferenciaDropDown
        });
      }
    } else {
      this.getReferncia();
    }
  }

  porReferenciaSearch = () => {
    this.props.addPorReferenciaFormData(this.state);
  };

  render() {
    const { classes } = this.props;
    const stateObj = this.state.reqObj;
    let showLoader = this.state.showLoader;

    return (
      <main className={classes.content + " home-page"}>
        <div className="mainComponent">
          {showLoader ? <Loader /> : null}
          <Card className="Card-area box-show-none">
            <fieldset className="custom-border">
              <legend className="legend-box">Por Referencia Corredor</legend>
              <div className="container">
                {this.state.showReferenciaDropDown ? (
                  <div className="row porClientRowClass">
                    <div className="col-sm-3">
                      <TextField
                        id="custom-css-standard-input"
                        label="Rut"
                        type="search"
                        className="porClenteInp"
                        margin="normal"
                        value={this.state.referenciaNumber}
                        onChange={this.changeRutNumber}
                      />
                    </div>
                    <div className="col-sm-6">
                      <div className="ramosClass">
                        <Select
                          maxMenuHeight={180}
                          isSearchable={true}
                          value={{
                            label: this.state.referenciaText,
                            value: this.state.referenciaNumber
                          }}
                          onChange={this.selectHandleChangeNegocio}
                          options={this.state.listOfReferencia.map(option => ({
                            value: option.age_cod_age,
                            label: option.Agt_Nombre
                          }))}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="row porClientRowClass">
                  <div className="col">
                    <TextField
                      id="custom-css-standard-input"
                      label="Referencia"
                      type="search"
                      className="porClenteInp"
                      margin="normal"
                      value={this.state.referenciaVal}
                      onChange={this.handleChangeReferencia}
                    />
                  </div>
                </div>
                <div className="row porClientRowClass">
                  <div className="col">
                    <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      className="search-policy resetButtonClass"
                      onClick={this.resetSearch}
                    >
                      {"Limpiar"}
                    </Button>
                    <Link
                      to={{
                        pathname: `/consulta-global/por-referencia-corredor/line-of-business/ramo`,
                        state: stateObj
                      }}
                      className="BuskarLink"
                    >
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className="search-policy"
                        disabled={false}
                        onClick={this.porReferenciaSearch}
                      >
                        {"Buscar"}
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </fieldset>
          </Card>
        </div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  console.log("state------>", state);
  return {
    porReferenciaObj: state.porReferenciaObj
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addPorReferenciaFormData: porReduxReqObj =>
      dispatch({ type: "ADD_POR_REFERENCIA_FORM_DATA", porReduxReqObj }),
    deletePorReferenciaFormData: () =>
      dispatch({ type: "DELETE_POR_REFERENCIA_FORM_DATA" })
  };
}

let PorReferenciaCorredorObj = connect(
  mapStateToProps,
  mapDispatchToProps
)(PorReferenciaCorredor);
export default withStyles(styles)(PorReferenciaCorredorObj);
