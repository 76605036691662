import React from "react";
import TextField from "@material-ui/core/TextField";
import CreatableSelect from "react-select/lib/Creatable";
import Card from "@material-ui/core/Card";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import "./GlobalSearchPorClient.scss";
import { getnegocio } from "./../../api/consulta";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../BaseComponents/Loader/Loader";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { listOfRamos } from "../../../Constants/listOfRamos";
import {
  porCliente,
  considerarEstratoDe
} from "../../../Constants/consultaConstant";
import moment from "moment";
import Divider from "@material-ui/core/Divider";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";
import { connect } from "react-redux";

const localeMap = {
  es: esLocale,
  en: enLocale
};

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto"
  },
  progress: {
    margin: theme.spacing.unit * 2
  },
  grid: {
    width: "70%"
  }
});

class PorNegocio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      negocioNumber: 0,
      negocioText: "",
      listOfNegocio: undefined,
      enableBuskar: true,
      porClienteRadio: "contratante",
      criterioRadio: "fechaDeVigencia",
      considerarRadio: "todos",
      considerarAnRadio: "poliza",
      porClenteValue: porCliente["contratante"],
      ramosValue: 0,
      considerarRadioValue: considerarEstratoDe["todos"],
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: new Date(new Date().getFullYear(), 0, 1),
      fetchHastaDate: new Date(),
      showNegocioDropDown: false,
      ramoText: listOfRamos[0].label,
      ramoNumber: listOfRamos[0].value
    };
    this.showLoaderAction = this.showLoaderAction.bind(this);
    this.disableSearchBtn = this.disableSearchBtn.bind(this);
  }

  handleChangeStartDate = (chageDate, event) => {
    this.setState({
      startDate: chageDate
    });
  };

  handleChangeEndDate = (chageDate, event) => {
    this.setState({
      endDate: chageDate
    });
  };

  handlePorClienteRadio = event => {
    const value = event.target.value;
    this.setState({
      considerarAnRadio: value
    });
  };

  handleChangeCriterioRadio = event => {
    this.setState({
      criterioRadio: event.target.value
    });
  };

  handleConsiderarEstraRadio = event => {
    let value = event.target.value;
    this.setState({
      considerarRadio: value,
      considerarRadioValue: considerarEstratoDe[value]
    });
  };

  handleClose = () => {
    this.setState({ openDailog: false });
  };

  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({
      showLoader: !showLoader
    });
  }

  selectHandleChange = actionMeta => {
    if (actionMeta) {
      this.setState({
        ramosValue: actionMeta.value,
        ramoText: actionMeta.label,
        ramoNumber: actionMeta.value
      });
    }
    console.groupEnd();
  };

  selectHandleInputChange = (inputValue, actionMeta) => {
    console.groupEnd();
  };

  selectHandleChangeNegocio = actionMeta => {
    if (actionMeta && actionMeta.value) {
      this.setState({
        negocioNumber: actionMeta.value,
        negocioText: actionMeta.label,
        negocioNumber: actionMeta.value
      });
    }
    console.groupEnd();
  };

  handleChangefetchHastaDate = date => {
    this.setState({ fetchHastaDate: date });
  };

  changeNegocioNumber = event => {
    let value = event.target.value;
    let negocioDropDownVal = _.find(this.state.listOfNegocio, [
      "lic_nro_con",
      parseInt(value)
    ]);
    if (negocioDropDownVal) {
      this.setState({
        negocioNumber: parseInt(value),
        negocioText: negocioDropDownVal.lic_des_cor
      });
    } else {
      this.setState({
        negocioNumber: value
      });
    }
  };

  resetSearch = () => {
    this.setState({
      negocioNumber: 0,
      negocioText: "",
      listOfNegocio: undefined,
      enableBuskar: true,
      porClienteRadio: "contratante",
      criterioRadio: "fechaDeVigencia",
      considerarRadio: "todos",
      considerarAnRadio: "poliza",
      porClenteValue: porCliente["contratante"],
      ramosValue: 0,
      considerarRadioValue: considerarEstratoDe["todos"],
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: new Date(new Date().getFullYear(), 0, 1),
      fetchHastaDate: new Date(),
      showNegocioDropDown: false
    });
    this.props.deletePorNegocioFormData();
    this.getNegocio();
  };

  disableSearchBtn() {
    let bln = true;

    let startDate = this.state.startDate
      ? moment(this.state.startDate).format("YYYYMMDD")
      : "";
    let endDate = this.state.endDate
      ? moment(this.state.endDate).format("YYYYMMDD")
      : "";

    if (!(_.isEmpty(startDate) && _.isEmpty(endDate)) && startDate > endDate) {
      bln = true;
    } else {
      bln = false;
    }

    return bln;
  }

  /* function to get getNegocio */

  getNegocio = rutNumber => {
    this.showLoaderAction();
    getnegocio().then(response => {
      if (response && response.data) {
        let negocioDropDownVal = _.find(response.data, ["lic_nro_con", 1564]);
        this.setState({
          negocioNumber: negocioDropDownVal.lic_nro_con,
          negocioText: negocioDropDownVal.lic_des_cor,
          listOfNegocio: response.data,
          showNegocioDropDown: true
        });
        this.showLoaderAction();
      } else {
        toast.error(response.msg || "Please enter R.u.t. number", {
          position: toast.POSITION.TOP_RIGHT
        });
        this.setState({
          listOfNegocio: undefined,
          showNegocioDropDown: false
        });
        this.showLoaderAction();
      }
    });
  };

  componentDidMount() {
    const { porNegocioObj } = this.props;

    if (porNegocioObj && !_.isEmpty(porNegocioObj)) {
      let {
        considerarAnRadio,
        considerarRadio,
        considerarRadioValue,
        criterioRadio,
        enableBuskar,
        endDate,
        fetchHastaDate,
        listOfNegocio,
        negocioNumber,
        negocioText,
        porClenteValue,
        porClienteRadio,
        ramosValue,
        showLoader,
        showNegocioDropDown,
        startDate,
        ramoNumber,
        ramoText
      } = this.props.porNegocioObj;
      if (true) {
        this.setState({
          considerarAnRadio: considerarAnRadio,
          considerarRadio: considerarRadio,
          considerarRadioValue: considerarRadioValue,
          criterioRadio: criterioRadio,
          enableBuskar: enableBuskar,
          endDate: endDate,
          fetchHastaDate: fetchHastaDate,
          listOfNegocio: listOfNegocio,
          negocioNumber: negocioNumber,
          negocioText: negocioText,
          porClenteValue: porClenteValue,
          porClienteRadio: porClienteRadio,
          ramosValue: ramosValue,
          showLoader: showLoader,
          showNegocioDropDown: showNegocioDropDown,
          startDate: startDate,
          ramoNumber: ramoNumber,
          ramoText: ramoText
        });
      }
    } else {
      this.getNegocio();
    }
  }

  porNegocioSearch = () => {
    console.log("this.statethis.state--->", this.state);
    this.props.addPorNegocioFormData(this.state);
  };

  render() {
    const { classes } = this.props;

    const stateObj = {
      porClente: 1009,
      rutNumber: parseInt(this.state.negocioNumber),
      ramos: this.state.ramosValue,
      ConsiderarEstratoDe: this.state.considerarRadioValue,
      slug: "consulta-global/por-n-negocio",
      nonRut: true,
      flag: "porNegocio"
    };

    if (this.state.criterioRadio === "carteraVigente") {
      stateObj.startDate = 0;
      stateObj.endDate = moment(this.state.fetchHastaDate).format("YYYYMMDD");
      stateObj.polEndDate = moment(this.state.fetchHastaDate).format(
        "YYYYMMDD"
      );
      stateObj.p_R_VST_CODIGO_DOS = "C";
    } else {
      stateObj.polEndDate = 0;
      stateObj.startDate = moment(this.state.startDate).format("YYYYMMDD");
      stateObj.endDate = moment(this.state.endDate).format("YYYYMMDD");
    }
    if (this.state.considerarAnRadio === "item") {
      stateObj.unidadCase = true;
    }

    let showLoader = this.state.showLoader;
    return (
      <main className={classes.content + " home-page"}>
        <div className="mainComponent">
          {showLoader ? <Loader /> : null}
          <Card className="Card-area box-show-none">
            <fieldset className="custom-border">
              <legend className="legend-box">Por N° Negocio</legend>
              <div className="container">
                {this.state.showNegocioDropDown ? (
                  <div className="row porClientRowClass">
                    <div className="col-sm-3">
                      <TextField
                        id="custom-css-standard-input"
                        label="N° Negocio"
                        type="search"
                        className="porClenteInp"
                        margin="normal"
                        value={this.state.negocioNumber}
                        onChange={this.changeNegocioNumber}
                      />
                    </div>
                    <div className="col-sm-6">
                      <div className="ramosClass">
                        <Select
                          value={{
                            label: this.state.negocioText,
                            value: this.state.negocioNumber
                          }}
                          isSearchable={true}
                          onChange={this.selectHandleChangeNegocio}
                          options={this.state.listOfNegocio.map(option => ({
                            value: option.lic_nro_con,
                            label: option.lic_des_cor
                          }))}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3" />
                    <div className="col-sm-3" />
                  </div>
                ) : null}
                <Divider />
                <div className="row porClientRowClass">
                  {this.state.criterioRadio === "fechaDeVigencia" ? (
                    <>
                      <div className="col">
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={localeMap["es"]}
                        >
                          <Grid
                            container
                            className={classes.grid}
                            justify="space-around"
                          >
                            <DatePicker
                              margin="normal"
                              keyboard
                              label="Fecha Inicio"
                              format="dd/MM/yyyy"
                              placeholder="10/10/2018"
                              value={this.state.startDate}
                              invalidDateMessage={"Rango fecha erroneo"}
                              minDateMessage={"Rango fecha erroneo"}
                              onChange={this.handleChangeStartDate}
                              disableOpenOnEnter
                              animateYearScrolling={false}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col">
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={localeMap["es"]}
                        >
                          <Grid
                            container
                            className={classes.grid}
                            justify="space-around"
                          >
                            <DatePicker
                              margin="normal"
                              keyboard
                              label="Fecha Término"
                              format="dd/MM/yyyy"
                              placeholder="10/10/2018"
                              value={this.state.endDate}
                              invalidDateMessage={"Rango fecha erroneo"}
                              minDate={this.state.startDate}
                              minDateMessage={
                                "Fecha de finalización mayor que la fecha de inicio"
                              }
                              onChange={this.handleChangeEndDate}
                              disableOpenOnEnter
                              animateYearScrolling={false}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid
                            container
                            className={classes.grid}
                            justify="space-around"
                          >
                            <DatePicker
                              margin="normal"
                              keyboard
                              label="Fecha Hasta"
                              format="dd/MM/yyyy"
                              placeholder="10/10/2018"
                              value={this.state.fetchHastaDate}
                              onChange={this.handleChangefetchHastaDate}
                              disableOpenOnEnter
                              animateYearScrolling={false}
                              disabled
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col" />
                    </>
                  )}

                  <div className="col">
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend">
                        Considerar a nivel de
                      </FormLabel>
                      <RadioGroup
                        className={classes.group}
                        value={this.state.considerarAnRadio}
                        onChange={this.handlePorClienteRadio}
                        row={true}
                      >
                        <FormControlLabel
                          value="poliza"
                          control={<Radio color="primary" />}
                          label="Poliza"
                        />
                        <FormControlLabel
                          value="item"
                          control={<Radio color="primary" />}
                          label="Item"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="col">
                    <span className="label-class">Ramos</span>
                    <div className="ramosClass">
                      <Select
                        maxMenuHeight={200}
                        isSearchable={true}
                        value={{
                          label: this.state.ramoText,
                          value: this.state.ramoNumber
                        }}
                        onChange={this.selectHandleChange}
                        options={listOfRamos.map(option => ({
                          value: option.value,
                          label: option.label
                        }))}
                      />
                    </div>
                  </div>
                </div>
                <Divider />
                <div className="row porClientRowClass">
                  <div className="col">
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend">Criterio</FormLabel>
                      <RadioGroup
                        className={classes.group}
                        value={this.state.criterioRadio}
                        onChange={this.handleChangeCriterioRadio}
                        row={true}
                      >
                        <FormControlLabel
                          value="fechaDeVigencia"
                          control={<Radio color="primary" />}
                          label="Fecha De Vigencia"
                        />
                        <FormControlLabel
                          value="cierreProduccionHistorica"
                          disabled
                          control={<Radio color="primary" />}
                          label="Cierre Produccion Historica"
                        />
                        <FormControlLabel
                          value="cierreProduccionalDia"
                          disabled
                          control={<Radio color="primary" />}
                          label="Cierre Produccion al Dia"
                        />
                        <FormControlLabel
                          value="carteraVigente"
                          control={<Radio color="primary" />}
                          label="Cartera Vigente"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                <Divider />
                <div className="row porClientRowClass">
                  <div className="col">
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend">
                        Considerar Estrato de
                      </FormLabel>
                      <RadioGroup
                        className={classes.group}
                        value={this.state.considerarRadio}
                        onChange={this.handleConsiderarEstraRadio}
                        row={true}
                      >
                        <FormControlLabel
                          value="personas"
                          control={<Radio color="primary" />}
                          label="Personas"
                        />
                        <FormControlLabel
                          value="empresas"
                          control={<Radio color="primary" />}
                          label="Empresas"
                        />
                        <FormControlLabel
                          selected
                          value="todos"
                          control={<Radio color="primary" />}
                          label="Todos"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="col">
                    <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      className="search-policy resetButtonClass"
                      onClick={this.resetSearch}
                    >
                      {"Limpiar"}
                    </Button>
                    <Link
                      to={{
                        pathname: `/consulta-global/por-n-negocio/line-of-business`,
                        state: stateObj
                      }}
                      className="BuskarLink"
                    >
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className="search-policy"
                        onClick={this.porNegocioSearch}
                        disabled={this.disableSearchBtn()}
                      >
                        {"Buscar"}
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </fieldset>
          </Card>
        </div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  console.log("state------>", state);
  return {
    porNegocioObj: state.porNegocioObj
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addPorNegocioFormData: porReduxReqObj =>
      dispatch({ type: "ADD_POR_NEGOCIO_FORM_DATA", porReduxReqObj }),
    deletePorNegocioFormData: () =>
      dispatch({ type: "DELETE_POR_NEGOCIO_FORM_DATA" })
  };
}

let porNegocioObj = connect(
  mapStateToProps,
  mapDispatchToProps
)(PorNegocio);

export default withStyles(styles)(porNegocioObj);
