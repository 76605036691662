import React from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { translate, Trans } from "react-i18next";
import Button from "@material-ui/core/Button";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import Card from '@material-ui/core/Card';
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import _ from 'lodash';
import  { detalleDeTickets }  from  '../../../Constants/cajaPanta';
import {api} from '../../api/caja-penta'
import './CajaPenta.scss'
import ExcelDownload  from '../../../utils/ExcelDownloadComponent'
import SearchIcon from "@material-ui/icons/CloudDownload";
import moment from 'moment';
import ecxelData from "./../../../assets/DetalleTicketNroCarga.xlsx"


const styles = theme => ({
});

class DetalleDeTickets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data : undefined,
      excelDownlaod:  false,
      excelData : undefined
    };

    this.donwloadExcel = this.donwloadExcel.bind(this);
  }
  
  componentDidMount() {
    const { match: { params } , location: {search}    } = this.props;
    const {nroCarga,moneda,oficina,periodoContable,fechaHora } = params;

   if(!_.isEmpty(nroCarga) && !_.isEmpty(moneda) && !_.isEmpty(oficina) && !_.isEmpty(periodoContable) && !_.isEmpty(fechaHora)){
    this.setState({
       data : {
         nroCarga: nroCarga,
         moneda :  moneda,
         oficina: oficina,
         periodoContable : periodoContable,
         fechaHora : fechaHora
        },
      excelsheetName : `DetalleTicketNroCarga2503${nroCarga}`,
      nroCarga :  nroCarga ,
      search: search
    })
    this.getTicketDetails(nroCarga)
   }
  }

  getTicketDetails(nroCarga){
    this.showLoaderAction();
    try {
      api.getTicketDetails(nroCarga).then((res)=>{
        if(res && !_.isEmpty(res.data)){
          let { ticket , total}  = res.data;
          ticket.push(total[0])
          this.setState({
            ticket : ticket
          })
        }else {
          toast.error(res.msg, {position: toast.POSITION.TOP_RIGHT});
        }
        this.showLoaderAction();
        })
        .catch((ex)=>{
            toast.error("Something went wrong.", {position: toast.POSITION.TOP_RIGHT});
            this.showLoaderAction();
        })
    }catch(ex){
      toast.error("Something went wrong.", {position: toast.POSITION.TOP_RIGHT});
    }
  }

  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  resetSearch() {
    this.setState({
      enableSearch : true,
      searchTextOne : "",
      searchTextTwo : ""
    });
  }

 showLoaderAction() {
  let showLoader = this.state.showLoader;
  this.setState({ showLoader: !showLoader });
 }


 donwloadExcel=()=>{
   let ticketNo = this.state.data.nroCarga;
   this.showLoaderAction();
   api.getExcelDetails(ticketNo)
      .then(res =>{
        if(res && res.success){
            this.showLoaderAction();
            window.open(
              ecxelData,
              "_blank"
            )
        }else{
          this.showLoaderAction();
          toast.error(res.msg, {position: toast.POSITION.TOP_RIGHT});
        }
      })
      .catch(err =>{
        this.showLoaderAction();
        toast.error("Tiempo de espera agotado. Inténtalo de nuevo", {position: toast.POSITION.TOP_RIGHT});
        console.log(err);
      });
}

  goToMainPage=()=>{
    let  { search }  = this.state;
    this.props.history.push(`/caja-penta/filterosh-historial${search}`)
  }
 
 
  render() {
    const { i18n } = this.props;
    const { classes } = this.props;
    let translateObj = (i18n.store.data[i18n.language] && i18n.store.data[i18n.language].translations) ||  i18n.store.data["es"].translations;
   
    let columnName  = detalleDeTickets;

    let {ticket, data}  = this.state

    let excelFirstColoulnValue=`Informe de Tickets asociados al N° Carga ${this.state.nroCarga}`;


    let newFormatData;
    if(!_.isEmpty(this.state.excelData) && !_.isEmpty(this.state.excelData)){
      newFormatData = this.state.excelData && this.state.excelData.map((singleObj)=>{
        return Object.values(singleObj);
      })
    }

    let finalData=[];
    let sheetName="sheet1";


    return (
      <main className="main-container">
        {this.state.showLoader ? <Loader /> : null}
        {ticket && data ?
        <div>
            <div>
              <div>
                <span className="caja-h3 font-25px">Detalle de tickets </span>
                <span>
                  <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      className="search-policy download-button-style"
                      onClick={this.goToMainPage}
                      >
                    {"Volver"}
                  </Button>
                  <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      className="search-policy download-button-style"
                      onClick={this.donwloadExcel.bind()}
                      >
                    <SearchIcon className="download-icon" />
                    {"Excel"}
                  </Button>
                </span>
            </div>
            <div className="margin-top-30">
                <Card className="Card-area box-show-none">
                    <fieldset className="custom-border">
                    <legend className="legend-box">Consultar Mandatos Cliente</legend>
                        <div className="vertical-align-middle">
                            <span className="Space-around">
                            <TextField InputLabelProps={{shrink: true }}
                                id="standard-bare"
                                label="Fecha Generación ticket"
                                className="textField"
                                value={data.fechaHora}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                readOnly: true,
                                }}
                                style = {{width: 250}}
                            />
                            </span>
                            <span className="Space-around">
                            <TextField InputLabelProps={{shrink: true }}
                                id="standard-bare"
                                label="Nro Carga-Lote"
                                className="textField"
                                value={data.nroCarga}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                readOnly: true,
                                }}
                                style = {{width: 150}}
                            />
                            </span>
                            <span className="Space-around">
                            <TextField InputLabelProps={{shrink: true }}
                                id="standard-bare"
                                label="Oficina"
                                className="textField"
                                value={data.oficina}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                readOnly: true,
                                }}
                                style = {{width: 200}}
                            />
                            </span>
                            <span className="Space-around">
                            <TextField InputLabelProps={{shrink: true }}
                                id="standard-bare"
                                label="Moneda Pago"
                                className="textField"
                                value={data.moneda}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                readOnly: true,
                                }}
                                style = {{width: 150}}
                            />
                            </span>
                            <span className="Space-around">
                            <TextField InputLabelProps={{shrink: true }}
                                id="standard-bare"
                                label="Período Contable"
                                className="textField"
                                value={data.periodoContable}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                readOnly: true,
                                }}
                                style = {{width: 150}}
                            />
                            </span>
                        </div>
                    </fieldset>
                </Card>
                </div>        
            </div>
            <div>
              <h4 className="caja-h3">Bandeja de Trabajo </h4>
                <DyanmicTableWithSorting 
                    data={ticket}
                    keyArray={columnName}
                    orderBy={columnName[0]}
                    pathData={{}}
                    isPaginationReq={false}
                    isEnhancedTableHeadReq={true}
                    defaultRowPerPage={false}
                />
            </div>
        </div>
        : null
        }
        <ToastContainer />
      </main>
    );
  }
}


DetalleDeTickets.propTypes = {
  classes: PropTypes.object.isRequired
};

export default translate("translations")( withStyles(styles)(DetalleDeTickets));


