import React from 'react';
import { config, base_url, axiousInstance } from './index';
let axios=axiousInstance;


export function getInventory(data){
  // url : "https://lrigoocw6l.execute-api.us-east-1.amazonaws.com/dev/getInventory"
  return axios.post(base_url+"getInventory",data,config)
    .then(res => {
      return res.data;
    })
    .catch((error) => {
        console.log('error ' + error);
        return error;
     });
}

export function getInspectionRecord(data){
  // url : "https://lrigoocw6l.execute-api.us-east-1.amazonaws.com/dev/getInspectionRecord"
  return axios.post(base_url+"getInspectionRecord",data,config)
    .then(res => {
      return res.data;
    })
    .catch((error) => {
        console.log('error ' + error);
        return error;
     });
}