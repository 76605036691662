import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { translate, Trans } from "react-i18next";
import Button from "@material-ui/core/Button";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './consultasiniestros.scss'
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import { getRamosVarios } from "./../../api/ramoVarios";
import { ConsultaSiniestrosKeyColoumn} from "./../../../Constants/ramosVarios";
import _ from 'lodash'

const styles = theme => ({
    root: {
        display: 'flex',
      },
      formControl: {
        margin: theme.spacing.unit * 3,
      },
      group: {
        margin: `${theme.spacing.unit}px 0`,
      },
});

class ConsultaDiniestros extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resultData : undefined,
      showData :  false,
      enableSearch : true,
      siniestroText:"",
      policy:"",
      item:"",
      selectedRadionValue:"nrosiniestro"
    };
  }
  
  componentDidMount() {
    const { match: { params } , location : {search} } = this.props;
    let query = ""
    let keyValue = {}
    if(search){
        query =  search.slice(1,search.length).split("&")
        _.forEach(query,(q)=>{
            let splitString =  q.split("=")
            let key = splitString[0];
            let value = splitString[1];
            keyValue[key] = value            
        })
    }

    let keys = _.keys(keyValue)
    _.forEach(keys, (key)=>{
      if(keys=="nroSiniestro"){
        this.setState({siniestroText : keyValue.nroSiniestro , selectedRadionValue:"nrosiniestro" , enableSearch : false })
      }else if(key == "policyNo") {
        this.setState({policy : keyValue.policyNo  , selectedRadionValue:"policyItem" , enableSearch : false})
      }else if(key == "itemNo") {
        this.setState({item : keyValue.itemNo, selectedRadionValue:"policyItem" , enableSearch : false})
      }
    })

  }
  handleRadioChange = event => {
    let value = event.target.value;
    let enableSearch=true;
    if(value==="nrosiniestro"){
      if(this.state.siniestroText.length){
        enableSearch=false;
      }
    }else{
      if(this.state.policy.length){
        enableSearch=false;
      }
    }
    this.setState({
        selectedRadionValue: value,
        enableSearch:enableSearch
    });
  };
  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }
  getConsultaSiniestros= ()=>{
    let flag;
    if(this.state.selectedRadionValue=="nrosiniestro"){
      flag="S";
    }else{
      flag=(this.state.policy.length && this.state.item.length)?"I":"P";
    }
    this.showLoaderAction();
    getRamosVarios(this.state.siniestroText, this.state.policy, this.state.item, flag).then((response)=>{
      this.showLoaderAction();
      if(response && response.data && response.data.sinistroiList.length){
        this.setState({
            showData:true,
            resultData:response.data.sinistroiList
        })
      }else{
        toast.error(response.msg, {position: toast.POSITION.TOP_RIGHT});
        this.setState({
            showData:false,
            resultData:undefined
        })
      }
    })
   
  }
  policyTextChange=event=> {
    let enableSearch=true;
    let policy=event.target.value;
    if(policy.length){
      enableSearch=false;
    }
    this.setState({
      policy:policy,
      enableSearch:enableSearch
    })
  }
  onsiniestroTextChange=event=> {
    let enableSearch=true;
    let siniestroText=event.target.value;
    if(siniestroText.length){
      enableSearch=false;
    }
    this.setState({
      siniestroText:siniestroText,
      enableSearch:enableSearch
    })
  }
  itemTextChange=event=> {
    let item=event.target.value;
    this.setState({
      item:item
    })
  }
  resetSearch=()=>{
    this.setState({
      siniestroText:"",
      policy:"",
      item:"",
      resultData:undefined,
      enableSearch:true
    })
  }

  clickOnDetaile=(data)=>{
    let {nroSiniestro,polizaItem} =  data;
    let  { siniestroText,policy, item } =  this.state;

    if(policy){
      let bln = item ? true: false;
      this.props.history.push(`/ramos/consultasiniestros/${nroSiniestro}/${polizaItem}?policyNo=true&itemNo=${bln}&nroSiniestro=false`)     
    }else if(siniestroText){
      this.props.history.push(`/ramos/consultasiniestros/${nroSiniestro}/${polizaItem}?nroSiniestro=true&policyNo=false&itemNo=false`)
    }
  }
  render() {
    const { i18n } = this.props;
    const { classes } = this.props;
    let translateObj = (i18n.store.data[i18n.language] && i18n.store.data[i18n.language].translations) ||  i18n.store.data["es"].translations;
    let resultData=this.state.resultData;
    const data = {
        route: "/ramos/consultasiniestros",
        keys: ["accion"]
      };
    return (
      <main className="main-container">
        {this.state.showLoader ? <Loader /> : null}

        <div className="search-wrapper">
            <fieldset className="custom-border padding-left-20">
                <legend className="legend-box">Búsqueda de Siniestros</legend>
                <FormControl component="fieldset" className={classes.formControl +" margin-top-bottom-0"}>
                    <RadioGroup
                        className={classes.group}
                        value={this.state.selectedRadionValue}
                        onChange={this.handleRadioChange}
                    >   
                      <FormControlLabel className="align-top" value="nrosiniestro" control={<Radio color="primary" />} label="Nro. Siniestro" />
                      <FormControlLabel className="align-top" value="policyItem" control={<Radio color="primary" />} label="Policy/Item" />
                    </RadioGroup>
                </FormControl>
                <FormControl component="fieldset" className={classes.formControl +" margin-top-bottom-0"}>
                    <div className="siniestro-text">
                      <TextField
                            id="custom-css-standard-input"
                            label={"Nro. Siniestro"}
                            type="search"
                            className="text-field"
                            margin="normal"
                            value={this.state.siniestroText}
                            style = {{width: 300}}
                            onChange={this.onsiniestroTextChange.bind(this)}
                            disabled={this.state.selectedRadionValue==="nrosiniestro"?false:true}
                      />
                    </div>
                    <div className="policy-item-group">
                      <TextField
                          id="custom-css-standard-input"
                          label={"Poliza"}
                          type="search"
                          className="text-field"
                          margin="normal"
                          value={this.state.policy}
                          style = {{width: 200}}
                          onChange={this.policyTextChange.bind(this)}
                          disabled={this.state.selectedRadionValue==="nrosiniestro"?true:false}
                      />
                      <TextField
                          id="custom-css-standard"
                          style = {{width: 100}}
                          label={"Item"}
                          type="search"
                          className="text-field"
                          margin="normal"
                          value={this.state.item}
                          onChange={this.itemTextChange.bind(this)}
                          disabled={this.state.selectedRadionValue==="nrosiniestro"?true:false}
                      />
                    </div>
                </FormControl>
                <div className="centred-aligned">
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            className="search-policy"
                            disabled={this.state.enableSearch}
                            onClick={this.getConsultaSiniestros.bind()}
                        >
                            {"Buscar Siniestros"}
                        </Button>
                        <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    className="search-policy"
                onClick={this.resetSearch.bind()}
                >
                    {"Limpiar Filtros"}
                </Button>
                    </div>
            </fieldset>
        </div>
        { this.state.showData ? 
            <div className="margin-top-30">
                 <DyanmicTableWithSorting
                        data={resultData}
                        keyArray={ ConsultaSiniestrosKeyColoumn}
                        orderBy={ ConsultaSiniestrosKeyColoumn[0]}
                        pathData={data}
                        virticalScroll={true}
                        tableMaxHeight={"400"}
                        isEnhancedTableHeadReq={true}
                        // linkCreateArray={["nroSiniestro","polizaItem"]}
                        virticalScroll={true}
                        tableMaxHeight={"400"}
                        tableMaxHeight={"400"}
                        isPaginationReq={true}
                        defaultRowPerPage={20}
                        passClick = {this.clickOnDetaile}
                      />
            </div>
        :null}
        <ToastContainer />
      </main>
    );
  }
}


ConsultaDiniestros.propTypes = {
  classes: PropTypes.object.isRequired
};

export default translate("translations")( withStyles(styles)(ConsultaDiniestros));


