import React from "react";
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import itemKey from "./itemKey.json";
import TextField from "@material-ui/core/TextField";
import { translate, Trans } from "react-i18next";
import Button from "@material-ui/core/Button";
import { getItemList } from "./../../api/policy";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class ItemsListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      data: undefined,
      itemKeyArray: itemKey,
      enableSearch: true,
      searchText: "",
      searchItemText: "",
      blnShowItem: false,
      showLoader: false,
      startRange: 1,
      endRange: "",
      totalItems: "",
      isPaginationReq: true,
      isEnhancedTableHeadReq: true,
      defaultRowPerPage: 20,
      showVolver: false
    };
    this.resetSearch = this.resetSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleItemChange = this.handleItemChange.bind(this);
    this.showItemList = this.showItemList.bind(this);
    this.getItemByRange = this.getItemByRange.bind(this);
    this.handleStartRangeChange = this.handleStartRangeChange.bind(this);
    this.handleEndRangeChange = this.handleEndRangeChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }
  handleStartRangeChange(evt) {
    this.setState({ startRange: evt.target.value });
  }
  handleEndRangeChange(evt) {
    this.setState({ endRange: evt.target.value });
  }
  getItemByRange() {
    let { startRange, endRange } = this.state;
    if (
      parseInt(startRange) == 0 ||
      parseInt(endRange) == 0 ||
      (parseInt(startRange) && parseInt(endRange))
    ) {
      if (startRange === 0) {
        this.setState({ startRange: 1 });
      }
      this.showItemList(
        this.state.searchText,
        this.state.startRange,
        this.state.endRange
      );
    } else {
      alert("Please enter numeric value in start or end range");
    }
  }
  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    const policyNo = params.policyNo;
    if (policyNo) {
      this.setState({ searchText: policyNo, showVolver: true });
      this.showItemList(policyNo);
      this.setState({ enableSearch: false });
    }
  }

  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  showItemList(policyNo, start, end) {
    this.showLoaderAction();
    if (
      this.state.searchText &&
      this.state.searchText.length > 0 &&
      this.state.searchItemText &&
      this.state.searchItemText.length > 0
    ) {
      this.props.history.push(
        "/items-list/" + this.state.searchText + "/" + this.state.searchItemText
      );
    } else {
      if (typeof policyNo !== "string") {
        this.props.history.push("/items-list/" + this.state.searchText);
      } else {
        this.props.history.push(
          "/items-list/" + policyNo || this.state.searchText
        );
      }
    }

    getItemList(this.state.searchText || policyNo, start, end)
      .then(response => {
        if (response && response.data && !_.isEmpty(response.data)) {
          this.setState({
            blnShowItem: true,
            data: response.data && response.data.items,
            startRange:
              response.range.startRange !== 0 ? response.range.startRange : 1,
            endRange: response.range.endRange || response.range.comuna,
            totalItems: response.range.comuna
          });
          this.showLoaderAction();
        } else {
          toast.error(response.msg || "No Items found for given Policy no", {
            position: toast.POSITION.TOP_RIGHT
          });
          this.setState({
            data: undefined,
            blnShowItem: false
          });
          this.showLoaderAction();
        }
      })
      .catch(err => {
        this.showLoaderAction();
        console.error(err);
      });
    this.setState({ blnShowItem: true });
  }

  handleChange(event) {
    const value = event.target.value;
    let bln = value.length > 0 ? false : true;
    this.setState({ searchText: value, enableSearch: bln });
  }

  handleItemChange(event) {
    const value = event.target.value;
    this.setState({ searchItemText: value });
  }

  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.showItemList();
    }
  }

  resetSearch() {
    this.setState({
      blnShowItem: false,
      searchText: "",
      searchItemText: "",
      data: undefined
    });
  }

  backToPreviousPage = () => {
    window.history.back();
    window.history.back();
  };

  render() {
    const { classes, i18n } = this.props;
    const { value } = this.state;
    let translateObj =
      (i18n.store.data[i18n.language] &&
        i18n.store.data[i18n.language].translations) ||
      i18n.store.data["es"].translations;
    const blnShowItem = this.state.blnShowItem;
    let showLoader = this.state.showLoader;

    const data = {
      route: "/items-list/",
      search: {
        policyNo: this.state.searchText
      },
      key: "nItem"
    };
    return (
      <main className="main-container">
        {showLoader ? <Loader /> : null}
        <div className="main-screen">
          <div className="search-wrapper">
            <TextField
              id="standard-search"
              label={translateObj.policy_page.search_placeholder}
              type="search"
              className="text-field"
              margin="normal"
              value={this.state.searchText}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
            />
            <TextField
              id="standard-item-search"
              label={translateObj.policy_page.search_item_placeholder}
              type="search"
              className="text-field"
              margin="normal"
              value={this.state.searchItemText}
              onChange={this.handleItemChange}
              onKeyPress={this.handleKeyPress}
            />
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className="search-policy"
              disabled={this.state.enableSearch}
              onClick={this.showItemList.bind()}
            >
              {translateObj.policy_page.search_btn}
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className="search-policy"
              onClick={this.resetSearch.bind()}
            >
              {translateObj.policy_page.reset_btn}
            </Button>
            {this.state.showVolver ? (
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className="search-policy"
                onClick={this.backToPreviousPage}
              >
                {"Volver"}
              </Button>
            ) : null}
          </div>

          {blnShowItem && this.state.data ? (
            <>
              <div className="range-group">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="total-items"
                  label="Total de Items"
                  className="textField margin-right-20"
                  value={this.state.totalItems}
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="start-range"
                  label="Inicio"
                  className="textField margin-right-20"
                  value={this.state.startRange}
                  margin="normal"
                  onChange={this.handleStartRangeChange}
                  variant="outlined"
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="end-range"
                  label="Final"
                  className="textField margin-right-20"
                  value={this.state.endRange}
                  onChange={this.handleEndRangeChange}
                  margin="normal"
                  variant="outlined"
                />
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className="search-policy"
                  onClick={this.getItemByRange.bind()}
                >
                  {translateObj.policy_page.search_btn}
                </Button>
              </div>
              <DyanmicTableWithSorting
                data={this.state.data}
                keyArray={this.state.itemKeyArray}
                orderBy={this.state.itemKeyArray[0]}
                pathData={data}
                isPaginationReq={this.state.isPaginationReq}
                isEnhancedTableHeadReq={this.state.isEnhancedTableHeadReq}
                defaultRowPerPage={this.state.defaultRowPerPage}
                virticalScroll={true}
                tableMaxHeight={"450"}
              />
            </>
          ) : null}
        </div>
        <ToastContainer />
      </main>
    );
  }
}

export default translate("translations")(ItemsListComponent);
